import { Navigate } from 'react-router-dom';
import * as RC from './route_constants';
import PageLayout from '../Components/PageLayout/PageLayout';

export const PrivateRouteWrapper = ({ children, userRole, noWrapper }) => {
  return userRole ? (
    noWrapper ? (
      children
    ) : (
      <PageLayout>{children}</PageLayout>
    )
  ) : (
    <Navigate to={RC.BASEURL} replace={true} />
  );
};
