import axios from 'axios';

const logLevels = {
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR',
};

const logToServer = async (level, message, data = {}) => {
  try {
    const response = await axios.post('/v1/log', {
      level,
      message,
      data,
      timestamp: new Date().toISOString(),
    });

    if (response.status !== 200) {
      console.error('Failed to send log to server');
    }
  } catch (error) {
    console.error('Error sending log to server:', error.message);
  }
};

export const logger = {
  info: (message, data) => logToServer(logLevels.INFO, message, data),
  warn: (message, data) => logToServer(logLevels.WARN, message, data),
  error: (message, data) => logToServer(logLevels.ERROR, message, data),
};
