import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { BASEURL } from '../Routes/route_constants';
import rocketImage from '../../src/Assets/images/rocket-image.png';
import CustomButton from '../Components/MiscComponents/CustomButton';
import { ArrowBack } from '@mui/icons-material';
import UnAuthPageLayout from '../Components/PageLayout/UnAuthPageLayout';

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <UnAuthPageLayout
      sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          maxWidth: '460px',
          mx: 'auto',
          p: '16px',

          '& img': {
            animationDuration: '0.3s',
            animationDelay: `0.01s`,
          },
          '& h5': {
            animationDuration: '0.3s',
            animationDelay: `0.06s`,
          },
          '& button': {
            animationDuration: '0.3s',
            animationDelay: `0.11s`,
          },
        }}
      >
        <img
          className="login-image slide-up-bounce"
          src={rocketImage}
          alt="Logo"
          style={{
            height: '250px',
            width: ' 250px',
            objectFit: 'contain',
            marginInline: 'auto',
          }}
        />
        <Typography
          className="slide-up-bounce"
          variant="h5"
          textAlign="center"
          fontWeight={500}
          mb="42px"
        >
          Oops! Looks like the page you're trying to access has been removed or
          incorrect.
        </Typography>

        <CustomButton
          className="slide-up-bounce"
          type="submit"
          onClick={() => navigate(BASEURL)}
          startIcon={<ArrowBack />}
        >
          Back to Dashboard
        </CustomButton>
      </Box>
    </UnAuthPageLayout>
  );
};

export default ErrorPage;
