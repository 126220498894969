import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateProfile } from '../../Redux/slices/userSlice';
import {
  Box,
  Typography,
  TextField,
  Grid,
  IconButton,
  Card,
  CardContent,
} from '@mui/material';
import { getCurrentUser } from '../../Redux/slices/authSlice';
import { toast } from 'react-toastify';
import CustomButton from '../../Components/MiscComponents/CustomButton';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import Loader from '../../Components/MiscComponents/Loader';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CloseOutlined, EditOutlined } from '@mui/icons-material';

const MyProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.currentUser?.data);
  const [userDetails, setUserDetails] = useState(user || {});
  const loading = useSelector((state) => state.auth.loading);
  const [editMode, setEditMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (!user) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, user]);

  const handleUpdateProfile = () => {
    const updateData = {
      userId: userDetails.id,
      data: {
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        contact_number: userDetails.contact_number,
        current_address: userDetails.current_address,
        emergency_contact_name: userDetails.emergency_contact_name,
        emergency_contact_number: userDetails.emergency_contact_number,
        date_of_birth: userDetails.date_of_birth,
        national_id_number: userDetails.national_id_number,
        organizationData: {
          bank_name: userDetails.organizationData?.bank_name,
          bank_account_title: userDetails.organizationData?.bank_account_title,
          bank_account_number:
            userDetails.organizationData?.bank_account_number,
        },
      },
    };

    dispatch(updateProfile(updateData))
      .unwrap()
      .then(() => {
        toast.success('Profile updated successfully');
        setEditMode(false);
        handleCloseDialog(true);
        dispatch(getCurrentUser());
      })
      .catch((error) => {
        toast.error(`Update failed: ${error.message}`);
      });
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const toggleEditMode = () => setEditMode(!editMode);

  return (
    <Box>
      {/* <DynamicBackButton /> */}

      {loading ? (
        <Loader height="60vh" />
      ) : (
        <>
          <Card
            className="no-hover"
            sx={{
              maxWidth: '724px',
              mx: 'auto',
              mt: { xs: '20px', sm: '0px' },
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: '42px',
                }}
              >
                <Typography variant="h4" fontWeight={500} textAlign="left">
                  Personal Information
                </Typography>

                <IconButton onClick={toggleEditMode} color="primary">
                  {editMode ? <CloseOutlined /> : <EditOutlined />}
                </IconButton>
              </Box>

              <Grid container spacing={'18px'}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    value={userDetails?.first_name || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        first_name: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    value={userDetails?.last_name || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        last_name: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    value={userDetails?.email || ''}
                    disabled
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Contact Number"
                    value={userDetails?.contact_number || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        contact_number: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <DatePicker
                    label="Date of Birth"
                    value={new Date(userDetails?.date_of_birth) || null}
                    onChange={(date) =>
                      setUserDetails({ ...userDetails, date_of_birth: date })
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    disabled={!editMode}
                    fullWidth
                    sx={{ width: '100%' }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Current Address"
                    value={userDetails?.current_address || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        current_address: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                    multiline
                    rows={2}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Emergency Contact Name"
                    value={userDetails?.emergency_contact_name || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        emergency_contact_name: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Emergency Contact Number"
                    value={userDetails?.emergency_contact_number || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        emergency_contact_number: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>

                {/* National ID Number - Occupying full width due to its uniqueness */}
                <Grid item xs={12}>
                  <TextField
                    label="National ID Number"
                    value={userDetails?.national_id_number || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        national_id_number: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Job Title"
                    value={userDetails?.organizationData?.job_title || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        organizationData: {
                          ...userDetails.organizationData,
                          job_title: e.target.value,
                        },
                      })
                    }
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Job Start Date"
                    value={
                      new Date(userDetails?.organizationData?.job_start_date) ||
                      null
                    }
                    onChange={(date) =>
                      setUserDetails({
                        ...userDetails,
                        organizationData: {
                          ...userDetails.organizationData,
                          job_start_date: date,
                        },
                      })
                    }
                    renderInput={(params) => <TextField {...params} />}
                    disabled={!editMode}
                    fullWidth
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Job End Date"
                    value={userDetails?.organizationData?.job_end_date || null}
                    onChange={(date) =>
                      setUserDetails({
                        ...userDetails,
                        organizationData: {
                          ...userDetails.organizationData,
                          job_end_date: date,
                        },
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    disabled={!editMode}
                    fullWidth
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Salary Amount"
                    disabled
                    value={userDetails?.organizationData?.salary_amount || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        organizationData: {
                          ...userDetails.organizationData,
                          salary_amount: e.target.value,
                        },
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Salary Currency"
                    value={userDetails?.organizationData?.salary_currency || ''}
                    disabled
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        organizationData: {
                          ...userDetails.organizationData,
                          salary_currency: e.target.value,
                        },
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Bank Name"
                    value={userDetails?.organizationData?.bank_name || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        organizationData: {
                          ...userDetails.organizationData,
                          bank_name: e.target.value,
                        },
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Bank Account Title"
                    value={
                      userDetails?.organizationData?.bank_account_title || ''
                    }
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        organizationData: {
                          ...userDetails.organizationData,
                          bank_account_title: e.target.value,
                        },
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Bank Account Number"
                    value={
                      userDetails?.organizationData?.bank_account_number || ''
                    }
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        organizationData: {
                          ...userDetails.organizationData,
                          bank_account_number: e.target.value,
                        },
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
              </Grid>

              {editMode && (
                <CustomButton
                  onClick={handleOpenDialog}
                  fullWidth
                  sx={{ mt: '32px' }}
                >
                  Update
                </CustomButton>
              )}
            </CardContent>
          </Card>

          <CustomDialog
            open={openDialog}
            onClose={handleCloseDialog}
            heading="Confirm Update"
            subHeading="Are you sure you want to update the changes?"
          >
            <Box display="flex" justifyContent="flex-end" mt={'42px'} gap="8px">
              <CustomButton onClick={handleCloseDialog} variant="outlined">
                Cancel
              </CustomButton>
              <CustomButton
                onClick={handleUpdateProfile}
                variant="contained"
                color="primary"
              >
                Update
              </CustomButton>
            </Box>
          </CustomDialog>
        </>
      )}
    </Box>
  );
};

export default MyProfile;
