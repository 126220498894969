import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import daftarLogo from '../../Assets/images/daftar-logo.png';
import Colors from '../../Utils/Colors';

const UnAuthPageLayout = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: `0.5px solid ${Colors.border}`,
          padding: { xs: '12px 16px', sm: '12px 24px' },
        }}
      >
        <Box
          component="img"
          src={daftarLogo}
          alt="Daftar Logo"
          sx={{ height: { xs: '35px', sm: '35px' }, cursor: 'pointer' }}
          onClick={() => navigate('/')}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          maxWidth: '460px',
          mx: 'auto',
          p: '16px',
        }}
      >
        {children}
      </Box>

      <Typography
        fontSize="12px"
        color="grey"
        textAlign="center"
        fontWeight={300}
        p="10px"
      >
        © Daftar {new Date().getFullYear()}. All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default UnAuthPageLayout;
