import React from 'react';
import { Box, Typography } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

const ReturnToInvoices = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="60vh"
      className="done-page"
    >
      <CheckCircleOutline sx={{ fontSize: 64, color: '#7A3DF3', marginBottom: '16px', animation: 'fadeIn 0.5s ease-in-out' }} />
      <Typography variant="h6" mt={3} style={{ animation: 'fadeIn 0.5s ease-in-out' }}>
        You're all done. Return to invoices
      </Typography>
      <style>
        {`
          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          .done-page {
            animation: fadeIn 0.5s ease-in-out;
          }
        `}
      </style>
    </Box>
  );
};

export default ReturnToInvoices;
