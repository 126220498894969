import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createLeave } from '../../Redux/slices/leaveSlice';
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Card,
  CardContent,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DynamicBackButton from '../../Components/BackButton/DynamicBackButton';
import { toast } from 'react-toastify';
import CustomButton from '../../Components/MiscComponents/CustomButton';

function SubmitLeaveForm() {
  const [leaveData, setLeaveData] = useState({
    type: '',
    duration: '',
    start_date: null,
    end_date: null,
    reason: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmitLeaveData = (e) => {
    e.preventDefault();
    if (
      !leaveData.type ||
      !leaveData.duration ||
      !leaveData.start_date ||
      !leaveData.end_date
    ) {
      toast.error('Please fill all the required fields.');
      return;
    }

    const formattedData = {
      ...leaveData,
      start_date: leaveData.start_date.toISOString(),
      end_date: leaveData.end_date.toISOString(),
    };

    dispatch(createLeave(formattedData))
      .unwrap()
      .then(() => {
        toast.success('Leave request submitted successfully.');
        navigate(-1);
      })
      .catch((error) => {
        toast.error(`Error: ${error.message}`);
      });
  };

  // TODO: this page can be converted into a dialog in future

  return (
    <>
      <DynamicBackButton />

      <Card
        className="no-hover"
        sx={{ maxWidth: '724px', mx: 'auto', mt: { xs: '20px', sm: '0px' } }}
      >
        <CardContent>
          <Typography variant="h4" fontWeight={500} mb="8px" textAlign="left">
            Submit Leave Request
          </Typography>
          <Typography textAlign="left" variant="body2" mb="42px">
            Please provide the details for your leave request below
          </Typography>

          <form onSubmit={handleSubmitLeaveData}>
            <Grid container spacing={'18px'}>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  textAlign="left"
                  mb="4px"
                >
                  Select Leave Type:
                </Typography>
                <RadioGroup
                  row
                  name="type"
                  value={leaveData.type}
                  onChange={(e) =>
                    setLeaveData({ ...leaveData, type: e.target.value })
                  }
                >
                  <FormControlLabel
                    value="Casual Leave"
                    control={<Radio />}
                    label="Casual Leave"
                  />
                  <FormControlLabel
                    value="Sick Leave"
                    control={<Radio />}
                    label="Sick Leave"
                  />
                  <FormControlLabel
                    value="Annual Leave"
                    control={<Radio />}
                    label="Annual Leave"
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={12} mt="8px">
                <Typography
                  variant="body1"
                  fontWeight={500}
                  textAlign="left"
                  mb="4px"
                >
                  Duration:
                </Typography>
                <RadioGroup
                  row
                  name="duration"
                  value={leaveData.duration}
                  onChange={(e) =>
                    setLeaveData({ ...leaveData, duration: e.target.value })
                  }
                >
                  <FormControlLabel
                    value="Half Day"
                    control={<Radio />}
                    label="Half Day"
                  />
                  <FormControlLabel
                    value="Full Day"
                    control={<Radio />}
                    label="Full Day"
                  />
                  <FormControlLabel
                    value="Short Leave"
                    control={<Radio />}
                    label="Short Leave"
                  />
                </RadioGroup>
              </Grid>

              <Grid item sm={6} xs={12} mt="8px">
                <DatePicker
                  label="Start Date"
                  value={leaveData.start_date}
                  onChange={(date) =>
                    setLeaveData({ ...leaveData, start_date: date })
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth required />
                  )}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item sm={6} xs={12} mt="8px">
                <DatePicker
                  label="End Date"
                  value={leaveData.end_date}
                  onChange={(date) =>
                    setLeaveData({ ...leaveData, end_date: date })
                  }
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth required />
                  )}
                />
              </Grid>

              <Grid item xs={12} mt="8px">
                <TextField
                  label="Reason for Leave (optional)"
                  fullWidth
                  multiline
                  rows={4}
                  value={leaveData.reason}
                  onChange={(e) =>
                    setLeaveData({ ...leaveData, reason: e.target.value })
                  }
                />
              </Grid>
            </Grid>

            <CustomButton type="submit" fullWidth sx={{ mt: '32px' }}>
              Submit Request
            </CustomButton>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

export default SubmitLeaveForm;
