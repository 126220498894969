// slices/stripeSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// Create Checkout Session
export const createCheckoutSession = createAsyncThunk(
  'stripe/createCheckoutSession',
  async ({ priceId, metadata }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/v1/stripe/create-checkout-session', {
        priceId,
        metadata,
      });
      return { ...response.data, metadata };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchStripePlans = createAsyncThunk(
  'stripe/fetchStripePlans',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/v1/stripe/stripe-plans');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch plans');
    }
  }
);

export const cancelPlan = createAsyncThunk(
  'stripe/cancelPlan',
  async (subscriptionId, { rejectWithValue }) => {
    try {
      // You can replace this with an actual API call to cancel the subscription
      const response = await axios.post('/v1/stripe/cancel-subscription', {
        subscriptionId,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const changeSubscription = createAsyncThunk(
  'stripe/changeSubscription',
  async (
    { currentSubscriptionId, newPlanId, isDowngrade },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post('/v1/stripe/change-subscription', {
        currentSubscriptionId,
        newPlanId,
        isDowngrade,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const stripeSlice = createSlice({
  name: 'stripe',
  initialState: {
    sessionId: null,
    stripePlans: [],
    subscription: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCheckoutSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionId = action.payload.id;
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchStripePlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStripePlans.fulfilled, (state, action) => {
        state.loading = false;
        state.stripePlans = action.payload.data;
      })
      .addCase(fetchStripePlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(cancelPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelPlan.fulfilled, (state, action) => {
        state.loading = false;
        // Handle success state if needed
        state.customerSubscription = action.payload;
      })
      .addCase(cancelPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(changeSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changeSubscription.fulfilled, (state, action) => {
        state.loading = false;
        console.log('🚀 ~ .addCase ~ action.payloa:', action.payload);
        state.subscription = action.payload.id; // Store the updated subscription details
      })
      .addCase(changeSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default stripeSlice.reducer;
