import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setPassword } from '../../Redux/slices/authSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress, TextField, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../Components/MiscComponents/CustomButton';
import UnAuthPageLayout from '../../Components/PageLayout/UnAuthPageLayout';

const SetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirming password is required'),
  });

  const onSubmit = (values, { setSubmitting }) => {
    const { password } = values;
    dispatch(setPassword({ password, token }))
      .unwrap()
      .then(() => {
        toast.success('Password set successfully');
        navigate('/');
      })
      .catch((error) => {
        toast.error(error || 'An error occurred while setting the password.');
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (!token) {
      toast.error('Invalid or expired link.');
      navigate('/*');
    }
  }, [token, navigate]);

  return (
    <UnAuthPageLayout>
      <Typography variant="h5" textAlign="left" fontWeight={500} mb="42px">
        Welcome to Daftar! Please Set Your Password to Continue.
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ getFieldProps, errors, touched, isSubmitting }) => (
          <Form>
            <Field
              as={TextField}
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
              autoComplete="new-password"
              {...getFieldProps('password')}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              style={{ margin: 0, minHeight: '70px', marginBottom: '10px' }}
            />
            <Field
              as={TextField}
              variant="outlined"
              margin="normal"
              fullWidth
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              {...getFieldProps('confirmPassword')}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              style={{ margin: 0, minHeight: '70px' }}
            />
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: '12px', position: 'relative' }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: 'primary.contrastText',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              ) : (
                'Set Password'
              )}
            </CustomButton>
          </Form>
        )}
      </Formik>
    </UnAuthPageLayout>
  );
};

export default SetPassword;
