import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationById } from '../../Redux/slices/organizationSlice';
import {
  cancelPlan,
  createCheckoutSession,
  changeSubscription,
} from '../../Redux/slices/stripeSlice';
import { getAllSubscriptionPlans } from '../../Redux/slices/subscriptionplanSlice';
import CustomButton from '../../Components/MiscComponents/CustomButton';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  Modal,
  Button,
  Card,
  CardContent,
  CardActions,
  Chip,
} from '@mui/material';
import { toast } from 'react-toastify';
import Loader from '../../Components/MiscComponents/Loader';
import { loadStripe } from '@stripe/stripe-js';
import { PLAN_CANCEL_ERROR, PLAN_CANCELED_SUCCESSFULLY, PLAN_DOWNGRADED_SUCCESSFULLY, PLAN_NOT_SELECTED_ERROR } from '../../Utils/Constants';

const stripePromise = loadStripe(
  'pk_test_51PVS4j1ctBJ7HF5LmayZvP5c06XMGKLS11qaa1cbsxB7EKCvj9JzRxPmMdazGMw9KSQy2hPU54j5Gg8ONjLAdMWK00IRuhOrKJ'
);

const PlanPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.currentUser?.data);
  const organization = useSelector(
    (state) => state.organizations.currentOrganization
  );
  const plans = useSelector((state) => state.subscriptionPlans.apiPlans);
  const isLoading = useSelector((state) => state.subscriptionPlans.isLoading);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  useEffect(() => {
    if (user?.active_organization) {
      dispatch(getOrganizationById(user.active_organization));
      dispatch(getAllSubscriptionPlans());
    }
  }, [dispatch, user]);

  const isDowngrade = (selectedPlan, currentPlan) => {
    return selectedPlan.price < currentPlan.price;
  };

  const handleUpgradePlan = async () => {
    if (!selectedPlan) {
      toast.error(PLAN_NOT_SELECTED_ERROR);
      return;
    }

    const currentPlan = organization.subscription.subscriptionPlanSnapshot;
    const downgrade = isDowngrade(selectedPlan, currentPlan);

    const stripe = await stripePromise;
    if (!stripe) {
      throw new Error('Stripe.js has not loaded correctly.');
    }

    try {
      const resultAction = await dispatch(
        changeSubscription({
          currentSubscriptionId:
            organization.subscription.subscriptionPlanSnapshot.subscriptionId,
          newPlanId: selectedPlan.priceId,
          isDowngrade: downgrade,
        })
      );

      if (changeSubscription.rejected.match(resultAction)) {
        throw new Error('Failed to create Checkout Session');
      }

      const session = resultAction.payload;
      if (downgrade) {
        // For downgrades, we don't need to redirect to Stripe
        toast.success(
          PLAN_DOWNGRADED_SUCCESSFULLY
        );
        setIsModalOpen(false);
      } else {
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        if (error) {
          throw error;
        }
      }
    } catch (error) {
      console.error('Upgrade plan error:', error.message);
      toast.error(`Upgrade plan error: ${error.message}`);
    }
  };

  const handleCancelPlan = () => {
    if (organization?.subscription?.subscriptionPlanSnapshot) {
      const { subscriptionId } =
        organization.subscription.subscriptionPlanSnapshot;
      dispatch(cancelPlan(subscriptionId))
        .then(() => {
          setIsCancelled(true);
          toast.info(
            PLAN_CANCELED_SUCCESSFULLY
          );
        })
        .catch((error) => {
          console.error('Error cancelling plan:', error);
          toast.error(PLAN_CANCEL_ERROR);
        });
    }
  };

  const plan = organization?.subscription?.subscriptionPlanSnapshot;

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" fontWeight={500} gutterBottom>
        Your Plan
      </Typography>
      {plan ? (
        <Paper elevation={3} sx={{ p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" fontWeight={500}>
                Plan Name:
              </Typography>
              <Typography>{plan.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" fontWeight={500}>
                Price:
              </Typography>
              <Typography>
                ${plan.price} per {plan.billingFrequency}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" fontWeight={500}>
                Max Users:
              </Typography>
              <Typography>{plan.maxUsers}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" fontWeight={500}>
                Max Projects:
              </Typography>
              <Typography>{plan.maxProjects}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight={500}>
                Allowed Features:
              </Typography>
              <Typography>{plan.allowedFeatures.join(', ')}</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <CustomButton
              label="Change Plan"
              onClick={() => setIsModalOpen(true)}
            />
            <CustomButton
              label="Cancel Plan"
              onClick={handleCancelPlan}
              color="error"
              disabled={isCancelled || plan.name.toLowerCase() === 'free'}
            />
          </Box>
        </Paper>
      ) : (
        <Loader />
      )}

      {/* Modal for plan selection */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 1200,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Select a Plan
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {isLoading ? (
            <Loader />
          ) : plans && plans.length > 0 ? (
            <Grid container spacing={3}>
              {plans.map((plan) => (
                <Grid item xs={12} sm={6} md={4} key={plan.id}>
                  <Card
                    sx={{
                      cursor: 'pointer',
                      border:
                        selectedPlan?.id === plan.id
                          ? '2px solid #1976d2'
                          : 'none',
                      boxShadow:
                        selectedPlan?.id === plan.id
                          ? '0 0 10px rgba(25, 118, 210, 0.5)'
                          : 'none',
                    }}
                    onClick={() => {
                      setSelectedPlan(plan);
                      console.log('Selected plan:', plan); // Log the selected plan
                    }}
                  >
                    <CardContent
                      sx={{
                        textAlign: 'center',
                        padding: '20px',
                        backgroundColor:
                          selectedPlan?.id === plan.id ? '#fff' : '#fff',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      <Typography
                        variant="h5"
                        fontWeight={600}
                        sx={{
                          color: '#1976d2',
                          marginBottom: '10px',
                        }}
                      >
                        {plan.name}
                      </Typography>

                      <Typography
                        variant="h4"
                        fontWeight={700}
                        sx={{
                          color: '#333',
                          marginBottom: '10px',
                        }}
                      >
                        ${plan.price}{' '}
                        <span style={{ fontSize: '16px', fontWeight: 400 }}>
                          per {plan.billingFrequency}
                        </span>
                      </Typography>

                      <Divider sx={{ marginBottom: '15px' }} />

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          Max Users:{' '}
                          <span style={{ fontWeight: 700 }}>
                            {plan.maxUsers}
                          </span>
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          Max Projects:{' '}
                          <span style={{ fontWeight: 700 }}>
                            {plan.maxProjects}
                          </span>
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          Allowed Features:
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '5px',
                            justifyContent: 'center',
                            mt: 1,
                          }}
                        >
                          {plan.allowedFeatures.map((feature, index) => (
                            <Chip
                              key={index}
                              label={feature}
                              variant="outlined"
                              size="small"
                              sx={{
                                backgroundColor: '#e3f2fd',
                                fontWeight: 500,
                                fontSize: '12px',
                                color: '#1976d2',
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>No plans available at the moment.</Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button
              variant="contained"
              onClick={handleUpgradePlan}
              disabled={!selectedPlan}
            >
              Proceed to Checkout
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PlanPage;
