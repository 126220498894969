import React, { useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  TextField,
  InputAdornment,
} from '@mui/material';
import CustomButton from '../../Components/MiscComponents/CustomButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Add, Search } from '@mui/icons-material';
import Loader from './Loader';
import { DatePicker } from '@mui/x-date-pickers';
import Colors from '../../Utils/Colors';

const ContentWrapper = ({
  children,
  heading = '',
  addButtonText = '',
  addButtonOnClick,
  additionalButtonOnClick = '',
  additionalButtonText,
  filters,
  isLoading,
  hideAddIcon = false,
  onClearFilters,
}) => {
  const [filterOpen, setFilterOpen] = useState(false);

  const toggleFilter = () => setFilterOpen(!filterOpen);

  const areFiltersApplied =
    filters?.filter((item) => !!item.value)?.length || 0;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', md: 'center' },
          gap: { xs: '18px', md: '12px' },
        }}
      >
        <Typography variant="h4" fontWeight={500} textAlign="left">
          {heading}
        </Typography>

        <Box
          display="flex"
          alignItems="stretch"
          gap="12px"
          width={{ xs: '100%', md: 'auto' }}
        >
          {!!filters?.length && (
            <CustomButton
              onClick={toggleFilter}
              aria-label="filter"
              variant="outlined"
              sx={{
                border: `1px solid ${Colors.border} !important`,
                color: `${Colors.text} !important`,
                width: '46px',
                height: '46px',
                minWidth: '46px',
                padding: '0px',
                position: 'relative',
              }}
            >
              <FilterListIcon />
              {!!areFiltersApplied && (
                <Box
                  sx={{
                    position: 'absolute',
                    width: '20px',
                    width: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: Colors.primary,
                    top: '-10px',
                    left: '-10px',
                    borderRadius: '10px',
                    fontSize: '12px',
                    color: 'white',
                  }}
                >
                  {areFiltersApplied}
                </Box>
              )}
            </CustomButton>
          )}

          {!!addButtonOnClick && !!addButtonText && (
            <CustomButton
              variant="contained"
              color="primary"
              startIcon={!hideAddIcon && <Add />}
              onClick={addButtonOnClick}
              sx={{ width: { xs: '100%', md: 'auto' } }}
            >
              {addButtonText || 'Add'}
            </CustomButton>
          )}
          {!!additionalButtonOnClick && !!additionalButtonText && (
            <CustomButton
              variant="contained"
              color="primary"
              onClick={additionalButtonOnClick}
              sx={{ width: { xs: '100%', md: 'auto' } }}
            >
              {additionalButtonText}
            </CustomButton>
          )}
        </Box>
      </Box>
      {!!areFiltersApplied && (
        <Typography
          variant="body2"
          textAlign="left"
          sx={{
            textDecoration: 'none',
            cursor: 'pointer',
            color: 'primary',
          }}
          onClick={onClearFilters}
        >
          Clear filters
        </Typography>
      )}

      <Collapse in={filterOpen} sx={{ mb: '24px', mt: '12px' }}>
        <Box
          pt={'24px'}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'flex-start',
            gap: '12px',
            width: '100%',
          }}
        >
          {filters?.map((filter, idx) =>
            filter.type === 'input' ? (
              <TextField
                key={idx}
                type="search"
                placeholder={filter.placeholder}
                value={filter.value}
                onChange={filter.onChange}
                size="small"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                sx={{ flex: { xs: 1, sm: 1 / 3 } }}
              />
            ) : filter.type === 'dropdown' ? (
              <FormControl
                variant="outlined"
                sx={{ flex: { xs: 1, sm: 1 / 3 } }}
              >
                <InputLabel>{filter.label}</InputLabel>
                <Select
                  value={filter.value}
                  onChange={filter.onChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Status Filter' }}
                  size="small"
                  sx={{ flex: { xs: 1, sm: 1 / 3 } }}
                  label={filter.label}
                >
                  {filter.options?.map((opt, idx2) => (
                    <MenuItem key={idx2} value={opt.value}>
                      {opt.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : filter.type === 'date' ? (
              <DatePicker
                label={filter.label}
                value={filter.value}
                onChange={filter.onChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
                sx={{ flex: { xs: 1, sm: 1 / 3 } }}
              />
            ) : (
              ''
            )
          )}
        </Box>
      </Collapse>

      {isLoading ? <Loader height="60vh" /> : children}
    </Box>
  );
};

export default ContentWrapper;
