import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { useState } from 'react';
import LayoutHeader from '../../Components/PageLayout/LayoutHeader';
import daftarLogo from '../../Assets/images/daftar-logo.png';
import { toast } from 'react-toastify';
import { logger } from '../../Services/loggerService';
import {
  DRAWER_WIDTH,
  SIGNUP_SELECT_PLAN_ERROR,
  SIGNUP_STEPS,
  DRAWER_WIDTH as drawerWidth,
} from '../../Utils/Constants';

import LayoutSpacing from '../../Utils/LayoutSpacing';
import UserDetail from './UserDetail';

import {
  Button,
  Drawer,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import Colors from '../../Utils/Colors';
import { ERROR_MESSAGES } from '../../Utils/ValidationErrors';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/MiscComponents/Loader';
import CompanyDetail from './CompanyDetail';
import { getCurrentUser, signupUser } from '../../Redux/slices/authSlice';
import { createOrganization } from '../../Redux/slices/organizationSlice';
import PlanSelection from './PlanSelection';
import { createCheckoutSession } from '../../Redux/slices/stripeSlice';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_KEY}`
);

const SignupMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [signUpData, setSignUpData] = useState({
    members: [],
  });

  const handleDrawerToggle = (state) =>
    setMobileOpen(typeof state !== 'undefined' ? state : !mobileOpen);

  const handleChange = (name, value) => {
    setSignUpData((prev) => {
      let updatedSignUpData = { ...prev };
      updatedSignUpData[name] = value;
      return updatedSignUpData;
    });
  };

  const handleCheckout = async (priceId, organizationId) => {
    console.log('🚀 ~ handleCheckout ~ organizationId:', organizationId);
    try {
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Stripe.js has not loaded correctly.');
      }

      const resultAction = await dispatch(
        createCheckoutSession({
          priceId,
          metadata: {
            organizationId: organizationId,
            isNewSubscription: true,
          },
        })
      );

      if (createCheckoutSession.rejected.match(resultAction)) {
        throw new Error('Failed to create Checkout Session');
      }

      const session = resultAction.payload;

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        throw error;
      }
    } catch (error) {
      console.error('Checkout error:', error.message);
      toast.error(`Checkout error: ${error.message}`);
    }
  };

  const handleSubmitSignUp = async () => {
    setIsLoading(true);
    if (!validateStep(activeStep)) {
      setIsLoading(false);
      return;
    }
    try {
      // Signup user
      const signupResult = await dispatch(
        signupUser({
          first_name: signUpData.first_name,
          last_name: signUpData.last_name,
          email: signUpData.email,
          password: signUpData.password,
        })
      );
      // Check if signup was successful
      if (!signupResult.success) {
        logger.error('Signup failed', signupResult.message);
      }

      // Create organization
      const orgResult = await dispatch(
        createOrganization({
          name: signUpData.company_name,
          url: signUpData.company_url,
          description: signUpData.company_description,
          logo: signUpData.company_logo,
          subscription: signUpData.planId,
        })
      );
      // Check if organization creation was successful
      if (!orgResult.success) {
        logger.error('Organization creation failed', orgResult.message);
      }

      logger.info('Signup and organization creation successful!', {
        'Signup Result': signupResult,
        'Organization Result': orgResult,
      });
      toast.success('Signup and organization creation successful!');

      await handleCheckout(
        signUpData.selectedPlan,
        orgResult.payload.metadata.id
      );

      dispatch(getCurrentUser());
    } catch (error) {
      logger.error('Error in signup process:', error);
      toast.error(`Error: ${error.message || 'An unexpected error occurred'}`);
    } finally {
      setIsLoading(false);
      logger.info('Signup process ended');
    }
  };

  const validateStep = (step) => {
    let errors = {};
    switch (step) {
      case 0:
        if (!signUpData.selectedPlan) {
          errors.selectedPlan = SIGNUP_SELECT_PLAN_ERROR;
        }
        break;
      case 1:
        if (!signUpData.first_name) {
          errors.first_name = ERROR_MESSAGES.FIRST_NAME_REQUIRED;
        }
        if (!signUpData.last_name) {
          errors.last_name = ERROR_MESSAGES.LAST_NAME_REQUIRED;
        }
        if (!signUpData.email) {
          errors.email = ERROR_MESSAGES.EMAIL_REQUIRED;
        } else if (!/\S+@\S+\.\S+/.test(signUpData.email)) {
          errors.email = ERROR_MESSAGES.EMAIL_INVALID;
        }
        if (!signUpData.password) {
          errors.password = ERROR_MESSAGES.PASSWORD_REQUIRED;
        }
        break;

      case 2:
        if (!signUpData.company_name) {
          errors.company_name = ERROR_MESSAGES.CLIENT_COMPANY_NAME_REQUIRED;
        }
        if (!signUpData.company_url) {
          errors.company_url = ERROR_MESSAGES.CLIENT_COMPANY_URL_REQUIRED;
        }
        if (!signUpData.company_description) {
          errors.company_description =
            ERROR_MESSAGES.CLIENT_COMPANY_DESCRIPTION_REQUIRED;
        }
        if (!signUpData.company_logo) {
          errors.company_logo = ERROR_MESSAGES.CLIENT_COMPANY_LOGO_REQUIRED;
        }
        break;

      default:
        break;
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(activeStep)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <PlanSelection
            handleChange={handleChange}
            selectedPlan={signUpData.selectedPlan}
          />
        );
      case 1:
        return (
          <UserDetail
            handleChange={handleChange}
            signUpData={signUpData}
            validationErrors={validationErrors}
          />
        );
      case 2:
        return (
          <CompanyDetail
            handleChange={handleChange}
            signUpData={signUpData}
            validationErrors={validationErrors}
          />
        );
      default:
        return;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {activeStep !== 3 && (
        <>
          <Box
            component="nav"
            sx={{
              width: { sm: DRAWER_WIDTH },
              flexShrink: { sm: 0 },
            }}
            aria-label="mailbox folders"
          >
            <Drawer
              open
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: DRAWER_WIDTH,
                },
              }}
            >
              <Box
                sx={{
                  borderRight: `1px solid ${Colors.border}`,
                  borderRadius: LayoutSpacing.headerBorderRadius,
                  minHeight: '100%',
                  height: 'max-content',
                  overflowY: 'auto',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                }}
              >
                <Toolbar sx={{ justifyContent: 'center', pt: '12px' }}>
                  <Box
                    component="img"
                    alt="Logo"
                    src={daftarLogo}
                    sx={{ height: '35px', cursor: 'pointer' }}
                  />
                </Toolbar>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 10,
                  }}
                >
                  <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    sx={{
                      '& .MuiStepIcon-text': {
                        fill: '#FFF',
                      },
                    }}
                  >
                    {SIGNUP_STEPS?.map((step, index) => (
                      <Step key={step}>
                        <StepLabel>{step}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Box>
            </Drawer>
          </Box>
        </>
      )}

      <Box
        component="main"
        sx={(theme) => ({
          p: LayoutSpacing.pagePadding,

          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          [theme.breakpoints.down('sm')]: {
            p: LayoutSpacing.pagePaddingSm,
            pt: '32px',
          },
        })}
      >
        {isLoading ? (
          <Loader height="60vh" />
        ) : (
          <>
            <Toolbar />
            {getStepContent(activeStep)}
            {activeStep !== 3 && (
              <Box
                sx={(theme) => ({
                  my: 3,
                  display: 'flex',
                  justifyContent: 'space-end',
                  alignItems: 'center',
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    px: 2,
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'row',
                    ml: 'auto',
                    [theme.breakpoints.down('sm')]: {
                      width: '100%',
                      justifyContent: 'space-between',
                    },
                  })}
                >
                  {activeStep === SIGNUP_STEPS.length - 1 ? (
                    <Button
                      variant="contained"
                      onClick={handleSubmitSignUp}
                      sx={(theme) => ({
                        mt: 1,
                        mr: 1,
                        [theme.breakpoints.down('sm')]: {
                          size: 'small',
                          fontSize: '0.875rem',
                        },
                      })}
                    >
                      Finish
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={(theme) => ({
                        mt: 1,
                        mr: 1,
                        [theme.breakpoints.down('sm')]: {
                          size: 'small',
                          fontSize: '0.875rem',
                        },
                      })}
                    >
                      Continue
                    </Button>
                  )}
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={(theme) => ({
                      mt: 1,
                      mr: 1,
                      [theme.breakpoints.down('sm')]: {
                        size: 'small',
                        fontSize: '0.875rem',
                      },
                    })}
                  >
                    Back
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default SignupMain;
