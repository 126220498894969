import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logger } from '../../Services/loggerService';

const initialState = {
  allLeaves: [],
  leaveDetails: null,
  loading: false,
  error: null,
  message: null,
  metadata: {
    totalResults: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 10,
  },
};

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

// Fetch all leaves with optional filters, pagination, sorting, and status
export const getAllLeaves = createAsyncThunk(
  'leaves/getAllLeaves',
  async (
    { filters = {}, status = '', page = 1, limit = 10, sort = 'createdAt' },
    { rejectWithValue }
  ) => {
    try {
      // Construct filters object to include status if it's provided
      if (status) {
        filters.status = status;
      }

      const response = await axios.get('/v1/leaves', {
        params: { ...filters, page, limit, sort },
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      logger.error('Get all leaves', error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch leave details by ID
export const getLeaveDetails = createAsyncThunk(
  'leaves/getLeaveDetails',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/v1/leaves/${id}`);
      return response.data;
    } catch (error) {
      logger.error('Get leave details', error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create a new leave request
export const createLeave = createAsyncThunk(
  'leaves/createLeave',
  async (leaveData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/v1/leaves', leaveData);
      return response.data;
    } catch (error) {
      logger.error('Create leave', error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Update an existing leave request
export const updateLeave = createAsyncThunk(
  'leaves/updateLeave',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/v1/leaves/${id}`, data);
      return response.data;
    } catch (error) {
      logger.error('Update leave', error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete a leave request
export const deleteLeave = createAsyncThunk(
  'leaves/deleteLeave',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/v1/leaves/${id}`);
      return response.data;
    } catch (error) {
      logger.error('Delete leave', error);
      return rejectWithValue(error.response.data);
    }
  }
);

const leaveSlice = createSlice({
  name: 'leaves',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLeaves.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLeaves.fulfilled, (state, action) => {
        state.loading = false;
        state.allLeaves = action.payload;
        state.metadata = action.payload.metadata;
      })
      .addCase(getAllLeaves.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getLeaveDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLeaveDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.leaveDetails = action.payload;
      })
      .addCase(getLeaveDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createLeave.pending, (state) => {
        state.loading = true;
      })
      .addCase(createLeave.fulfilled, (state, action) => {
        state.loading = false;
        state.allLeaves = action.payload;
        //state.allLeaves.push(action.payload);
      })
      .addCase(createLeave.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateLeave.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLeave.fulfilled, (state, action) => {
        state.loading = false;
        // Updating leave state upon fulfillment
      })
      .addCase(updateLeave.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteLeave.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLeave.fulfilled, (state, action) => {
        state.loading = false;
        state.allLeaves = state.allLeaves.filter(
          (leave) => leave._id !== action.payload._id
        );
      })
      .addCase(deleteLeave.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { reset } = leaveSlice.actions;
export default leaveSlice.reducer;
