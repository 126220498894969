import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAttendance } from '../../Redux/slices/attendanceSlice';
import CustomTable from '../../Components/MiscComponents/CustomTable';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { MONTHS, YEARS } from '../../Utils/Constants';
import ContentWrapper from '../../Components/MiscComponents/ContentWrapper';
import { formatDate, formatTime } from '../../Utils/functions';

const ViewAttendance = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.currentUser?.data);
  const metadata = useSelector((state) => state.attendance.metadata);
  const attendanceData = useSelector(
    (state) => state.attendance.userAttendance
  );
  const { _id, firstName, lastName } = useParams();
  const loading = useSelector((state) => state.users.loading); // TODO: This loading is not working, need to discuss with Shehzore which loading will be working on this page

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const defaultLimit = 10; // Define a default limit if not using initialState from Redux

  useEffect(() => {
    if (user) {
      const monthIndex = MONTHS.indexOf(selectedMonth) + 1;
      const start =
        selectedMonth && selectedYear
          ? formatDate(
              moment(`${selectedYear}-${monthIndex}-01`).startOf('month')
            )
          : undefined;
      const end = start ? formatDate(moment(start).endOf('month')) : undefined;

      const attendanceData = {
        userId: _id,
        startDate: start,
        endDate: end,
        page: currentPage,
        limit: defaultLimit,
      };

      dispatch(getUserAttendance(attendanceData));
    }
  }, [dispatch, _id, currentPage, selectedMonth, selectedYear]);

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    if (!selectedYear) {
      setSelectedYear(moment().format('YYYY')); // Default to current year if year not selected
    }
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    if (selectedMonth && year) {
      // Recalculate and fetch attendance data for the selected month and year
      const monthIndex = MONTHS.indexOf(selectedMonth) + 1;
      const start = formatDate(
        moment(`${year}-${monthIndex}-01`).startOf('month')
      );
      const end = formatDate(moment(start).endOf('month'));
      dispatch(getUserAttendance({ userId: _id }));
    }
  };

  useEffect(() => {
    // Trigger data fetch when both month and year are selected
    if (selectedMonth && selectedYear && user) {
      const monthIndex = MONTHS.indexOf(selectedMonth) + 1;
      const start = formatDate(
        moment(`${selectedYear}-${monthIndex}-01`).startOf('month')
      );
      const end = formatDate(moment(start).endOf('month'));
      dispatch(
        getUserAttendance({ userId: _id, startDate: start, endDate: end })
      );
    }
  }, [selectedMonth, selectedYear, dispatch, user]);

  const tableHeaders = [
    {
      label: 'Date',
      key: 'date',
      format: formatDate, // Format for Date, e.g., "Feb 2nd, 2024"
    },
    {
      label: 'Check-in Time',
      key: 'check_in_time',
      format: formatTime, // Format for time, e.g., "9:15 AM"
    },
    {
      label: 'Check-out Time',
      key: 'check_out_time',
      format: formatTime, // Check if value exists, else show '---'
    },
    {
      label: 'Status',
      key: 'status',
      format: (value) => value,
    },
  ];

  const processAttendanceData = (data) => {
    return data.map((entry) => {
      const status =
        entry.check_in_time === 'N/A' && entry.check_out_time === 'N/A'
          ? 'Absent'
          : 'Present';
      return { ...entry, status };
    });
  };

  const handleClearFilters = () => {
    setSelectedMonth('');
    setSelectedYear('');
  };

  return (
    <ContentWrapper
      heading={`${firstName} ${lastName}'s Attendance Record`}
      isLoading={loading}
      filters={[
        {
          label: 'Month',
          type: 'dropdown',
          value: selectedMonth,
          onChange: (e) => handleMonthChange(e.target.value),
          options: [
            ...(MONTHS?.map((month) => ({ value: month, text: month })) || []),
          ],
        },
        {
          label: 'Year',
          type: 'dropdown',
          value: selectedYear,
          onChange: (e) => handleYearChange(e.target.value),
          options: [
            ...(YEARS?.map((year) => ({ value: year, text: year })) || []),
          ],
        },
      ]}
      onClearFilters={handleClearFilters}
    >
      <CustomTable
        data={processAttendanceData(attendanceData)}
        headers={tableHeaders}
        totalPages={metadata?.totalPages}
        currentPage={currentPage}
        handlePageChange={(event, page) => setCurrentPage(page)}
      />
    </ContentWrapper>
  );
};

export default ViewAttendance;
