import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';

function StepFour({ onboardingData, setOnboardingData, validationErrors }) {
  const handleClientChange = (e) => {
    setOnboardingData((prev) => ({
      ...prev,
      client_details: {
        ...prev?.client_details,
        [e.target.name]: e.target.value,
      },
    }));
  };
  return (
    <>
      <Typography variant="h5" fontWeight={500} mb={2}>
        Who your client is
      </Typography>
      <Grid item xs={12} mb={4}>
        <Typography fontWeight={500} marginBottom="5px">
          Add in any details for your client that you'd like to see show up on
          your project reports or invoices
        </Typography>
      </Grid>

      <Grid container spacing={2}>
        {/* Client Information */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Company Name"
            name="company_name"
            error={
              !!validationErrors?.client_details?.company_name &&
              !onboardingData?.client_details?.company_name
            }
            variant="outlined"
            value={onboardingData?.client_details?.company_name || ''}
            onChange={handleClientChange}
            style={{ marginBottom: '7px' }}
            fullWidth
          />
          {validationErrors?.client_details?.company_name &&
            !onboardingData?.client_details?.company_name && (
              <Typography variant="caption" color="error">
                {validationErrors?.client_details?.company_name}
              </Typography>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Empty space on purpose */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="First Name"
            name="first_name"
            variant="outlined"
            value={onboardingData?.client_details?.first_name}
            error={
              !!validationErrors?.client_details?.first_name &&
              !onboardingData?.client_details?.first_name
            }
            fullWidth
            style={{ marginBottom: '7px' }}
            onChange={handleClientChange}
          />
          {validationErrors?.client_details?.first_name &&
            !onboardingData?.client_details?.first_name && (
              <Typography variant="caption" color="error">
                {validationErrors?.client_details?.first_name}
              </Typography>
            )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Last Name"
            name="last_name"
            variant="outlined"
            error={
              !!validationErrors?.client_details?.last_name &&
              !onboardingData?.client_details?.last_name
            }
            value={onboardingData?.client_details?.last_name}
            fullWidth
            style={{ marginBottom: '7px' }}
            onChange={handleClientChange}
          />
          {validationErrors?.client_details?.last_name &&
            !onboardingData?.client_details?.last_name && (
              <Typography variant="caption" color="error">
                {validationErrors?.client_details?.last_name}
              </Typography>
            )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            name="email"
            variant="outlined"
            error={
              (!!validationErrors?.client_details?.email &&
                !onboardingData?.client_details?.email) ||
              (!!onboardingData?.client_details?.email &&
                !/\S+@\S+\.\S+/.test(onboardingData?.client_details?.email))
            }
            value={onboardingData?.client_details?.email || ''}
            fullWidth
            onChange={handleClientChange}
            style={{ marginBottom: '7px' }}
          />
          {validationErrors?.client_details?.email &&
            !onboardingData?.client_details?.email && (
              <Typography variant="caption" color="error">
                {validationErrors?.client_details?.email}
              </Typography>
            )}
          {onboardingData?.client_details?.email &&
            !/\S+@\S+\.\S+/.test(onboardingData?.client_details?.email) && (
              <Typography variant="caption" color="error">
                Email address is invalid
              </Typography>
            )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Phone Number"
            name="phone_number"
            variant="outlined"
            value={onboardingData?.client_details?.phone_number || ''}
            fullWidth
            onChange={handleClientChange}
            style={{ marginBottom: '7px' }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Country"
            name="country"
            variant="outlined"
            style={{ marginBottom: '7px' }}
            value={onboardingData?.client_details?.country || ''}
            onChange={handleClientChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Address"
            name="address"
            variant="outlined"
            style={{ marginBottom: '7px' }}
            onChange={handleClientChange}
            value={onboardingData?.client_details?.address || ''}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Zip Code"
            variant="outlined"
            name="zip_code"
            value={onboardingData?.client_details?.zip_code || ''}
            style={{ marginBottom: '7px' }}
            onChange={handleClientChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}

export default StepFour;
