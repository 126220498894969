import React, { useState } from 'react';
import InvoiceSteps from '../../Components/InvoiceComponent/InvoiceSteps';
import { INVOICE_STEPS } from '../../Utils/Constants';
import {
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getDefaultInvoiceItems } from '../../Redux/slices/invoiceSlice';
import DynamicBackButton from '../../Components/BackButton/DynamicBackButton';

const InvoiceStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [invoiceData, setInvoiceData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const fetchInvoiceItems = () => {
    dispatch(getDefaultInvoiceItems(invoiceData))
      .unwrap()
      .then((invoice) => {
        const updatedInvoiceData = {
          ...invoice,
          start_date: new Date(invoice?.start_date),
        };

        if (invoice?.end_date)
          updatedInvoiceData.end_date = new Date(invoice?.end_date);
        setInvoiceData(updatedInvoiceData);
        setLoading(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(`Error: ${error.message}`);
      });
  };

  const handleChange = (data) => {
    setInvoiceData(data);
  };

  const handleNext = () => {
    fetchInvoiceItems();
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <div>
      <DynamicBackButton />
      <Stepper activeStep={activeStep} sx={{ px: 2 }}>
        {INVOICE_STEPS?.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <InvoiceSteps
          step={activeStep}
          data={invoiceData}
          loading={loading}
          error={error}
          setInvoiceData={setInvoiceData}
          setActiveStep={setActiveStep}
          onChange={handleChange}
          onNext={handleNext}
          onBack={handleBack}
        />
        {loading && <CircularProgress />}
      </div>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        message={error}
      />
    </div>
  );
};

export default InvoiceStepper;
