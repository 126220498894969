export const ERROR_MESSAGES = {
  PROJECT_NAME_REQUIRED: 'Project name is required',
  BILLING_CURRENCY_REQUIRED: 'Billing Currency is required',
  BILLING_FREQUENCY_REQUIRED: 'Billing Frequency is required',
  AT_LEAST_ONE_MEMBER_REQUIRED: 'At least one member is required',
  MEMBER_USER_REQUIRED: 'Member is required',
  MEMBER_ROLE_REQUIRED: 'Role is required',
  MEMBER_ENGAGEMENT_TYPE_REQUIRED: 'Engagement type is required',
  MEMBER_BILLING_RATE_REQUIRED: 'Rate is required',
  FIRST_NAME_REQUIRED: 'First name is required',
  LAST_NAME_REQUIRED: 'Last name is required',
  EMAIL_REQUIRED: 'Email is required',
  EMAIL_INVALID: 'Email address is invalid',
  CLIENT_COMPANY_NAME_REQUIRED: 'Company name is required',
  CLIENT_COMPANY_URL_REQUIRED: 'Company url is required',
  CLIENT_COMPANY_DESCRIPTION_REQUIRED: 'Company description is required',
  CLIENT_COMPANY_LOGO_REQUIRED: 'Company logo is required',
  PASSWORD_REQUIRED: 'Password is required',
};
