import { Grid, TextField, Typography, Box } from '@mui/material';
import React from 'react';

function UserDetail({ signUpData, handleChange, validationErrors }) {
  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <Typography variant="h5" fontWeight={500} mb={2}>
          About You
        </Typography>
        <Grid item xs={12}>
          <Typography fontWeight={500} mb={6}>
            Tell us about yourself
          </Typography>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="First Name"
            variant="outlined"
            id="first_name"
            value={signUpData?.first_name}
            onChange={(e) => handleChange('first_name', e.target.value)}
            error={!!validationErrors.first_name}
            helperText={validationErrors.first_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Last Name"
            variant="outlined"
            id="last_name"
            value={signUpData?.last_name}
            onChange={(e) => handleChange('last_name', e.target.value)}
            error={!!validationErrors.last_name}
            helperText={validationErrors.last_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            id="email"
            value={signUpData?.email}
            onChange={(e) => handleChange('email', e.target.value)}
            error={!!validationErrors.email}
            helperText={validationErrors.email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            id="password"
            type="password" // This will make the input a password field
            value={signUpData?.password}
            onChange={(e) => handleChange('password', e.target.value)}
            error={!!validationErrors.password}
            helperText={validationErrors.password}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default UserDetail;
