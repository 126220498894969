import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllWorkLogs,
  getAllUsersWorkLogs,
} from '../../Redux/slices/workLogsSlice';
import { getAllProjects } from '../../Redux/slices/projectSlice';
import CustomTable from '../../Components/MiscComponents/CustomTable';
import ContentWrapper from '../../Components/MiscComponents/ContentWrapper';
import { isValid } from 'date-fns';
import moment from 'moment';
import { formatDate } from '../../Utils/functions';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import AppRocketWorkingLog from '../../Components/WorkLogComponent/WorkLog';

const WorkLogHistory = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.currentUser?.data);
  const workLogs = useSelector((state) => state.workLogs.workLogs);
  const projects = useSelector((state) => state.projects.allProjects);
  const metadata = useSelector((state) => state.workLogs.metadata);
  const allUsersWorkLogs = useSelector(
    (state) => state.workLogs.allUsersWorkLogs
  );

  console.log(user.organizationData?.role);
  const [selectedProject, setSelectedProject] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAllRecords, setShowAllRecords] = useState(false);
  const [workLogModalOpen, setWorkLogModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllProjects());
    if (showAllRecords) {
      dispatch(
        getAllUsersWorkLogs({
          startDate,
          endDate,
          projectId: selectedProject,
          page: currentPage,
        })
      ); // Dispatch fetch for all users' work logs
    } else {
      filterWorkLogs(); // Dispatch fetch for current user's work logs with filters
    }
  }, [
    dispatch,
    user?.id,
    startDate,
    endDate,
    currentPage,
    selectedProject,
    showAllRecords,
  ]); // Update when user ID, start date, end date, or page changes

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const filterWorkLogs = () => {
    // Format startDate to the start of the day
    const formattedStartDate = startDate
      ? formatDate(moment(startDate).startOf('day'))
      : null;

    // Format endDate to the end of the day
    const formattedEndDate = endDate
      ? formatDate(moment(endDate).endOf('day'))
      : null;

    dispatch(
      getAllWorkLogs({
        userId: user.id,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        projectId: selectedProject,
        page: currentPage,
      })
    );
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    setCurrentPage(1); // Reset to first page when start date changes
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    setCurrentPage(1); // Reset to first page when end date changes
  };

  const handleNewButtonClick = () => {
    setWorkLogModalOpen(true);
  };

  const handleCloseWorkLogModal = () => {
    setWorkLogModalOpen(false);
  };

  // Define headers for the CustomTable
  const tableHeaders = [
    {
      label: 'Date',
      key: 'date',
      format: (value) =>
        isValid(new Date(value)) ? formatDate(value) : 'Invalid date',
    },
    { label: 'Project', key: 'project.name' },
    { label: 'Hours Logged', key: 'duration' },
    { label: 'Description', key: 'description' },
  ];

  const handleShowAllRecords = () => {
    setShowAllRecords(!showAllRecords); // Toggle between showing all records and user's records
    setCurrentPage(1); // Reset to first page when toggling
  };

  const handleClearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedProject('');
  };

  const dataToDisplay = showAllRecords ? allUsersWorkLogs : workLogs;
  const isUserAdminOrHR = ['Admin', 'HR'].includes(user.organizationData?.role);

  return (
    <ContentWrapper
      heading={'Work Log History'}
      addButtonText={showAllRecords ? 'Show my records' : 'Show all records'} // Button text toggles based on showAllRecords state
      addButtonOnClick={handleShowAllRecords} // Button click toggles between showing all records and user's records
      additionalButtonText={isUserAdminOrHR ? 'Add worklog' : undefined}
      additionalButtonOnClick={
        isUserAdminOrHR ? handleNewButtonClick : undefined
      }
      hideAddIcon
      filters={[
        {
          type: 'date',
          label: 'Start Date',
          placeholder: 'Start Date',
          value: startDate,
          onChange: handleStartDate,
        },
        {
          type: 'date',
          label: 'End Date',
          placeholder: 'End Date',
          value: endDate,
          onChange: handleEndDate,
        },
        {
          type: 'dropdown',
          value: selectedProject,
          onChange: handleProjectChange,
          options: [
            { value: '', text: 'All Projects' },
            ...(projects?.map((project) => ({
              value: project.id,
              text: project.name,
            })) || []),
          ],
        },
      ]}
      onClearFilters={handleClearFilters}
    >
      <CustomTable
        headers={tableHeaders}
        data={dataToDisplay}
        totalPages={metadata.totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        emptyStateMessage="No work logs found"
      />
      <CustomDialog
        open={workLogModalOpen}
        onClose={handleCloseWorkLogModal}
        title="Add Work Log"
      >
        <AppRocketWorkingLog
          onClose={handleCloseWorkLogModal}
          isUserAdminOrHR={isUserAdminOrHR}
        />
      </CustomDialog>
    </ContentWrapper>
  );
};

export default WorkLogHistory;
