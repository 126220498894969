import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import usersSlice from './slices/userSlice';
import projectSlice from './slices/projectSlice';
import authSlice from './slices/authSlice';
import companyResourceSlice from './slices/companyResourceSlice';
import fileSlice from './slices/fileSlice';
import appraisalSlice from './slices/appraisalSlice';
import leaveSlice from './slices/leaveSlice';
import slackSlice from './slices/slackSlice';
import reimbursementSlice from './slices/reimbursementSlice';
import attendanceSlice from './slices/attendanceSlice';
import workLogsSlice from './slices/workLogsSlice';
import invoiceSlice from './slices/invoiceSlice';
import onboardingSlice from './slices/onboardingSlice';
import organizationSlice from './slices/organizationSlice';
import subscriptionSlice from './slices/subscriptionSlice';
import stripeSlice from './slices/stripeSlice';
import subscriptionplanSlice from './slices/subscriptionplanSlice';
import departmentSlice from './slices/departmentSlice';

export const store = configureStore({
  reducer: {
    users: usersSlice,
    auth: authSlice,
    projects: projectSlice,
    companyResource: companyResourceSlice,
    files: fileSlice,
    appraisals: appraisalSlice,
    leaves: leaveSlice,
    slack: slackSlice,
    reimbursement: reimbursementSlice,
    attendance: attendanceSlice,
    workLogs: workLogsSlice,
    invoices: invoiceSlice,
    onboarding: onboardingSlice,
    organizations: organizationSlice,
    subscription: subscriptionSlice,
    stripe: stripeSlice,
    subscriptionPlans: subscriptionplanSlice,
    departments: departmentSlice,
  },
  middleware: [...getDefaultMiddleware({ serializableCheck: false })],
});
