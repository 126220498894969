import {
  Box,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { PROJECT_MEMBERS_ROLES } from '../../Utils/Constants';
import { DeleteOutlined } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Colors from '../../Utils/Colors';

function StepTwo({
  onboardingData,
  setOnboardingData,
  handleMemberChange,
  validationErrors,
}) {
  const handleAddMember = () => {
    setOnboardingData((prev) => ({
      ...prev,
      members: [
        ...prev?.members,
        {
          first_name: '',
          last_name: '',
          email: '',
          project_role: '',
          engagement_type: '',
          billing_rate: null,
          hour_cap: null,
        },
      ],
    }));
  };

  const handleRemoveMember = (index) => {
    const newMember = [...onboardingData?.members];
    newMember.splice(index, 1);
    setOnboardingData((prev) => ({
      ...prev,
      members: newMember,
    }));
  };
  return (
    <div>
      <Typography variant="h5" fontWeight={500} mb={2}>
        Who you're working with
      </Typography>
      <Grid item xs={12} sx={{ mb: 4 }}>
        <Typography fontWeight={500}>
          Who else is working with you on {onboardingData?.project_name}?
        </Typography>
      </Grid>

      <Card className="no-hover" variant="outlined" sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {/* Headers */}
          <Grid item xs={6} sm={3}>
            <Typography textAlign="left" variant="subtitle1">
              First Name
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography textAlign="left" variant="subtitle1">
              Last Name
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography textAlign="left" variant="subtitle1">
              Email
            </Typography>
          </Grid>

          <Grid item xs={6} sm={2}>
            <Typography textAlign="left" variant="subtitle1">
              Role
            </Typography>
          </Grid>
          <Grid item xs={6} sm={1}>
            {/* Empty spacing for delete icon */}
          </Grid>

          {onboardingData?.members?.map((member, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6} sm={3}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="first_name"
                  value={member.first_name}
                  onChange={(e) => handleMemberChange(index, e)}
                  sx={{ mb: 1 }}
                  error={!!validationErrors[`members.[${index}].first_name`]}
                  helperText={validationErrors[`members.[${index}].first_name`]}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="last_name"
                  value={member.last_name}
                  onChange={(e) => handleMemberChange(index, e)}
                  sx={{ mb: 1 }}
                  error={!!validationErrors[`members.[${index}].last_name`]}
                  helperText={validationErrors[`members.[${index}].last_name`]}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={member.email}
                  onChange={(e) => handleMemberChange(index, e)}
                  sx={{ mb: 1 }}
                  error={!!validationErrors[`members.[${index}].email`]}
                  helperText={validationErrors[`members.[${index}].email`]}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <FormControl
                  fullWidth
                  error={!!validationErrors[`members.[${index}].project_role`]}
                >
                  <InputLabel>Role</InputLabel>
                  <Select
                    name="project_role"
                    required
                    value={member?.project_role}
                    onChange={(e) => handleMemberChange(index, e)}
                    displayEmpty
                    label="Role"
                  >
                    {PROJECT_MEMBERS_ROLES?.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                  {validationErrors[`members.[${index}].project_role`] && (
                    <Typography variant="caption" color="error">
                      {validationErrors[`members.[${index}].project_role`]}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={1} display="flex" justifyContent="flex-end">
                <IconButton
                  onClick={() => handleRemoveMember(index)}
                  sx={{ color: 'error.main' }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Box
              onClick={handleAddMember}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                color: Colors.link,
                justifyContent: 'flex-end',
              }}
            >
              <AddBoxIcon />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                Add teammate
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default StepTwo;
