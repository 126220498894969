import { Box } from '@mui/material';
import React from 'react';
import { HashLoader } from 'react-spinners';
import Colors from '../../Utils/Colors';

const Loader = ({ height }) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      height={height || 'calc(100vh - 76px - 24px - 24px)'} // 76px: height of header, 24px: padding of main div
    >
      <HashLoader color={Colors.primary} loading={true} size={60} />
    </Box>
  );
};

export default Loader;
