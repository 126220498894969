import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Toolbar from '@mui/material/Toolbar';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import daftarLogo from '../../Assets/images/daftar-logo.png';
import {
  ADMIN_SIDEBAR,
  EMPLOYEE_SIDEBAR,
  DRAWER_WIDTH as drawerWidth,
} from '../../Utils/Constants';
import LayoutSpacing from '../../Utils/LayoutSpacing';
import Colors from '../../Utils/Colors';
import { Typography, styled } from '@mui/material';

const StyledDrawerListItem = styled(ListItem)(({ isActive }) => ({
  borderRadius: '10px',
  backgroundColor: isActive ? Colors.primary : 'transparent',
  color: isActive ? 'white' : Colors.text,
  svg: { path: { fill: isActive ? 'white' : Colors.text } },
  '& .MuiListItemButton-root': {
    borderRadius: '10px',
  },
}));

const LayoutSidebar = ({ open, handleDrawerToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = useSelector(
    (state) => state.auth.currentUser?.data?.organizationData?.role
  );
  const isAdmin = ['admin', 'Admin'].includes(userRole);

  // Determine which sidebar items to display based on the user's role
  const sidebarItems = isAdmin ? ADMIN_SIDEBAR : EMPLOYEE_SIDEBAR;

  const handleClickItem = (path) => {
    if (open) handleDrawerToggle?.();
    navigate(path);
  };

  const drawerContent = (
    <Box
      sx={{
        // border: `1px solid ${Colors.border}`,
        borderRight: `1px solid ${Colors.border}`,
        borderRadius: LayoutSpacing.headerBorderRadius,
        minHeight: '100%',
        height: 'max-content',
        overflowY: 'auto',
        backgroundColor: 'rgba(255, 255, 255, 1)',
      }}
    >
      <Toolbar sx={{ justifyContent: 'center', pt: '12px' }}>
        <Box
          component="img"
          alt="Logo"
          src={daftarLogo}
          onClick={() => handleClickItem('/')}
          sx={{ height: '35px', cursor: 'pointer' }}
        />
      </Toolbar>

      <List
        sx={{
          marginTop: '38px',
          padding: '12px',
          '& .MuiListItemIcon-root': {
            minWidth: 'unset',
            marginRight: '14px',
            svg: {
              width: '17px',
              height: '17px',
            },
          },
        }}
      >
        {Object.values(sidebarItems).map((item, index) => {
          const isActive =
            item.url === location.pathname ||
            location.pathname?.includes(item.url);

          return (
            <StyledDrawerListItem
              isActive={isActive}
              key={index}
              disablePadding
              onClick={() => handleClickItem(item.url)}
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <Typography
                  sx={{
                    py: '7px',
                    color: 'inherit',
                    fontSize: '16px',
                    fontWeight: 400,
                  }}
                >
                  {item.label}
                </Typography>
              </ListItemButton>
            </StyledDrawerListItem>
          );
        })}
      </List>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
      }}
      aria-label="mailbox folders"
    >
      {/* Temporary drawer for mobile view */}
      <Drawer
        open={open}
        variant="temporary"
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          disableScrollLock: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawerContent}
      </Drawer>

      {/* Permanent drawer for wider screens */}
      <Drawer
        open
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};

export default LayoutSidebar;
