const LayoutSpacing = {
  gap: '12px',
  gapLarge: '24px',
  padding: '12px',
  headerPadding: '0px', // padding around header and sidebar
  headerBorderRadius: '0px', // border radius of header and sidebar
  // headerPadding: '8px', // padding around header and sidebar
  // headerBorderRadius: '12px', // border radius of header and sidebar
  pagePadding: '60px',
  pagePaddingSm: '24px'
};

export default LayoutSpacing;

export const borderRadius = {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 20,
  xl: 24,
};
