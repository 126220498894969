import { createTheme } from '@mui/material';
import Colors from './Utils/Colors';
import LayoutSpacing, { borderRadius } from './Utils/LayoutSpacing';

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: Colors.text,
    },
    secondary: {
      main: '#D3D3D3',
      contrastText: Colors.textMid,
    },
    grey: {
      custom: '#737373',
    },
    text: {
      primary: Colors.text,
    },
    success: {
      main: '#19B616',
      contrastText: '#ffffff', // Define the contrast text color for success
    },
    error: {
      main: '#db261c',
      contrastText: '#ffffff', // Define the contrast text color for error
    },
  },
  typography: {
    fontFamily: ['Inter', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },

  // spacing: 1, // Will handle it at the end, because it can break the UI

  components: {
    // Table Styles
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          boxShadow: 'unset',
          border: `1px solid ${Colors.border}`,
          overflowX: 'auto',
          maxWidth: 'calc(100vw - 48px)',

          '&::-webkit-scrollbar': { height: '8px' },
          '&::-webkit-scrollbar-track': {
            borderRadius: `0 0 10px 10px`,
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: `0 0 10px 10px`,
          },

          thead: {
            background: '#f4f4f4',

            th: {
              fontWeight: 500,
              padding: '14px 16px',
              color: Colors.textMid,
              fontSize: '14px',
              lineHeight: '18px',
            },
          },

          tbody: {
            borderBottom: `1px solid ${Colors.border}`,
            tr: {
              '&.isClickable': { cursor: 'pointer' },
              'td, th': {
                padding: '12px 16px',
                color: Colors.text,
                fontSize: '14px',
                borderBottomColor: Colors.border,
                backgroundColor: 'white',
              },

              '&:nth-last-child(1)': {
                'td, th': {
                  borderBottom: '0px',
                },
              },

              '&:hover': {
                '& td, & th': {
                  backgroundColor: Colors.border,
                  transition: 'all ease-out 0.2s',
                },
                transition: 'all ease-out 0.2s',
                // cursor: "pointer",
                // boxShadow:
                //   "rgba(17, 17, 26, 0.08) 0px 4px 16px, rgba(17, 17, 26, 0.08) 0px 8px 32px",
                // boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
                textAlign: 'left',
              },
            },
          },

          '.MuiPagination-ul': {
            padding: '12px',
            minWidth: 'max-content',
          },
        },
      },
    },

    // Button styles
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '12px 20px',
          minWidth: 120,
          fontSize: '16px',
          lineHeight: '20px',
          borderRadius: `${borderRadius.sm}px`,
          boxShadow: 'none',
          textTransform: 'inherit',
          backgroundColor: Colors.primary,
          color: 'white',

          '&:hover, &:focus, &:active': {
            boxShadow: 'none',
            backgroundColor: Colors.primary + 'D2', // D2 added at the end of hex color to reduce the opacity of color
            color: 'white',
          },

          '& .MuiButton-startIcon': {
            marginBottom: 0,
          },

          // styles for primary color of button
          '&.MuiButton-containedPrimary': {
            backgroundColor: Colors.primary,
            color: 'white',
            border: `1px solid ${Colors.primary}`,
            '&:hover, &:focus, &:active': {
              backgroundColor: Colors.primary + 'D2',
            },
          },

          '&.MuiButton-text': {
            p: '0px !important',
            minWidth: 'unset',
            color: Colors.primary,
            backgroundColor: 'white',
          },

          '&.MuiButton-outlinedPrimary': {
            borderColor: Colors.primary,
            color: Colors.primary,
            backgroundColor: 'transparent',

            '&:hover, &:focus, &:active': {
              backgroundColor: Colors.primary + '10',
            },
          },

          // styles for secondary color of button
          '&.MuiButton-containedSecondary': {
            backgroundColor: Colors.text,
            color: 'white',

            '&:hover, &:focus, &:active': {
              backgroundColor: Colors.text + 'D2', // D2 added at the end of hex color to reduce the opacity of color
            },
          },

          '&:disabled': {
            backgroundColor: 'lightgray',
            color: 'grey',
            borderColor: 'grey',
          },
        },
      },
    },

    MuiTouchRipple: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          borderRadius: 'inherit',
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root': {
            top: '-4px !important',
            left: '1px !important',
          },
          '&.MuiInputLabel-shrink': {
            top: '0px !important',
            left: '0px !important',
          },
        },
      },
    },

    // Overall Input Styles
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& textarea, input': {
            padding: 0,
            paddingBlock: '12px',
            color: Colors.text,
            fontSize: '16px',
            lineHeight: '20px',
            textAlign: 'left',
            '&:-internal-autofill-selected': {
              boxShadow: '0 0 0 50px white inset',
            },
          },

          fieldset: {
            borderColor: Colors.border,
            // borderColor: variables.color.gray,
          },

          '&.MuiInputBase-root': {
            backgroundColor: 'white',
          },
          '&.MuiInput-underline input': {
            paddingBlock: 6,
            // paddingBlock: variables.inputPaddingVertical ,
          },
          '&.MuiInput-underline:after': {
            borderBottomColor: Colors.border,
          },
          '&.MuiOutlinedInput-root': {
            padding: `${0}px 16px`,
            borderRadius: `${borderRadius.sm}px`,

            '&.MuiAutocomplete-inputRoot': {
              padding: `12px 16px`,
              '& .MuiAutocomplete-input': {
                padding: 0,
                '&:-internal-autofill-selected': {
                  boxShadow: '0 0 0 50px white inset',
                },
              },
            },

            '& .MuiSelect-outlined': {
              padding: 0,
              paddingBlock: '12px',
              paddingRight: 32,
              textAlign: 'left',
            },

            '&.Mui-focused fieldset': {
              borderColor: Colors.textMid,
              borderWidth: 1,
            },
          },

          '& input::placeholder': {
            color: Colors.textMid,
            opacity: 0.7,
            textAlign: 'left',
          },
          '& textarea::placeholder': {
            color: Colors.textMid,
            opacity: 0.7,
            textAlign: 'left',
          },
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          padding: '16px',
          borderRadius: `${borderRadius.xl}px`,
          // boxShadow: 'rgba(99, 99, 99, 0.1) 0px 1px 8px 0px',
          transition: 'all ease 0.25s',
          border: `1px solid ${Colors.border}`,
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'rgba(17, 12, 46, 0.1) 0px 28px 60px 0px',
          },

          '&.no-hover': {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          // WebkitBackdropFilter: 'blur(2px)',
          // backdropFilter: 'blur(2px)',
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiDrawer-paper': {
            border: 'unset',
            backgroundColor: 'transparent',
            padding: LayoutSpacing.headerPadding,
            paddingRight: 0,
            boxShadow: 'unset',
            boxSizing: 'border-box',
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          borderRadius: borderRadius.xs,
          color: Colors.text,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          textAlign: 'center',
        },
        arrow: {
          color: 'white',
          textShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            boxShadow: 'rgba(17, 12, 46, 0.1) 0px 28px 60px 0px',
          },
        },
      },
    },
  },
});

export default theme;
