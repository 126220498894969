import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tooltip } from '@mui/material';
import ProjectCard from './ProjectCard';
import AddUpdateProject from './AddUpdateProject';
import { getAllProjects } from '../../Redux/slices/projectSlice';
import { getSubscriptionData } from '../../Redux/slices/subscriptionSlice';
import PlaceholderForEmptyState from '../../Components/MiscComponents/PlaceholderEmptyTable';
import ContentWrapper from '../../Components/MiscComponents/ContentWrapper';
import { toast } from 'react-toastify';
import { MODIFY_INACTIVE_PROJECT_ERROR, SUBSCRIPTION_PROJECT_LIMIT_REACHED } from '../../Utils/Constants';

function ProjectList() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [isAddUpdateModalOpen, setIsAddUpdateModalOpen] = useState(false);
  const loading = useSelector((state) => state.projects.loading);
  const subscriptionData = useSelector((state) => state.subscription.data);
  const projectsResponse = useSelector((state) => state.projects.allProjects);
  const userRole = useSelector(
    (state) => state.auth.currentUser?.data?.organizationData?.role
  );
  const isAdminLoggedIn = userRole && userRole.toLowerCase() === 'admin';
  const maxProjects = subscriptionData?.maxProjects || 0;

  const activeProjectCount = projectsResponse.filter(
    (project) => project.status === 'Active'
  ).length;
  const isAtOrOverLimit = activeProjectCount >= maxProjects;

  useEffect(() => {
    dispatch(getSubscriptionData());
    dispatch(getAllProjects());
  }, [dispatch]);

  useEffect(() => {
    setFilteredItems(
      search.length === 0
        ? projectsResponse
        : projectsResponse.filter((project) =>
            project.name.toLowerCase().includes(search.toLowerCase())
          )
    );
  }, [projectsResponse, search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleOpenAddUpdateModal = () => {
    if (isAtOrOverLimit) {
      toast.error(
        SUBSCRIPTION_PROJECT_LIMIT_REACHED
      );
      return;
    }
    setIsAddUpdateModalOpen(true);
  };

  const handleCloseAddUpdateModal = () => {
    setIsAddUpdateModalOpen(false);
  };

  const handleClearFilters = () => {
    setSearch('');
  };

  return (
    <ContentWrapper
      heading="Projects"
      isLoading={loading}
      addButtonText={isAdminLoggedIn ? 'Add Project' : ''}
      addButtonOnClick={isAdminLoggedIn ? handleOpenAddUpdateModal : undefined}
      filters={[
        {
          type: 'input',
          value: search,
          onChange: handleSearch,
          placeholder: 'Search projects...',
        },
      ]}
      onClearFilters={handleClearFilters}
    >
      {filteredItems.length > 0 ? (
        <Box
          display="grid"
          gridTemplateColumns={{
            sm: '1fr',
            md: '1fr 1fr',
            lg: '1fr 1fr 1fr',
          }}
          gap="24px"
          mt="44px"
        >
          {filteredItems.map((project, idx) => (
            <Tooltip
              title={
                isAtOrOverLimit && project.status !== 'Active'
                  ? MODIFY_INACTIVE_PROJECT_ERROR
                  : ''
              }
              key={project.id}
            >
              <Box
                className="slide-up"
                sx={{
                  animationDelay: `${idx / 20}s`,
                  opacity:
                    isAtOrOverLimit && project.status !== 'Active' ? 0.5 : 1,
                  transition: 'opacity 0.3s ease-in-out',
                  pointerEvents:
                    isAtOrOverLimit && project.status !== 'Active'
                      ? 'none'
                      : 'auto',
                }}
              >
                <ProjectCard
                  project={project}
                  onClick={() => {
                    if (isAtOrOverLimit && project.status !== 'Active') {
                      toast.error(
                        MODIFY_INACTIVE_PROJECT_ERROR
                      );
                      return;
                    }
                    // ProjectCard handles its own click event
                  }}
                />
              </Box>
            </Tooltip>
          ))}
        </Box>
      ) : (
        <PlaceholderForEmptyState message={'No projects found'} />
      )}
      {isAddUpdateModalOpen && (
        <AddUpdateProject onClose={handleCloseAddUpdateModal} />
      )}
    </ContentWrapper>
  );
}

export default ProjectList;
