import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Button,
  Box,
} from '@mui/material';
import React from 'react';
import {
  BILLING_MESSAGES,
  CURRENCY_ENUMS,
  CUSTOM_BILLING_FREQ_ENUM,
  PROJECT_BILLING_FREQUENCIES,
} from '../../Utils/Constants';
import moment from 'moment';

function StepOne({ onboardingData, handleChange, validationErrors }) {
  return (
    <>
      <Typography variant="h5" fontWeight={500} mb={2}>
        What you're working on
      </Typography>

      <Grid item xs={12}>
        <Typography fontWeight={500} mb={2}>
          Hi! To get started, name one project or client engagement you're
          working on
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Project Name"
            variant="outlined"
            id="project_name"
            value={onboardingData?.project_name}
            onChange={(e) => handleChange('project_name', e.target.value)}
            error={!!validationErrors.project_name}
            helperText={validationErrors.project_name}
          />
        </Grid>

        <Grid item xs={12} mt={1}>
          <Typography fontWeight={500} marginBottom="5px">
            Also tell us how you're working on this project or client engagement
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            component="fieldset"
            error={
              !!validationErrors.billing_frequency &&
              onboardingData.billing_frequency === ''
            }
          >
            <RadioGroup
              aria-label="billing frequency"
              name="billing_frequency"
              value={onboardingData?.billing_frequency}
              onChange={(e) =>
                handleChange('billing_frequency', e.target.value)
              }
            >
              {Object.keys(PROJECT_BILLING_FREQUENCIES).map((key) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      xs: 'column',
                      md: 'column',
                      lg: 'row',
                    },
                  }}
                >
                  <FormControlLabel
                    key={key}
                    value={PROJECT_BILLING_FREQUENCIES[key]}
                    control={<Radio />}
                    label={
                      BILLING_MESSAGES[key] || PROJECT_BILLING_FREQUENCIES[key]
                    }
                    sx={{ width: '100%' }}
                  />
                  {onboardingData?.billing_frequency ===
                    PROJECT_BILLING_FREQUENCIES.custom &&
                    key === 'custom' && (
                      <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <p style={{ width: '100%' }}>starting on</p>
                        <TextField
                          name="custom_billing_start"
                          variant="outlined"
                          fullWidth
                          sx={{ mx: '10px' }}
                          type="date"
                          value={
                            moment(onboardingData?.custom_billing_start)
                              .startOf('day')
                              .format('YYYY-MM-DD') || ''
                          }
                          onChange={(e) =>
                            handleChange('custom_billing_start', e.target.value)
                          }
                        />
                        <p style={{ width: '100%' }}>and then</p>
                        <FormControl fullWidth>
                          <Select
                            name="custom_billing_frequency"
                            value={
                              onboardingData?.custom_billing_frequency || ''
                            }
                            sx={{ mr: '10px' }}
                            onChange={(e) =>
                              handleChange(
                                'custom_billing_frequency',
                                e.target.value
                              )
                            }
                            displayEmpty
                          >
                            {CUSTOM_BILLING_FREQ_ENUM?.map((freq, index) => (
                              <MenuItem key={index} value={freq}>
                                {freq}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <p>thereafter</p>
                      </Grid>
                    )}
                </Box>
              ))}
            </RadioGroup>
            {validationErrors.billing_frequency &&
              onboardingData.billing_frequency === '' && (
                <Typography variant="caption" color="error">
                  {validationErrors.billing_frequency}
                </Typography>
              )}
          </FormControl>
        </Grid>
        {onboardingData?.billing_frequency !==
          PROJECT_BILLING_FREQUENCIES.none && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth error={!!validationErrors.billing_currency}>
              <InputLabel>Billing Currency</InputLabel>
              <Select
                name="billing_currency"
                label="Billing Currency"
                value={onboardingData?.billing_currency}
                style={{ marginBottom: '15px' }}
                onChange={(e) =>
                  handleChange('billing_currency', e.target.value)
                }
                displayEmpty
                fullWidth
              >
                {CURRENCY_ENUMS?.map((currency, index) => (
                  <MenuItem key={index} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
              {validationErrors.billing_currency && (
                <Typography variant="caption" color="error">
                  {validationErrors.billing_currency}
                </Typography>
              )}
            </FormControl>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default StepOne;
