import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../Redux/slices/authSlice';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Button, TextField, Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UnAuthPageLayout from '../../Components/PageLayout/UnAuthPageLayout';

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const error = useSelector((state) => state.auth.error);
  // const message = useSelector((state) => state.auth.message);
  const done = useSelector((state) => state.auth.done);

  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const schema = Yup.object().shape({
    newPassword: Yup.string()
      .required('Required')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]+$/,
        'Must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Required'),
  });

  const handleResetPassword = (values, { setSubmitting }) => {
    const { newPassword } = values;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tokenFromUrl = urlParams.get('token');

    if (tokenFromUrl) {
      dispatch(
        resetPassword({
          token: tokenFromUrl,
          password: newPassword,
        })
      ).then(() => {
        setSubmitting(false);
        if (done) {
          navigate('/login');
        }
      });
    } else {
      toast.error('Invalid or expired token.');
      setSubmitting(false);
    }
  };

  return (
    <UnAuthPageLayout>
      <Typography variant="h5" textAlign="left" fontWeight={500} mb="8px">
        Reset Your Password
      </Typography>
      <Typography textAlign="left" variant="body2" mb="28px">
        Enter new password
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleResetPassword}
      >
        {({ setFieldValue, errors, touched }) => (
          <Form>
            <Box sx={{ mt: 1 }}>
              <Field
                as={TextField}
                margin="normal"
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                error={touched.newPassword && Boolean(errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
                style={{ margin: 0, height: '70px', marginBottom: '10px' }}
              />
              <Field
                as={TextField}
                margin="normal"
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
                style={{ margin: 0, height: '70px' }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: '12px', mb: '18px' }}
              >
                Change Password
              </Button>

              <Typography
                variant="body2"
                align="center"
                fontWeight={300}
                component="span"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(-1)}
                color="primary.contrastText"
              >
                <ArrowBackIcon
                  fontSize="inherit"
                  sx={{ mb: '-2px', mr: '2px' }}
                />{' '}
                Go Back
              </Typography>
            </Box>
          </Form>
        )}
      </Formik>
    </UnAuthPageLayout>
  );
}

export default ResetPassword;
