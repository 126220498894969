import { Link } from 'react-router-dom';
import './LeaveManagement.css';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import {
  EMPLOYEE_LEAVE_RECORD,
  EMPLOYEE_LEAVE_REQUESTS,
  EMPLOYEE_VIEW_LEAVES,
  EMPLOYEE_SUBMIT_LEAVE,
} from '../../Routes/route_constants';
import { useSelector } from 'react-redux';


const LeaveDashboard = () => {
  // const user = JSON.parse(localStorage.getItem("user"));
  // const userRole = JSON.parse(localStorage.getItem("userRole"));
  const userRole = useSelector(
    (state) => state.auth.currentUser?.data?.organizationData?.role
  );
  const user = useSelector((state) => state.auth.currentUser?.data);

  return (
    <div className="main-body">
      {userRole === 'admin' || userRole === 'Admin' ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Link className="link-category" to={EMPLOYEE_LEAVE_REQUESTS}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Pending Leave Requests</Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} md={6}>
            <Link className="link-category" to={EMPLOYEE_VIEW_LEAVES}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Leave Records</Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Link className="link-category" to={EMPLOYEE_SUBMIT_LEAVE}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Request Leave</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            <Grid item xs={12} md={6}>
              <Link
                className="link-category"
                to={`${EMPLOYEE_LEAVE_RECORD}/${user?._id}`}
              >
                <Card>
                  <CardContent>
                    <Typography variant="h6">Leave Records</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default LeaveDashboard;
