import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAttendance } from '../../Redux/slices/attendanceSlice';
import CustomTable from '../../Components/MiscComponents/CustomTable';
import moment from 'moment';
import { MONTHS, YEARS } from '../../Utils/Constants';
import { format, parseISO } from 'date-fns';
import ContentWrapper from '../../Components/MiscComponents/ContentWrapper';
import { formatDate } from '../../Utils/functions';

const EmployeeAttendance = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.currentUser?.data);
  const metadata = useSelector((state) => state.attendance.metadata);
  const [currentPage, setCurrentPage] = useState(1);
  const attendanceData = useSelector(
    (state) => state.attendance.userAttendance
  );

  // Keep the selectedMonth and selectedYear states to allow month and year filtering
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const defaultLimit = 10; // Define a default limit if not using initialState from Redux


  useEffect(() => {
    if (user) {
      const monthIndex = MONTHS.indexOf(selectedMonth) + 1;
      const start = selectedMonth && selectedYear ? formatDate(
        moment(`${selectedYear}-${monthIndex}-01`).startOf('month')
      ) : undefined;
      const end = start ? formatDate(moment(start).endOf('month')) : undefined;

      const attendanceData = {
        userId: user?.id,
        startDate: start,
        endDate: end,
        page: currentPage,
        limit: defaultLimit,
      }

      dispatch(
        getUserAttendance(attendanceData)
      );
    }
  }, [dispatch, user, currentPage, selectedMonth, selectedYear]);
  

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    if (!selectedYear) {
      setSelectedYear(moment().format('YYYY')); // Default to current year if year not selected
    }
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    if (selectedMonth && year) {
      // Recalculate and fetch attendance data for the selected month and year
      const monthIndex = MONTHS.indexOf(selectedMonth) + 1;
      const start = formatDate(
        moment(`${year}-${monthIndex}-01`).startOf('month')
      );
      const end = formatDate(moment(start).endOf('month'));
      dispatch(getUserAttendance({ userId: user?.id }));
    }
  };

  useEffect(() => {
    // Trigger data fetch when both month and year are selected
    if (selectedMonth && selectedYear && user) {
      const monthIndex = MONTHS.indexOf(selectedMonth) + 1;
      const start = formatDate(
        moment(`${selectedYear}-${monthIndex}-01`).startOf('month')
      );
      const end = formatDate(moment(start).endOf('month'));
      dispatch(
        getUserAttendance({ userId: user?.id, startDate: start, endDate: end })
      );
    }
  }, [selectedMonth, selectedYear, dispatch, user]);

  const tableHeaders = [
    {
      label: 'Date',
      key: 'date',
      format: (value) => {return value}, // Format for Date, e.g., "Feb 2nd, 2024"
    },
    {
      label: 'Check-in Time',
      key: 'check_in_time',
      format: (value) => format(parseISO(value), 'p'), // Format for time, e.g., "9:15 AM"
    },
    {
      label: 'Check-out Time',
      key: 'check_out_time',
      format: (value) => (value ? format(parseISO(value), 'p') : '---'), // Check if value exists, else show '---'
    },
  ];

  const handleClearFilters = () => {
    setSelectedMonth('');
    setSelectedYear('');
  };

  return (
    <ContentWrapper
      heading={`Attendance Record`}
      onClearFilters={handleClearFilters}
      filters={[
        {
          label: 'Month',
          type: 'dropdown',
          value: selectedMonth,
          onChange: (e) => handleMonthChange(e.target.value),
          options: [
            ...(MONTHS?.map((month) => ({ value: month, text: month })) || []),
          ],
        },
        {
          label: 'Year',
          type: 'dropdown',
          value: selectedYear,
          onChange: (e) => handleYearChange(e.target.value),
          options: [
            ...(YEARS?.map((year) => ({ value: year, text: year })) || []),
          ],
        },
      ]}
    >
      <CustomTable
        headers={tableHeaders}
        data={attendanceData}
        totalPages={metadata?.totalPages || 0}
        currentPage={currentPage}
        handlePageChange={(event, page) => setCurrentPage(page)}
      />
    </ContentWrapper>
  );
};

export default EmployeeAttendance;
