import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getInvoiceByID,
  updateInvoice,
  resetInvoiceState,
} from '../../Redux/slices/invoiceSlice';
import { DeleteOutlined } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Colors from '../../Utils/Colors';
import {
  Box,
  Typography,
  TextField,
  Grid,
  IconButton,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { INVOICE_STATUS } from '../../Utils/Constants';

import { toast } from 'react-toastify';
import DynamicBackButton from '../../Components/BackButton/DynamicBackButton';
import CustomButton from '../../Components/MiscComponents/CustomButton';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import Loader from '../../Components/MiscComponents/Loader';
import { DatePicker } from '@mui/x-date-pickers';
import { CloseOutlined, EditOutlined } from '@mui/icons-material';
import { getAllProjects } from '../../Redux/slices/projectSlice';
import { logger } from '../../Services/loggerService';

const InvoiceDetails = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const invoiceDetailsResponse = useSelector(
    (state) => state.invoices.invoiceDetail
  );
  const loadingInvoiceDetails = useSelector((state) => state.invoices.loading);
  const errorUpdateDetails = useSelector((state) => state.invoices.error);
  const message = useSelector((state) => state.invoices.message);
  const done = useSelector((state) => state.invoices.done);

  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [project, setProject] = useState();
  const [editMode, setEditMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [validation, setValidation] = useState(null);

  useEffect(() => {
    if (errorUpdateDetails || done) {
      dispatch(resetInvoiceState());
      setEditMode(false);
    }
  }, [errorUpdateDetails, done]);

  useEffect(() => {
    if (
      !invoiceDetailsResponse ||
      Object.keys(invoiceDetailsResponse).length === 0
    ) {
      dispatch(getInvoiceByID(_id));
    }
  }, []);

  useEffect(() => {
    if (invoiceDetailsResponse) {
      console.log({ invoiceDetailsResponse });
      setProject(invoiceDetailsResponse?.project);
      setInvoiceDetails({
        ...invoiceDetailsResponse,
        project: invoiceDetailsResponse?.project?.id,
      });
    }
  }, [invoiceDetailsResponse]);

  useEffect(() => {
    if (validation) {
      const valid = Object.values(validation).map((val) => {
        if (val?.length) {
          return true;
        } else return false;
      });

      setDisableBtn(valid.includes(true));
    }
  }, [validation]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAddInvoice = () => {
    setInvoiceDetails((prev) => ({
      ...prev,
      invoice_items: [
        ...prev?.invoice_items,
        { invoice_item: '', quantity: null, rate: null },
      ],
    }));
  };

  const handleRemoveInvoice = (index) => {
    const newInvoiceItems = [...invoiceDetails?.invoice_items];
    newInvoiceItems.splice(index, 1);
    setInvoiceDetails((prev) => ({
      ...prev,
      invoice_items: newInvoiceItems,
    }));
  };

  const handleInputChange = (index, e) => {
    let newInvoiceItems = [...invoiceDetails?.invoice_items];
    let updatedItem = { ...newInvoiceItems[index] };
    updatedItem[e.target.name] = e.target.value;
    newInvoiceItems[index] = updatedItem;
    setInvoiceDetails((prev) => ({
      ...prev,
      invoice_items: newInvoiceItems,
    }));
  };

  const handleUpdateInvoice = async () => {
    await dispatch(updateInvoice({ id: _id, ...invoiceDetails }))
      .unwrap()
      .then(() => {
        setOpenDialog(false);

        // Show toast notification
        toast.success('Invoice updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });

        // Reset edit mode to false
        setEditMode(false);
      })
      .catch((error) => {
        setOpenDialog(false);

        toast.error(message || 'Failed to update invoice.', {
          position: toast.POSITION.TOP_RIGHT,
        });
        logger.error(error);
      });
  };

  return (
    <>
      <DynamicBackButton />
      {loadingInvoiceDetails ? (
        <Loader height="60vh" />
      ) : (
        <>
          <Card
            className="no-hover"
            sx={{
              maxWidth: '724px',
              mx: 'auto',
              mt: { xs: '20px', sm: '0px' },
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: '42px',
                }}
              >
                <Typography variant="h4" fontWeight={500} textAlign="left">
                  Invoice Information
                </Typography>
                <IconButton onClick={toggleEditMode} color="primary">
                  {editMode ? <CloseOutlined /> : <EditOutlined />}
                </IconButton>
              </Box>
              <Typography
                component="h2"
                textAlign="left"
                sx={{ mb: 1, fontWeight: 500 }}
              >
                Project: {project?.name}
              </Typography>
              <Typography
                component="h2"
                textAlign="left"
                sx={{ mb: 4, fontWeight: 500 }}
              >
                Total: {invoiceDetails?.invoice_total}
              </Typography>
              <Grid container spacing={'18px'}>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Start Date"
                    disabled={!editMode}
                    value={
                      invoiceDetails?.start_date
                        ? new Date(invoiceDetails?.start_date)
                        : null
                    }
                    name="start_date"
                    onChange={(date) => {
                      setInvoiceDetails({
                        ...invoiceDetails,
                        start_date: date,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="End Date"
                    disabled={!editMode}
                    value={
                      invoiceDetails?.end_date
                        ? new Date(invoiceDetails?.end_date)
                        : null
                    }
                    name="end_date"
                    onChange={(date) => {
                      setInvoiceDetails({
                        ...invoiceDetails,
                        end_date: date,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Due Date"
                    disabled={!editMode}
                    value={
                      invoiceDetails?.due_date
                        ? new Date(invoiceDetails?.due_date)
                        : null
                    }
                    name="due_date"
                    onChange={(date) => {
                      setInvoiceDetails({
                        ...invoiceDetails,
                        due_date: date,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {invoiceDetails?.invoice_status && (
                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <InputLabel>Status</InputLabel>
                      <Select
                        name="invoice_status"
                        disabled={!editMode}
                        value={invoiceDetails.invoice_status}
                        label={'Status'}
                        onChange={(e) => {
                          setInvoiceDetails({
                            ...invoiceDetails,
                            invoice_status: e.target.value,
                          });
                        }}
                      >
                        {INVOICE_STATUS?.map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    textAlign="left"
                    fontWeight={500}
                    variant="body1"
                    mb="10px"
                  >
                    Invoice Items
                  </Typography>
                </Grid>
                {invoiceDetails?.invoice_items?.map((invoice, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Name"
                        disabled={!editMode}
                        name="invoice_item"
                        value={invoice?.invoice_item}
                        onChange={(e) => handleInputChange(index, e)}
                        sx={{ mb: 1 }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="number"
                        disabled={!editMode}
                        label="Quantity"
                        name="quantity"
                        value={invoice?.quantity}
                        onChange={(e) => handleInputChange(index, e)}
                        sx={{ mb: 1 }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <TextField
                        fullWidth
                        type="number"
                        disabled={!editMode}
                        label="Rate"
                        name="rate"
                        value={invoice?.rate}
                        onChange={(e) => handleInputChange(index, e)}
                        sx={{ mb: 1 }}
                      />
                    </Grid>
                    {editMode && (
                      <Grid
                        item
                        xs={6}
                        sm={1}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <IconButton
                          onClick={() => handleRemoveInvoice(index)}
                          sx={{ color: 'error.main' }}
                        >
                          <DeleteOutlined />
                        </IconButton>
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
                {editMode && (
                  <Grid item xs={12}>
                    <Box
                      onClick={handleAddInvoice}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        color: Colors.link,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <AddBoxIcon />
                      <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        Add another item
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
              {editMode && (
                <CustomButton
                  disabled={disableBtn}
                  onClick={handleOpenDialog}
                  fullWidth
                  sx={{ mt: '32px' }}
                >
                  Update
                </CustomButton>
              )}
            </CardContent>
          </Card>
          <CustomDialog
            open={openDialog}
            onClose={handleCloseDialog}
            heading="Confirm Update"
            subHeading="Are you sure you want to update the changes?"
          >
            <Box display="flex" justifyContent="flex-end" mt={'42px'} gap="8px">
              <CustomButton onClick={handleCloseDialog} variant="outlined">
                Cancel
              </CustomButton>
              <CustomButton onClick={handleUpdateInvoice}>Update</CustomButton>
            </Box>
          </CustomDialog>
        </>
      )}
    </>
  );
};

export default InvoiceDetails;
