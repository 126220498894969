import React from 'react';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

const CustomButton = ({
  children,
  label,
  onClick,
  variant = 'contained',
  color = 'primary',
  disabled,
  isSaving, // this boolean is used when we want to display loader on button and disable the button
  startIcon,
  ...rest
}) => {
  return (
    <Button
      variant={variant}
      color={color}
      onClick={onClick}
      disabled={disabled || isSaving}
      startIcon={
        isSaving ? (
          <CircularProgress color="inherit" size={'14px'} />
        ) : (
          startIcon
        )
      }
      {...rest}
    >
      {children || label}
    </Button>
  );
};

export default CustomButton;
