import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDailyAttendance } from '../../Redux/slices/attendanceSlice';
import { useNavigate } from 'react-router-dom';
import { EMPLOYEE_VIEW_ATTENDANCE } from '../../Routes/route_constants';
import CustomTable from '../../Components/MiscComponents/CustomTable';
import ContentWrapper from '../../Components/MiscComponents/ContentWrapper';
import { formatTime } from '../../Utils/functions';

const headers = [
  { label: 'Name', keys: ['first_name', 'last_name'] },
  { label: 'Status', key: 'status' },
  {
    label: 'Check-in Time',
    key: 'attendance.check_in_time',
    format: formatTime,
  },
  {
    label: 'Check-out Time',
    key: 'attendance.check_out_time',
    format: formatTime,
  },
];

const AttendanceDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dailyAttendance = useSelector(
    (state) => state.attendance.dailyAttendance
  );
  const metadata = useSelector((state) => state.attendance.metadata);
  const loading = useSelector((state) => state.users.loading); // TODO: This loading is not working, need to discuss with Shehzore which loading will be working on this page

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  useEffect(() => {
    const filters = search ? { search } : {};
    dispatch(
      getDailyAttendance({
        filters,
        page: currentPage,
        statusFilter: statusFilter,
      })
    );
  }, [dispatch, currentPage, search, statusFilter]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleClearFilters = () => {
    setSearch('');
    setStatusFilter('');
  };

  return (
    <ContentWrapper
      heading={'Attendance Management'}
      isLoading={loading}
      filters={[
        {
          type: 'input',
          placeholder: 'Search...',
          value: search,
          onChange: handleSearch,
        },
        {
          type: 'dropdown',
          value: statusFilter,
          onChange: handleStatusChange,
          options: [
            { value: '', text: 'All Statuses' },
            { value: 'Absent', text: 'Absent' },
            { value: 'Checked-In', text: 'Checked-In' },
            { value: 'Checked-Out', text: 'Checked-Out' },
            { value: 'On Leave', text: 'On Leave' },
          ],
        },
      ]}
      onClearFilters={handleClearFilters}
    >
      <CustomTable
        headers={headers}
        data={dailyAttendance}
        handleRowClick={(user) =>
          navigate(
            `${EMPLOYEE_VIEW_ATTENDANCE}/${user.id}/${user.first_name}/${user.last_name}`
          )
        }
        totalPages={metadata?.totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        emptyStateMessage="No attendance data found"
      />
    </ContentWrapper>
  );
};

export default AttendanceDashboard;
