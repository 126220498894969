import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Colors from '../../Utils/Colors';
import { Box } from '@mui/material';

function DynamicBackButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: { xs: '66px', sm: '84px' },
        left: { xs: '11px', sm: '305px' },
        zIndex: 1000,
      }}
    >
      <IconButton
        onClick={handleClick}
        aria-label="Back"
        sx={{
          borderRadius: '10px',
          transition: 'border-radius 0.3s, background-color 0.3s',
          color: Colors.text,
          backgroundColor: 'transparent',
          border: `none`,
          padding: '10px',

          '&:hover': {
            borderRadius: '10px',
            backgroundColor: Colors.primary,
            color: 'white',
          },
        }}
      >
        <ArrowBackIcon />
      </IconButton>
    </Box>
  );
}

export default DynamicBackButton;
