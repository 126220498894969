import AllRoutes from './Routes/AllRoutes';
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from './Redux/slices/authSlice';
import { ToastContainer } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './App.css';
import './animations.css';
import 'react-toastify/dist/ReactToastify.css';
import OnboardingMain from './Pages/Onboarding/OnboardingMain';
import { getOnboardingStatus } from './Redux/slices/onboardingSlice';
import { checkUserLimits } from './Redux/slices/organizationSlice';
import { fetchStripePlans } from './Redux/slices/stripeSlice';
import Loader from './Components/MiscComponents/Loader';

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(null);
  const onboardingPending = useSelector(
    (state) => state.onboarding.onboardingStatus
  );
  const currentUser = useSelector((state) => state.auth.currentUser);
  const plans = useSelector((state) => state.stripe.stripePlans);
  const userRole = useSelector(
    (state) => state.auth.currentUser?.data?.organizationData?.role
  );
  const isAdmin = ['admin', 'Admin'].includes(userRole);

  useEffect(() => {
    const checkUserStatus = async () => {
      await dispatch(getCurrentUser());
    };
    checkUserStatus();
    dispatch(fetchStripePlans());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      const checkOnboardingStatus = async () => {
        setLoading(true);
        await dispatch(getOnboardingStatus());
        setLoading(false);
      };
      checkOnboardingStatus();
      dispatch(checkUserLimits(currentUser?.data?.active_organization));
    } else {
      setLoading(false);
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (onboardingPending !== null) {
      setShowOnboarding(onboardingPending);
    }
  }, [onboardingPending]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <ToastContainer hideProgressBar />
        {loading ? (
          <Loader />
        ) : showOnboarding && isAdmin ? (
          <OnboardingMain />
        ) : (
          <AllRoutes />
        )}
      </ThemeProvider>
    </LocalizationProvider> 
  );
};

export default App;
