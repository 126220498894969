import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllInvoices } from '../../Redux/slices/invoiceSlice';

import moment from 'moment';
import ContentWrapper from '../../Components/MiscComponents/ContentWrapper';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import CustomTable from '../../Components/MiscComponents/CustomTable';
import { getAllProjects } from '../../Redux/slices/projectSlice';
import { INVOICE_HEADERS } from '../../Utils/Constants';
import { CREATE_NEW_INVOICE, INVOICE_DETAILS } from '../../Routes/route_constants';
import { useNavigate } from 'react-router-dom';

const Invoices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const invoiceResponse = useSelector((state) => state.invoices.allInvoices);
  const metadata = useSelector((state) => state.invoices.metadata);
  const projectsResponse = useSelector((state) => state.projects.allProjects);

  const [selectedProject, setSelectedProject] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!projectsResponse || !projectsResponse?.length) {
      dispatch(getAllProjects());
    }
  }, []);
  useEffect(() => {
    handleInvoiceDispatch();
  }, [startDate, endDate, currentPage, selectedProject]);

  const handleInvoiceDispatch = () => {
    const formattedStartDate = startDate
      ? moment(startDate).startOf('day').format('YYYY-MM-DD')
      : null;

    const formattedEndDate = endDate
      ? moment(endDate).endOf('day').format('YYYY-MM-DD')
      : null;

    dispatch(
      getAllInvoices({
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        projectId: selectedProject,
        page: currentPage,
      })
    );
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    setCurrentPage(1);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    setCurrentPage(1);
  };

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
  };


  const handleRowClick = (invoice) => {
    navigate(`${INVOICE_DETAILS}/${invoice.id}`);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleModalOpen = () => {
    setInvoiceModalOpen(true);
  };

  const handleModalClose = () => {
    setInvoiceModalOpen(false);
  };

  const handleClearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedProject('');
  };

  return (
    <ContentWrapper
      heading={'Invoices'}
      //   isLoading={loading}
      addButtonText="New Invoice"
      addButtonOnClick={() => navigate(CREATE_NEW_INVOICE)}
      hideAddIcon
      onClearFilters={handleClearFilters}
      filters={[
        {
          type: 'date',
          label: 'Start Date',
          placeholder: 'Start Date',
          value: startDate,
          onChange: handleStartDate,
        },
        {
          type: 'date',
          label: 'End Date',
          placeholder: 'End Date',
          value: endDate,
          onChange: handleEndDate,
        },
        {
          type: 'dropdown',
          value: selectedProject,
          onChange: handleProjectChange,
          options: [
            { value: '', text: 'All Projects' },
            ...(projectsResponse?.map((project) => ({
              value: project.id,
              text: project.name,
            })) || []),
          ],
        },
      ]}
    >
      <CustomTable
        headers={INVOICE_HEADERS}
        data={invoiceResponse}
        handleRowClick={handleRowClick}
        totalPages={metadata?.totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        emptyStateMessage="No invoices found"
      />
    </ContentWrapper>
  );
};

export default Invoices;
