import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async Thunks

// Fetch departments by organization ID
export const fetchDepartments = createAsyncThunk(
  'departments/fetchDepartments',
  async (organizationId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/v1/department/organization/${organizationId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single department by ID
export const fetchDepartment = createAsyncThunk(
  'departments/fetchDepartment',
  async (departmentId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/v1/department/${departmentId}`);
      return response.data.department;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create a new department
export const createDepartment = createAsyncThunk(
  'departments/createDepartment',
  async (departmentData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/v1/department', departmentData);
      return response.data.department;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update a department
export const updateDepartment = createAsyncThunk(
  'departments/updateDepartment',
  async ({ departmentId, departmentData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/v1/department/${departmentId}`,
        departmentData
      );
      return response.data.department;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete a department
export const deleteDepartment = createAsyncThunk(
  'departments/deleteDepartment',
  async (departmentId, { rejectWithValue }) => {
    try {
      await axios.delete(`/v1/department/${departmentId}`);
      return departmentId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Initial state
const initialState = {
  departments: [],
  department: null,
  loading: false,
  error: null,
};

// Slice
const departmentSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Departments
      .addCase(fetchDepartments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.loading = false;
        state.departments = action.payload;
      })
      .addCase(fetchDepartments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch Single Department
      .addCase(fetchDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDepartment.fulfilled, (state, action) => {
        state.loading = false;
        state.department = action.payload;
      })
      .addCase(fetchDepartment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create Department
      .addCase(createDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDepartment.fulfilled, (state, action) => {
        state.loading = false;
        state.departments.push(action.payload);
      })
      .addCase(createDepartment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update Department
      .addCase(updateDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.departments.findIndex(
          (dept) => dept.id === action.payload.id
        );
        if (index !== -1) {
          state.departments[index] = action.payload;
        }
      })
      .addCase(updateDepartment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete Department
      .addCase(deleteDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.loading = false;
        state.departments = state.departments.filter(
          (dept) => dept.id !== action.payload
        );
      })
      .addCase(deleteDepartment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export reducer
export default departmentSlice.reducer;
