import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logger } from '../../Services/loggerService';

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const createInvoice = createAsyncThunk(
  'invoices/createInvoice',
  async (invoiceData, { rejectWithValue }) => {
    try {
      console.log('InvoicePayload', invoiceData);
      const response = await axios.post(`/v1/invoice`, invoiceData, {
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data.data;
    } catch (error) {
      logger.error('Create Invoice', error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDefaultInvoiceItems = createAsyncThunk(
  'invoices/getDefaultInvoices',
  async (invoiceData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/v1/invoice/items`, invoiceData);
      return response.data?.data;
    } catch (error) {
      logger.error('Get Default Invoice Items', error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllInvoices = createAsyncThunk(
  'invoices/getAllInvoices',
  async (
    { start_date, end_date, projectId, page = 1, limit = 10 },
    { rejectWithValue }
  ) => {
    try {
      const params = { start_date, end_date, page, limit };
      if (start_date) params.start_date = start_date;
      if (projectId) params.projectId = projectId;
      if (end_date) params.end_date = end_date;

      const response = await axios.get(`/v1/invoice`, { params });
      return response.data;
    } catch (error) {
      logger.error('Get All Invoices', error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInvoiceByID = createAsyncThunk(
  'invoices/getInvoiceByID',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/v1/invoice/${id}`);
      return response.data;
    } catch (error) {
      logger.error('Get Invoice By ID', error.message);
      return thunkAPI.rejectWithValue(error.toString());
    }
  }
);

export const updateInvoice = createAsyncThunk(
  'invoices/updateInvoice',
  async ({ id, ...data }, thunkAPI) => {
    try {
      const response = await axios.put(`/v1/invoice/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      logger.error('Update Invoice', error.message);
      return thunkAPI.rejectWithValue(error.toString());
    }
  }
);

const initialState = {
  allInvoices: [],
  invoiceDetail: null,
  loading: false,
  error: null,
  message: '',
  metadata: {
    totalResults: 0,
    totalPages: 0,
    currentPage: 0,
    limit: 10, // Default limit
  },
};

const invoiceSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    resetInvoiceState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllInvoices.fulfilled, (state, action) => {
        state.loading = false;
        state.allInvoices = action.payload.data;
        state.metadata = action.payload.metadata;
      })
      .addCase(getAllInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getInvoiceByID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoiceByID.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.invoiceDetail = action.payload?.data;
      })
      .addCase(getInvoiceByID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.data;
        state.invoiceDetail = null;
      })
      .addCase(updateInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
        state.done = true;
        state.invoiceDetail = action.payload.data;
        state.allInvoices = state.allInvoices.map((invoice) => {
          return invoice.id === action.payload?.data?.id
            ? { ...invoice, ...action.payload?.data }
            : invoice;
        });
      })
      .addCase(updateInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allInvoices = [];
        state.message = action.payload?.message
          ? action.payload.message
          : action.payload?.data
          ? action.payload.data
          : null;
      })
      .addCase(createInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.allInvoices.push(action.payload);
        state.message = 'Invoice created successfully.';
      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetInvoiceState } = invoiceSlice.actions;
export default invoiceSlice.reducer;
