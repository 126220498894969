import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjectByID,
  deleteProject,
  updateProject,
} from '../../Redux/slices/projectSlice';
import { getAllUsers } from '../../Redux/slices/userSlice';
import { Box, Typography, Chip, Grid } from '@mui/material';
import moment from 'moment';
import {
  BILLING_MESSAGES,
  PROJECT_BILLING_FREQUENCIES,
  PROJECT_STATUS_ENUMS,
} from '../../Utils/Constants';
import { useCallback } from 'react';
import Loader from '../../Components/MiscComponents/Loader';
import CustomButton from '../../Components/MiscComponents/CustomButton';
import {
  DeleteOutline,
  EditOutlined,
  EmailOutlined,
  PhoneOutlined,
  BusinessOutlined,
  HomeOutlined,
  PersonOutlined,
} from '@mui/icons-material';
import EditProjectDialog from './EditProjectDialog';
import DeleteProjectDialog from './DeleteProjectDialog';
import DynamicBackButton from '../../Components/BackButton/DynamicBackButton';
import Colors from '../../Utils/Colors';
import LayoutSpacing from '../../Utils/LayoutSpacing';
import { logger } from '../../Services/loggerService';

function ProjectDetail() {
  const { id: projectId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector(
    (state) => state.auth.currentUser?.data?.organizationData?.role
  );
  const { projectDetail, loading } = useSelector((state) => state.projects);
  const fetchUsersDebounced = useCallback(
    debounce((search) => fetchUsers(search), 500),
    []
  );

  const [employees, setEmployees] = useState([]);
  const [projectManagerSearch, setProjectManagerSearch] = useState('');
  const usersResponse = useSelector((state) => state.users.allUsers);
  const [memberSearch, setMemberSearch] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedProject, setEditedProject] = useState({
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    project_manager: '',
    members: [],
    status: '',
    client_details: {},
  });

  function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  useEffect(() => {
    fetchUsersDebounced(projectManagerSearch);
  }, [projectManagerSearch, fetchUsersDebounced]);

  useEffect(() => {
    fetchUsersDebounced(memberSearch);
  }, [memberSearch, fetchUsersDebounced]);

  useEffect(() => {
    if (projectId) {
      dispatch(getProjectByID(projectId));
    }
  }, [dispatch, projectId]);

  const fetchUsers = (search) => {
    const filters = search ? { search: search } : {};
    dispatch(getAllUsers({ filters: filters, page: 1 }));
  };

  useEffect(() => {
    if (projectDetail) {
      // Map projectDetail.members to the expected structure for Autocomplete
      const formattedMembers = projectDetail.members.map((member) => ({
        label: `${member?.user?.first_name} ${member?.user?.last_name}`,
        value: member?.user?.id,
      }));

      let updateProject = {
        ...projectDetail,
        // project_manager: {
        //   label: `${projectDetail?.project_manager?.first_name} ${projectDetail?.project_manager?.last_name}`,
        //   value: projectDetail?.project_manager?.id,
        // },
        members: projectDetail?.members,
        status: projectDetail.status,
      };

      if (projectDetail?.start_date)
        updateProject.start_date = moment(projectDetail?.start_date).format(
          'YYYY-MM-DD'
        );
      if (projectDetail?.end_date)
        updateProject.end_date = moment(projectDetail?.end_date).format(
          'YYYY-MM-DD'
        );

      setEditedProject(updateProject);
    }
  }, [projectDetail]);

  useEffect(() => {
    if (usersResponse) {
      const formattedUsers = usersResponse?.map((user) => ({
        label: `${user?.first_name} ${user?.last_name}`,
        value: user?.id,
      }));
      setEmployees(formattedUsers);
    }
  }, [usersResponse]);

  const handleEditChange = (fieldOrEvent, newValue) => {
    let field = fieldOrEvent;
    let value = newValue;

    if (fieldOrEvent.target) {
      field = fieldOrEvent.target.name;
      value = fieldOrEvent.target.value;
    }

    if (typeof fieldOrEvent === 'object' && fieldOrEvent.target) {
      // It's an event from a TextField
      const { name, value } = fieldOrEvent.target;
      setEditedProject((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      // It's from Select or Autocomplete, which requires a field name and new value
      const fieldName = fieldOrEvent; // The first argument is the field name
      setEditedProject((prev) => ({
        ...prev,
        [fieldName]: newValue,
      }));
    }
    if (field === 'start_date' || field === 'end_date') {
      const start = field === 'start_date' ? value : editedProject.start_date;
      const end = field === 'end_date' ? value : editedProject.end_date;
      setDateError(moment(end).isBefore(moment(start)));
    }
  };

  const handleSubmitEdit = () => {
    const formattedMembers = editedProject?.members?.map((member) => ({
      user: member?.user?.id || member?.user,
      role: member.role,
      engagement_type: member.engagement_type,
      billing_rate: member.billing_rate || null,
      hour_cap: member.hour_cap || null,
    }));

    // Prepare the payload with the formatted data
    const updatePayload = {
      ...editedProject,
      members: formattedMembers,
      project_manager: undefined, // avoiding validation, project manager's handled from BE
    };

    // Dispatch the update action
    dispatch(updateProject({ id: projectId, ...updatePayload }))
      .unwrap()
      .then(() => {
        setEditMode(false);
        // Optionally, notify the user of success
        dispatch(getProjectByID(projectId));
      })
      .catch((error) => {
        logger.error('Failed to update project:', error);
        // Optionally, handle the error (e.g., show an error message)
      });
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteProject(projectId))
      .unwrap()
      .then(() => {
        navigate('/projects');
      })
      .catch((error) => {
        logger.error('Failed to delete project:', error);
      });
  };

  const REVERSED_PROJECT_BILLING_FREQUENCIES = Object.fromEntries(
    Object.entries(PROJECT_BILLING_FREQUENCIES).map(([key, value]) => [
      value,
      key,
    ])
  );

  const billingFrequency = projectDetail?.billing_frequency;
  const billingFrequencyKey =
    REVERSED_PROJECT_BILLING_FREQUENCIES[billingFrequency];

  // Get the corresponding billing message
  const billingMessage = BILLING_MESSAGES[billingFrequencyKey];

  return (
    <Box
      textAlign="left"
      position="relative"
      padding={{ xs: '20px', md: '40px' }}
      maxWidth="1200px"
      margin="0 auto"
    >
      {loading ? (
        <Loader height="60vh" />
      ) : projectDetail ? (
        <>
          <DynamicBackButton />
          <Box
            className={`project-status ${
              PROJECT_STATUS_ENUMS.includes(projectDetail?.status)
                ? projectDetail.status.toLowerCase().replace(' ', '')
                : ''
            }`}
            padding="8px 16px"
            borderRadius="4px"
            backgroundColor={Colors.statusColor}
            color={Colors.white}
            fontSize="14px"
            fontWeight="500"
            marginBottom="16px"
            display="inline-block"
          >
            {projectDetail.status}
          </Box>
          <Typography
            variant="h4"
            fontWeight={500}
            fontSize={{ xs: '1.8rem', md: '2.5rem' }}
            lineHeight={{ xs: '2.5rem', md: '3.5rem' }}
            marginBottom="16px"
          >
            {projectDetail.name}
          </Typography>
          <Typography
            variant="h6"
            fontWeight={300}
            marginBottom="20px"
            maxWidth="600px"
          >
            {projectDetail.description || 'No description'}
          </Typography>
          <Grid container spacing={2}>
            {/* Client Information */}
            <Grid item xs={12} md={6}>
              <Typography fontWeight={500} marginBottom="8px">
                Client Information
              </Typography>
              <Typography marginBottom="8px">
                <PersonOutlined fontSize="small" />{' '}
                {projectDetail?.client_details?.first_name}{' '}
                {projectDetail?.client_details?.last_name}
              </Typography>
              <Typography marginBottom="8px">
                <EmailOutlined fontSize="small" />{' '}
                {projectDetail?.client_details?.email}
              </Typography>
              {projectDetail?.client_details?.phone_number && (
                <Typography marginBottom="8px">
                  <PhoneOutlined fontSize="small" />{' '}
                  {projectDetail?.client_details?.phone_number}
                </Typography>
              )}
              <Typography marginBottom="8px">
                <BusinessOutlined fontSize="small" />{' '}
                {projectDetail?.client_details?.company_name}
              </Typography>
              {(projectDetail?.client_details?.address ||
                projectDetail?.client_details?.country) && (
                <Typography marginBottom="8px">
                  <HomeOutlined fontSize="small" />{' '}
                  {projectDetail?.client_details?.address}{' '}
                  {projectDetail?.client_details?.country}{' '}
                  {projectDetail?.client_details?.zip_code}
                </Typography>
              )}
            </Grid>
            {/* Billing Information */}
            <Grid item xs={12} md={6}>
              <Typography fontWeight={500} marginBottom="8px">
                Billing Information
              </Typography>
              <Typography marginBottom="8px">{billingMessage}</Typography>
              <Typography marginBottom="8px">
                {projectDetail?.billing_currency}
              </Typography>
              {projectDetail?.custom_billing_start && (
                <Typography marginBottom="8px">
                  {moment(projectDetail?.custom_billing_start)
                    .startOf('day')
                    .format('YYYY-MM-DD')}
                </Typography>
              )}
              {projectDetail?.custom_billing_frequency && (
                <Typography marginBottom="8px">
                  {projectDetail?.custom_billing_frequency}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Typography fontWeight={500} marginBottom="8px">
            Start Date:
          </Typography>
          <Typography marginBottom="16px">
            {editedProject?.start_date || 'N/A'}
          </Typography>
          <Typography fontWeight={500} marginBottom="8px">
            End Date:
          </Typography>
          <Typography marginBottom="16px">
            {editedProject?.end_date || 'N/A'}
          </Typography>
          <Typography fontWeight={500} marginBottom="8px">
            Project Manager:
          </Typography>
          <Typography marginBottom="16px">
            {projectDetail.project_manager
              ? `${projectDetail.project_manager.first_name} ${projectDetail.project_manager.last_name}`
              : '-'}
          </Typography>
          <Typography fontWeight={500} marginBottom="8px">
            Members:
          </Typography>
          <Box display="flex" alignItems="center" flexWrap="wrap" gap="8px">
            {projectDetail?.members?.map((member, index) => (
              <Chip
                key={index}
                label={`${member?.user?.first_name} ${member?.user?.last_name}`}
              />
            ))}
          </Box>

          {userRole === 'Admin' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'sticky',
                bottom: {
                  xs: LayoutSpacing.pagePaddingSm,
                  sm: LayoutSpacing.pagePadding,
                },
                padding: '12px',
                gap: '12px',
                borderRadius: '16px',
                WebkitBackdropFilter: 'saturate(180%) blur(20px)',
                backdropFilter: 'saturate(180%) blur(20px)',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                border: `1px solid ${Colors.border}`,
                boxShadow: 'rgba(17, 12, 46, 0.1) 0px 28px 60px 0px',
                overflow: 'hidden',
                m: { xs: '32px auto 0 auto', sm: '32px 0 0 auto' },

                animationDelay: '0.05s',

                '& button': {
                  animationDelay: '0.47s',
                  '&:nth-child(2)': {
                    animationDelay: '0.52s',
                  },
                },
              }}
              className="floating-action-buttons"
            >
              <CustomButton
                className="slide-up-bounce"
                variant="outlined"
                onClick={() => setEditMode(true)}
                startIcon={<EditOutlined />}
              >
                Edit
              </CustomButton>
              <CustomButton
                className="slide-up-bounce"
                variant="outlined"
                onClick={() => setOpenDeleteDialog(true)}
                startIcon={<DeleteOutline />}
              >
                Delete
              </CustomButton>
            </Box>
          )}

          <EditProjectDialog
            open={editMode}
            onClose={() => setEditMode(false)}
            heading="Edit Project"
            editedProject={editedProject}
            handleEditChange={handleEditChange}
            setEditedProject={setEditedProject}
            setProjectManagerSearch={setProjectManagerSearch}
            onSave={handleSubmitEdit}
            dateError={dateError}
            projectDetail={projectDetail}
            employees={employees}
            setMemberSearch={setMemberSearch}
          />

          <DeleteProjectDialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            onDelete={handleDeleteConfirm}
          />
        </>
      ) : (
        <Typography variant="body1">Project details not found.</Typography>
      )}
    </Box>
  );
}

export default ProjectDetail;
