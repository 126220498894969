import { DeleteOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Colors from '../../Utils/Colors';

function ChargeMilestones({ onboardingData, setOnboardingData }) {
  const handleAddMilestone = () => {
    setOnboardingData((prev) => ({
      ...prev,
      milestone_billing: [
        ...prev?.milestone_billing,
        {
          name: '',
          amount: '',
          due_date: null,
        },
      ],
    }));
  };

  const handleRemoveMilestone = (index) => {
    const newMilestone = [...onboardingData?.milestone_billing];
    newMilestone.splice(index, 1);
    setOnboardingData((prev) => ({
      ...prev,
      milestone_billing: newMilestone,
    }));
  };

  const handleMilestoneChange = (index, e) => {
    let newMilestone = [...onboardingData?.milestone_billing];
    let updatedMilestone = { ...newMilestone[index] };
    updatedMilestone[e.target.name] = e.target.value;
    newMilestone[index] = updatedMilestone;

    setOnboardingData((prev) => ({
      ...prev,
      milestone_billing: newMilestone,
    }));
  };

  return (
    <>
      <Typography variant="h5" fontWeight={500}>
        How you're charging
      </Typography>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Typography fontWeight={500} marginBottom="5px">
          Do you have any milestones for this project? Adding them will help you
          track progress towards them and understand projected revenues and
          potential optimizations.
        </Typography>
      </Grid>

      <Card className="no-hover" variant="outlined" sx={{ p: 2, my: 4 }}>
        <Grid container spacing={2}>
          {/* Headers */}
          <Grid item xs={5} sm={4}>
            <Typography textAlign="left" variant="subtitle1">
              Milestone
            </Typography>
          </Grid>
          <Grid item xs={5} sm={3}>
            <Typography textAlign="left" variant="subtitle1">
              Amount
            </Typography>
          </Grid>
          <Grid item xs={10} sm={4}>
            <Typography textAlign="left" variant="subtitle1">
              Due Date
            </Typography>
          </Grid>
          <Grid item xs={2} sm={1}>
            {/* Empty spacing for delete icon */}
          </Grid>

          {onboardingData?.milestone_billing?.map((member, index) => (
            <React.Fragment key={index}>
              <Grid item xs={5} sm={4}>
                <TextField
                  fullWidth
                  label="Milestone"
                  name="name"
                  value={member.name}
                  onChange={(e) => handleMilestoneChange(index, e)}
                  sx={{ mb: 1 }}
                />
              </Grid>

              <Grid item xs={5} sm={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Amount"
                  name="amount"
                  value={member.amount}
                  onChange={(e) => handleMilestoneChange(index, e)}
                  sx={{ mb: 1 }}
                />
              </Grid>
              <Grid item xs={10} sm={4}>
                <TextField
                  fullWidth
                  type="date"
                  name="due_date"
                  value={member.due_date}
                  onChange={(e) => handleMilestoneChange(index, e)}
                  sx={{ mb: 1 }}
                />
              </Grid>
              <Grid item xs={2} sm={1} display="flex" justifyContent="flex-end">
                <IconButton
                  onClick={() => handleRemoveMilestone(index)}
                  sx={{ color: 'error.main' }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Box
              onClick={handleAddMilestone}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                color: Colors.link,
                justifyContent: 'flex-end',
              }}
            >
              <AddBoxIcon />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                Add milestone
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default ChargeMilestones;
