import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubscriptionPlans } from '../../Redux/slices/subscriptionplanSlice';
import Loader from '../../Components/MiscComponents/Loader';

const PlanSelection = ({ handleChange, selectedPlan }) => {
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.subscriptionPlans.apiPlans);
  const isLoading = useSelector((state) => state.subscriptionPlans.isLoading);
  const [selected, setSelected] = useState(selectedPlan || '');

  useEffect(() => {
    dispatch(getAllSubscriptionPlans());
  }, [dispatch]);

  const handleSelect = (priceId, planId) => {
    setSelected(priceId);
    handleChange('selectedPlan', priceId);
    handleChange('planId', planId);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <Box sx={{ textAlign: 'left', mb: 4 }}>
        <Typography variant="h5" fontWeight={500} mb={2}>
          Select plan
        </Typography>
        <Typography fontWeight={500} mb={6}>
          Choose the plan that best fits your needs
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" gap={3} flexWrap="wrap">
        {plans.map((plan) => (
          <Card
            key={plan.id} // Use MongoDB _id as the key
            sx={{
              border:
                selected === plan.priceId
                  ? '2px solid blue'
                  : '2px solid transparent', // Highlight the selected plan
              cursor: 'pointer',
              width: { xs: '100%', sm: '350px' },
              boxShadow:
                selected === plan.priceId
                  ? '0px 4px 20px rgba(0, 0, 0, 0.2)'
                  : '0px 4px 10px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)',
              },
            }}
            onClick={() => handleSelect(plan.priceId, plan.id)} // Use subscriptionId for selection
          >
            <CardContent
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.85)',
                borderRadius: '8px',
                padding: '20px',
                textAlign: 'center',
              }}
            >
              <Typography variant="h5" fontWeight={600} mb={1}>
                {plan.name}
              </Typography>
              <Typography variant="body2" mb={2}>
                {plan.description}
              </Typography>
              <Typography variant="h6" color="primary" fontWeight={700} mb={2}>
                ${plan.price}/month
              </Typography>
              <Typography variant="body2" color="text.secondary" mb={2}>
                {plan.allowedFeatures.join(', ')}
              </Typography>
              <Button
                variant="contained"
                color={selected === plan.priceId ? 'primary' : 'secondary'}
                sx={{
                  backgroundColor:
                    selected === plan.priceId ? 'primary' : 'white',
                  color: selected === plan.priceId ? 'white' : 'black',
                  borderRadius: '20px',
                }}
              >
                {selected === plan.priceId ? 'Selected' : 'Select Plan'}
              </Button>
            </CardContent>
          </Card>
        ))}
      </Box>
    </>
  );
};

export default PlanSelection;
