import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Box,
  Grid,
  Typography,
  TextField,
  Card,
} from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Colors from '../../Utils/Colors';
import CustomButton from '../../Components/MiscComponents/CustomButton';

const InvoiceItems = ({ data, onChange, setActiveStep, onBack }) => {
  const [disableNext, setDisableNext] = useState(false);

  const handleInputChange = (index, e) => {
    let newInvoiceItems = [...data?.invoice_items];
    let updatedItem = { ...newInvoiceItems[index] };
    updatedItem[e.target.name] = e.target.value;
    newInvoiceItems[index] = updatedItem;
    onChange((prev) => ({
      ...prev,
      invoice_items: newInvoiceItems,
    }));
  };

  const handleAddInvoice = () => {
    onChange((prev) => ({
      ...prev,
      invoice_items: [
        ...prev?.invoice_items,
        { invoice_item: '', quantity: null, rate: null },
      ],
    }));
  };

  const handleRemoveInvoice = (index) => {
    const newInvoiceItems = [...data?.invoice_items];
    newInvoiceItems.splice(index, 1);
    onChange((prev) => ({
      ...prev,
      invoice_items: newInvoiceItems,
    }));
  };

  const handleNextClick = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBackClick = () => {
    onBack();
  };

  useEffect(() => {
    if (!data?.invoice_items || !data?.invoice_items?.length) {
      setDisableNext(true);
    } else {
      const hasDefaultValues = data?.invoice_items?.some(
        (item) =>
          item.invoice_item === '' || item.quantity === null || item.rate === null || item.rate == 0
      );
      setDisableNext(hasDefaultValues);
    };
  }, [data]);

  return (
    <Box sx={{ maxWidth: '724px', mx: 'auto', mt: 5 }}>
      <Grid item xs={12}>
        <Typography textAlign="left" fontWeight={500} variant="h5" mb="20px">
          Invoice Items
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card className="no-hover" variant="outlined" sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {/* Headers */}
            <Grid item xs={12} sm={6}>
              <Typography textAlign="left" variant="subtitle1">
                Name
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography textAlign="left" variant="subtitle1">
                Quantity
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography textAlign="left" variant="subtitle1">
                Rate
              </Typography>
            </Grid>
            {/* Rows */}
            {data?.invoice_items?.map((invoice, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="invoice_item"
                    value={invoice?.invoice_item}
                    onChange={(e) => handleInputChange(index, e)}
                    sx={{ mb: 1 }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Quantity"
                    name="quantity"
                    value={invoice?.quantity}
                    onChange={(e) => handleInputChange(index, e)}
                    sx={{ mb: 1 }}
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Rate"
                    name="rate"
                    value={invoice?.rate}
                    onChange={(e) => handleInputChange(index, e)}
                    sx={{ mb: 1 }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={1}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <IconButton
                    onClick={() => handleRemoveInvoice(index)}
                    sx={{ color: 'error.main' }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
            {/* Add another item button */}
            <Grid item xs={12}>
              <Box
                onClick={handleAddInvoice}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  color: Colors.link,
                  justifyContent: 'flex-end',
                }}
              >
                <AddBoxIcon />
                <Typography variant="subtitle1" sx={{ ml: 1 }}>
                  {data?.invoice_items?.length
                    ? 'Add another item'
                    : 'Add item'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CustomButton
            type="submit"
            sx={{ mt: '32px' }}
            onClick={handleBackClick}
          >
            Back
          </CustomButton>
          <CustomButton
            type="submit"
            disabled={disableNext}
            sx={{ mt: '32px' }}
            onClick={handleNextClick}
          >
            Next
          </CustomButton>
        </Box>
      </Grid>
    </Box>
  );
};

export default InvoiceItems;
