import React from 'react';
import Fab from '@mui/material/Fab';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { styled } from '@mui/system';

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: 20,
  right: 20,
  zIndex: 1000,
}));

const FeedbackButton = () => {
  const handleFeedbackClick = (e) => {
    e.preventDefault();
    window.open(
      'https://feedback.userreport.com/08e22036-ca57-466b-b24c-9e769cc6b3fd/'
    );
  };

  return (
    <StyledFab
      color="primary"
      aria-label="feedback"
      //   href="https://feedback.userreport.com/08e22036-ca57-466b-b24c-9e769cc6b3fd/"
      onClick={handleFeedbackClick}
    >
      <FeedbackIcon />
    </StyledFab>
  );
};

export default FeedbackButton;
