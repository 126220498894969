import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateAttendanceStatus,
  resetAttendanceState, // TODO: Use the correct reset function
} from '../../Redux/slices/attendanceSlice';
import { toast } from 'react-toastify';
import {
Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

const AttendanceDetails = ({ attendanceData, onClose, onUpdateAttendance }) => {
  const dispatch = useDispatch();
  const { done, error, message } = useSelector((state) => state.attendance);
  const [editMode, setEditMode] = useState(false);
  const [attendanceDetails, setAttendanceDetails] = useState({
    checkedIn: attendanceData.checkedIn,
    checkedOut: attendanceData.checkedOut,
  });

  useEffect(() => {
    if (done) {
      toast.success(message);
      dispatch(resetAttendanceState()); // Reset state after operation
    }
    if (error) {
      toast.error(message);
      dispatch(resetAttendanceState()); // Reset state after error
    }
  }, [dispatch, done, error, message]);

  const handleSaveChanges = () => {
    const { checkedIn, checkedOut } = attendanceDetails;
    // TODO: ensure includes _id for attendance record
    // Update for check-in
    if (checkedIn) {
      dispatch(
        updateAttendanceStatus({
          id: attendanceData._id,
          status: 'Checked-In',
          date: checkedIn,
        })
      );
    }
    // Update for check-out
    if (checkedOut) {
      dispatch(
        updateAttendanceStatus({
          id: attendanceData._id,
          status: 'Checked-Out',
          date: checkedOut,
        })
      );
    }
    onClose();
    onUpdateAttendance();
  };

  const toggleEditMode = () => setEditMode(!editMode);

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md">
      <DialogTitle>Attendance Details</DialogTitle>
      <DialogContent>
        <IconButton onClick={toggleEditMode} style={{ alignSelf: 'flex-end' }}>
          {!editMode ? <EditIcon /> : <CloseIcon />}
        </IconButton>
        <TextField
          margin="dense"
          id="date"
          label="Date"
          type="date"
          fullWidth
          defaultValue={attendanceData.date}
          InputLabelProps={{
            shrink: true,
          }}
          disabled
        />
        <TextField
          margin="dense"
          id="checkedIn"
          label="Checked-In Time"
          type="time"
          fullWidth
          value={attendanceDetails.checkedIn}
          onChange={(e) =>
            setAttendanceDetails({
              ...attendanceDetails,
              checkedIn: e.target.value,
            })
          }
          disabled={!editMode}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          id="checkedOut"
          label="Checked-Out Time"
          type="time"
          fullWidth
          value={attendanceDetails.checkedOut}
          onChange={(e) =>
            setAttendanceDetails({
              ...attendanceDetails,
              checkedOut: e.target.value,
            })
          }
          disabled={!editMode}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {editMode && (
          <Button onClick={handleSaveChanges} color="primary">
            Save Changes
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AttendanceDetails;
