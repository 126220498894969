import React, { useState } from 'react';
import {
  Box,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Card,
  IconButton,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {
  BILLING_MESSAGES,
  CURRENCY_ENUMS,
  CUSTOM_BILLING_FREQ_ENUM,
  ENGAGEMENT_TYPE_ENUMS,
  PROJECT_BILLING_FREQUENCIES,
  PROJECT_MEMBERS_ROLES,
  PROJECT_MEMBERS_ROLE_ENUMS,
  PROJECT_STATUS_OBJ_ENUMS,
} from '../../Utils/Constants';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import CustomButton from '../../Components/MiscComponents/CustomButton';
import moment from 'moment';
import { DeleteOutlined } from '@mui/icons-material';
import Colors from '../../Utils/Colors';
import { ERROR_MESSAGES } from '../../Utils/ValidationErrors';

const EditProjectDialog = ({
  open,
  onClose,
  heading = 'Edit Project',
  editedProject = {},
  handleEditChange = () => {},
  setEditedProject,
  setProjectManagerSearch = () => {},
  onSave = () => {},
  dateError,
  employees,
}) => {
  const [step, setStep] = useState(0);
  const [errors, setErrors] = useState({});

  const handleMemberChange = (index, e) => {
    let newMember = [...editedProject?.members];
    let updatedMember = { ...newMember[index] };
    updatedMember[e.target.name] = e.target.value;

    // If "Project Manager" is selected for one member, disable it for all other members
    if (
      e.target.name === 'role' &&
      e.target.value === PROJECT_MEMBERS_ROLE_ENUMS.projectManager
    ) {
      newMember.forEach((member, i) => {
        if (
          i !== index &&
          member.role == PROJECT_MEMBERS_ROLE_ENUMS.projectManager
        ) {
          member.role = '';
        }
      });
    }

    newMember[index] = updatedMember;
    setEditedProject((prev) => ({
      ...prev,
      members: newMember,
    }));
  };

  const handleAddMember = () => {
    setEditedProject((prev) => ({
      ...prev,
      members: [
        ...prev?.members,
        {
          user: '',
          role: '',
          engagement_type: '',
          billing_rate: null,
          hour_cap: null,
        },
      ],
    }));
  };

  const handleRemoveMember = (index) => {
    const newMember = [...editedProject?.members];
    newMember.splice(index, 1);
    setEditedProject((prev) => ({
      ...prev,
      members: newMember,
    }));
  };

  const handleAddMilestone = () => {
    setEditedProject((prev) => ({
      ...prev,
      milestone_billing: [
        ...prev?.milestone_billing,
        {
          name: '',
          amount: '',
          due_date: null,
        },
      ],
    }));
  };

  const handleRemoveMilestone = (index) => {
    const newMilestone = [...editedProject?.milestone_billing];
    newMilestone.splice(index, 1);
    setEditedProject((prev) => ({
      ...prev,
      milestone_billing: newMilestone,
    }));
  };

  const handleMilestoneChange = (index, e) => {
    let newMilestone = [...editedProject?.milestone_billing];
    let updatedMilestone = { ...newMilestone[index] };
    updatedMilestone[e.target.name] = e.target.value;
    newMilestone[index] = updatedMilestone;

    setEditedProject((prev) => ({
      ...prev,
      milestone_billing: newMilestone,
    }));
  };

  const handleClientChange = (e) => {
    setEditedProject((prev) => ({
      ...prev,
      client_details: {
        ...prev?.client_details,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const NUM_STEPS =
    editedProject.billing_frequency === PROJECT_BILLING_FREQUENCIES.milestone
      ? 4
      : 3;

  const validateForm = () => {
    let newErrors = {};

    // Validating required fields
    if (step === 0) {
      if (!editedProject?.name)
        newErrors.name = ERROR_MESSAGES.PROJECT_NAME_REQUIRED;
    } else if (step == 1) {
      if (!editedProject?.billing_currency)
        newErrors.billing_currency = ERROR_MESSAGES.BILLING_CURRENCY_REQUIRED;
    } else if (step === 2) {
      if (!editedProject?.members || editedProject?.members?.length === 0) {
        newErrors.members = ERROR_MESSAGES.AT_LEAST_ONE_MEMBER_REQUIRED;
      } else {
        editedProject?.members?.forEach((member, index) => {
          if (!member.user)
            newErrors[`members[${index}].user`] =
              ERROR_MESSAGES.MEMBER_USER_REQUIRED;
          if (!member.role)
            newErrors[`members[${index}].role`] =
              ERROR_MESSAGES.MEMBER_ROLE_REQUIRED;
          if (!member.engagement_type)
            newErrors[`members[${index}].engagement_type`] =
              'Engagement type is required';
          if (!member.billing_rate)
            newErrors[`members[${index}].billing_rate`] =
              ERROR_MESSAGES.MEMBER_BILLING_RATE_REQUIRED;
        });
      }
    } else if (step == NUM_STEPS) {
      if (!editedProject?.client_details?.first_name)
        newErrors.client_details = {
          ...newErrors.client_details,
          first_name: ERROR_MESSAGES.CLIENT_FIRST_NAME_REQUIRED,
        };
      if (!editedProject?.client_details?.last_name)
        newErrors.client_details = {
          ...newErrors.client_details,
          last_name: ERROR_MESSAGES.CLIENT_LAST_NAME_REQUIRED,
        };
      if (!editedProject?.client_details?.email)
        newErrors.client_details = {
          ...newErrors.client_details,
          email: ERROR_MESSAGES.CLIENT_EMAIL_REQUIRED,
        };
      else if (!/\S+@\S+\.\S+/.test(editedProject?.client_details?.email))
        newErrors.client_details = {
          ...newErrors.client_details,
          email: ERROR_MESSAGES.CLIENT_EMAIL_INVALID,
        };
      if (!editedProject?.client_details?.company_name)
        newErrors.client_details = {
          ...newErrors.client_details,
          company_name: ERROR_MESSAGES.CLIENT_COMPANY_NAME_REQUIRED,
        };
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = (e) => {
    e.preventDefault();

    const isFormValid = validateForm();
    if (!isFormValid) return;
    setStep((currentStep) => currentStep + 1);
  };

  return (
    <CustomDialog
      open={open}
      onClose={() => {
        onClose();
        setStep(0);
      }}
      heading={heading}
      size="md"
    >
      <Box
        sx={{
          my: '24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '18px',
        }}
      >
        {step == 0 ? (
          <>
            <TextField
              label="Project Name"
              variant="outlined"
              name="name"
              value={editedProject.name}
              error={!!errors?.name}
              helperText={errors.name}
              onChange={handleEditChange}
              fullWidth
            />
            <TextField
              label="Description"
              variant="outlined"
              name="description"
              value={editedProject.description}
              onChange={handleEditChange}
              fullWidth
              multiline
              rows={4}
            />
            <Box display="grid" gridTemplateColumns="1fr 1fr" gap="inherit">
              <TextField
                label="Start Date"
                type="date"
                name="start_date"
                error={!!errors?.start_date}
                value={editedProject.start_date}
                onChange={handleEditChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="End Date"
                type="date"
                name="end_date"
                value={editedProject.end_date}
                onChange={handleEditChange}
                error={dateError}
                helperText={
                  dateError ? "End date can't be before start date" : ''
                }
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <FormControl fullWidth>
              <InputLabel id="status-label">Project Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                name="status"
                value={editedProject.status}
                label="Project Status"
                onChange={(event) =>
                  handleEditChange(event.target.name, event.target.value)
                }
              >
                {PROJECT_STATUS_OBJ_ENUMS.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ) : step == 1 ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography fontWeight={500} marginBottom="5px" color="primary">
                  Also add how you're working on this project or client
                  engagement
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Billing Currency</InputLabel>
                  <Select
                    name="billing_currency"
                    label="Billing Currency"
                    value={editedProject?.billing_currency}
                    style={{ marginBottom: '15px' }}
                    onChange={(event) =>
                      handleEditChange(event.target.name, event.target.value)
                    }
                    error={
                      errors?.billing_currency &&
                      !editedProject?.billing_currency && (
                        <Typography variant="caption" color="error">
                          {errors?.billing_currency}
                        </Typography>
                      )
                    }
                    displayEmpty
                    fullWidth
                  >
                    {CURRENCY_ENUMS?.map((currency, index) => (
                      <MenuItem key={index} value={currency}>
                        {currency}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors?.billing_currency &&
                  !editedProject?.billing_currency && (
                    <Typography variant="caption" color="error">
                      {errors?.billing_currency}
                    </Typography>
                  )}
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="billing frequency"
                    name="billing_frequency"
                    value={editedProject?.billing_frequency}
                    onChange={handleEditChange}
                  >
                    {Object.keys(PROJECT_BILLING_FREQUENCIES).map((key) => (
                      <FormControlLabel
                        key={key}
                        value={PROJECT_BILLING_FREQUENCIES[key]}
                        control={<Radio />}
                        label={
                          BILLING_MESSAGES[key] ||
                          PROJECT_BILLING_FREQUENCIES[key]
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                {editedProject?.billing_frequency ===
                  PROJECT_BILLING_FREQUENCIES.custom && (
                  <>
                    <TextField
                      type="date"
                      name="custom_billing_start"
                      label="Custom Billing Start"
                      variant="outlined"
                      value={
                        moment(editedProject?.custom_billing_start)
                          .startOf('day')
                          .format('YYYY-MM-DD') || ''
                      }
                      fullWidth
                      onChange={handleEditChange}
                      style={{ marginBottom: '15px' }}
                    ></TextField>

                    <FormControl fullWidth>
                      <InputLabel>Custom Billing Frequency</InputLabel>
                      <Select
                        name="custom_billing_frequency"
                        label="Custom Billing Frequency"
                        value={editedProject?.custom_billing_frequency || ''}
                        style={{ marginBottom: '15px' }}
                        onChange={handleEditChange}
                        displayEmpty
                        fullWidth
                      >
                        {CUSTOM_BILLING_FREQ_ENUM?.map((freq, index) => (
                          <MenuItem key={index} value={freq}>
                            {freq}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        ) : step === 2 ? (
          <>
            {/* Select Project Manager
          <Autocomplete
            options={employees}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            // onInputChange={(event, newInputValue) => {
            //   setProjectManagerSearch(newInputValue);
            // }}
            onChange={(event, newValue) => {
              handleChange('project_manager', newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Project Manager" fullWidth />
            )}
          /> */}
            <Typography fontWeight={500} color="primary">
              Who you're working with
            </Typography>
            <Typography variant="subtitle2">
              Who else is working with you on "{editedProject?.name}"?
            </Typography>
            <Card className="no-hover" variant="outlined" sx={{ p: 2 }}>
              <Grid container spacing={2}>
                {/* Headers */}
                <Grid item xs={12} sm={3}>
                  <Typography textAlign="left" variant="subtitle1">
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Typography textAlign="left" variant="subtitle1">
                    Role
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Typography textAlign="left" variant="subtitle1">
                    Engagement Type
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Typography textAlign="left" variant="subtitle1">
                    Rate
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Typography textAlign="left" variant="subtitle1">
                    Cap on Hours
                  </Typography>
                </Grid>
                {editedProject?.members?.map((member, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12} sm={3}>
                      <FormControl
                        fullWidth
                        error={
                          !!errors?.[`members[${index}].user`] &&
                          member.user === ''
                        }
                      >
                        <InputLabel>Member</InputLabel>
                        <Select
                          name="user"
                          required
                          value={member?.user?.id || member.user}
                          onChange={(e) => handleMemberChange(index, e)}
                          displayEmpty
                          label="Member"
                        >
                          {employees?.map((user, i) => (
                            <MenuItem key={i} value={user.value}>
                              {user?.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors?.[`members[${index}].user`] &&
                          member.user === '' && (
                            <Typography variant="caption" color="error">
                              {errors?.[`members[${index}].user`]}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <FormControl
                        fullWidth
                        error={
                          !!errors?.[`members[${index}].role`] &&
                          member.role === ''
                        }
                      >
                        <InputLabel>Role</InputLabel>
                        <Select
                          name="role"
                          required
                          value={member.role}
                          onChange={(e) => handleMemberChange(index, e)}
                          displayEmpty
                          label="Role"
                        >
                          {PROJECT_MEMBERS_ROLES?.map((role) => (
                            <MenuItem key={role} value={role}>
                              {role}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors?.[`members[${index}].role`] &&
                          member.role === '' && (
                            <Typography variant="caption" color="error">
                              {errors?.[`members[${index}].role`]}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={2}>
                      <FormControl
                        fullWidth
                        error={
                          !!errors?.[`members[${index}].engagement_type`] &&
                          member.engagement_type === ''
                        }
                      >
                        <InputLabel>Engagement Type</InputLabel>
                        <Select
                          name="engagement_type"
                          required
                          value={member?.engagement_type}
                          onChange={(e) => handleMemberChange(index, e)}
                          displayEmpty
                          label="Engagement Type"
                        >
                          {ENGAGEMENT_TYPE_ENUMS?.map((type, index) => (
                            <MenuItem key={index} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors?.[`members[${index}].engagement_type`] &&
                          member.engagement_type === '' && (
                            <Typography variant="caption" color="error">
                              {errors?.[`members[${index}].engagement_type`]}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={2}>
                      <TextField
                        fullWidth
                        error={
                          !!errors?.[`members[${index}].billing_rate`] &&
                          member.billing_rate === null
                        }
                        type="number"
                        label="Rate"
                        name="billing_rate"
                        value={member.billing_rate}
                        onChange={(e) => handleMemberChange(index, e)}
                        sx={{ mb: 1 }}
                      />
                      {errors?.[`members[${index}].billing_rate`] &&
                        member.billing_rate === null && (
                          <Typography variant="caption" color="error">
                            {errors?.[`members[${index}].billing_rate`]}
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Hour Cap"
                        name="hour_cap"
                        value={member.hour_cap}
                        onChange={(e) => handleMemberChange(index, e)}
                        sx={{ mb: 1 }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={1}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <IconButton
                        onClick={() => handleRemoveMember(index)}
                        sx={{ color: 'error.main' }}
                      >
                        <DeleteOutlined />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid item xs={12}>
                  <Box
                    onClick={handleAddMember}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      color: Colors.link,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <AddBoxIcon />
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                      Add teammate
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </>
        ) : step === 3 &&
          editedProject?.billing_frequency ===
            PROJECT_BILLING_FREQUENCIES.milestone ? (
          <>
            <Typography variant="h5" fontWeight={500}>
              How you're charging
            </Typography>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography fontWeight={500} marginBottom="5px">
                Do you have any milestones for this project? Adding them will
                help you track progress towards them and understand projected
                revenues and potential optimizations.
              </Typography>
            </Grid>

            <Card className="no-hover" variant="outlined" sx={{ p: 2, my: 4 }}>
              <Grid container spacing={2}>
                {/* Headers */}
                <Grid item xs={12} sm={4}>
                  <Typography textAlign="left" variant="subtitle1">
                    Milestone
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography textAlign="left" variant="subtitle1">
                    Amount
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography textAlign="left" variant="subtitle1">
                    Due Date
                  </Typography>
                </Grid>

                {editedProject?.milestone_billing?.map((member, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Milestone"
                        name="name"
                        value={member.name}
                        onChange={(e) => handleMilestoneChange(index, e)}
                        sx={{ mb: 1 }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Amount"
                        name="amount"
                        value={member.amount}
                        onChange={(e) => handleMilestoneChange(index, e)}
                        sx={{ mb: 1 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        type="date"
                        name="due_date"
                        value={member.due_date}
                        onChange={(e) => handleMilestoneChange(index, e)}
                        sx={{ mb: 1 }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={1}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <IconButton
                        onClick={() => handleRemoveMilestone(index)}
                        sx={{ color: 'error.main' }}
                      >
                        <DeleteOutlined />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid item xs={12}>
                  <Box
                    onClick={handleAddMilestone}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      color: Colors.link,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <AddBoxIcon />
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                      Add milestone
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </>
        ) : (
          <>
            <Typography fontWeight={500} color="primary">
              Who your client is
            </Typography>
            <Typography variant="subtitle2" marginBottom="5px">
              Add in any details for your client that you'd like to see show up
              on your project reports or invoices
            </Typography>
            <Grid container spacing={2}>
              {/* Client Information */}

              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  name="first_name"
                  variant="outlined"
                  value={editedProject?.client_details?.first_name}
                  error={
                    !!errors?.client_details?.first_name &&
                    !editedProject?.client_details?.first_name
                  }
                  fullWidth
                  style={{ marginBottom: '7px' }}
                  onChange={handleClientChange}
                />
                {errors?.client_details?.first_name &&
                  !editedProject?.client_details?.first_name && (
                    <Typography variant="caption" color="error">
                      {errors?.client_details?.first_name}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  name="last_name"
                  error={
                    !!errors?.client_details?.last_name &&
                    !editedProject?.client_details?.last_name
                  }
                  variant="outlined"
                  value={editedProject?.client_details?.last_name}
                  fullWidth
                  style={{ marginBottom: '7px' }}
                  onChange={handleClientChange}
                />
                {errors?.client_details?.last_name &&
                  !editedProject?.client_details?.last_name && (
                    <Typography variant="caption" color="error">
                      {errors?.client_details?.last_name}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  name="email"
                  variant="outlined"
                  error={
                    (!!errors?.client_details?.email &&
                      !editedProject?.client_details?.email) ||
                    (!!editedProject?.client_details?.email &&
                      !/\S+@\S+\.\S+/.test(
                        editedProject?.client_details?.email
                      ))
                  }
                  value={editedProject?.client_details?.email || ''}
                  fullWidth
                  onChange={handleClientChange}
                  style={{ marginBottom: '7px' }}
                />
                {errors?.client_details?.email &&
                  !editedProject?.client_details?.email && (
                    <Typography variant="caption" color="error">
                      {errors?.client_details?.email}
                    </Typography>
                  )}
                {editedProject?.client_details?.email &&
                  !/\S+@\S+\.\S+/.test(
                    editedProject?.client_details?.email
                  ) && (
                    <Typography variant="caption" color="error">
                      Email address is invalid
                    </Typography>
                  )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  label="Phone Number"
                  name="phone_number"
                  variant="outlined"
                  value={editedProject?.client_details?.phone_number || ''}
                  fullWidth
                  onChange={handleClientChange}
                  style={{ marginBottom: '7px' }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Company Name"
                  name="company_name"
                  error={
                    !!errors?.client_details?.company_name &&
                    !editedProject?.client_details?.company_name
                  }
                  variant="outlined"
                  value={editedProject?.client_details?.company_name || ''}
                  onChange={handleClientChange}
                  style={{ marginBottom: '7px' }}
                  fullWidth
                />
                {errors?.client_details?.company_name &&
                  !editedProject?.client_details?.company_name && (
                    <Typography variant="caption" color="error">
                      {errors?.client_details?.company_name}
                    </Typography>
                  )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Country"
                  name="country"
                  variant="outlined"
                  style={{ marginBottom: '7px' }}
                  value={editedProject?.client_details?.country || ''}
                  onChange={handleClientChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Address"
                  name="address"
                  variant="outlined"
                  style={{ marginBottom: '7px' }}
                  onChange={handleClientChange}
                  value={editedProject?.client_details?.address || ''}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  label="Zip Code"
                  variant="outlined"
                  name="zip_code"
                  value={editedProject?.client_details?.zip_code || ''}
                  style={{ marginBottom: '7px' }}
                  onChange={handleClientChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        gap="8px"
      >
        {step > 0 && (
          <Button
            variant="text"
            onClick={() => setStep((s) => s - 1)}
            sx={{ mr: 'auto' }}
          >
            Back
          </Button>
        )}
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
            setStep(0);
          }}
        >
          Cancel
        </Button>
        {step === NUM_STEPS ? (
          <Button
            variant="contained"
            type="submit"
            onClick={() => {
              const isFormValid = validateForm();
              if (!isFormValid) return;
              onSave();
            }}
          >
            Save Changes
          </Button>
        ) : (
          <Button variant="contained" onClick={handleNextStep}>
            Next
          </Button>
        )}
      </Box>
    </CustomDialog>
  );
};

export default EditProjectDialog;
