import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logger } from '../../Services/loggerService';

const initialState = {
  onboardingStatus: null,
  leaveDetails: null,
  loading: false,
  error: null,
  message: null,
};

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const onboardingNewOrganization = createAsyncThunk(
  'onboarding/onboardingNewOrganization',
  async (onboardingData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/v1/onboard', onboardingData);
      return response.data;
    } catch (error) {
      logger.error('Onboarding new organization', error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOnboardingStatus = createAsyncThunk(
  'onboarding/getOnboardingStatus',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/v1/onboard/get-status', {
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data?.data;
    } catch (error) {
      logger.error('Get onboarding status', error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateOnboarding = createAsyncThunk(
  'onboarding/updateOnboarding',
  async (_, thunkAPI) => {
    try {
      const response = await axios.put(`/v1/onboard/update-status`);
      return response.message;
    } catch (error) {
      logger.error('Update onboarding status', error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOnboardingStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOnboardingStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.onboardingStatus = action.payload;
      })
      .addCase(getOnboardingStatus.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(onboardingNewOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(onboardingNewOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.onboardingStatus = action.payload;
      })
      .addCase(onboardingNewOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateOnboarding.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOnboarding.fulfilled, (state, action) => {
        state.loading = false;
        state.onboardingStatus = false; // check this
        state.message = action.payload;
      })
      .addCase(updateOnboarding.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default onboardingSlice.reducer;
