import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Pagination } from '@mui/material';
import PlaceholderForEmptyState from './PlaceholderEmptyTable';

const CustomTable = ({
  headers = [],
  data = [],
  handleRowClick,
  currentPage,
  totalPages,
  handlePageChange,
  emptyStateMessage = '',
  rowStyles = () => ({}),
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      {data.length === 0 ? (
        <PlaceholderForEmptyState
          message={emptyStateMessage || 'No data found'}
        />
      ) : (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell key={index} align="left">
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow
                  key={index}
                  style={{
                    cursor: handleRowClick ? 'pointer' : 'default',
                    ...rowStyles(item),
                  }}
                  onClick={() => (handleRowClick ? handleRowClick(item) : null)}
                >
                  {headers.map((header, headerIndex) => {
                    if (header.render) {
                      return (
                        <TableCell key={headerIndex} align="left">
                          {header.render(item)}
                        </TableCell>
                      );
                    }

                    let cellValue = 'N/A';
                    if (header.key) {
                      const keys = header.key.split('.');
                      let value = item;
                      for (const key of keys) {
                        value = value ? value[key] : null;
                      }
                      cellValue =
                        value !== null && value !== undefined
                          ? String(value)
                          : 'N/A';
                    } else if (header.keys && Array.isArray(header.keys)) {
                      cellValue =
                        header.keys
                          .map((key) => {
                            const keys = key.split('.');
                            let value = item;
                            for (const key of keys) {
                              value = value ? value[key] : null;
                            }
                            return value !== null && value !== undefined
                              ? String(value)
                              : '';
                          })
                          .filter(Boolean)
                          .join(' ') || 'N/A';
                    }
                    if (header.format && cellValue !== 'N/A') {
                      cellValue = header.format(cellValue);
                    }
                    return (
                      <TableCell key={headerIndex} align="left">
                        {cellValue}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalPages > 0 && (
        <Pagination
          count={totalPages || 0}
          page={currentPage || 0}
          onChange={handlePageChange}
          sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
        />
      )}
    </Box>
  );
};

export default CustomTable;
