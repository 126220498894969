import React from 'react';
import Typography from '@mui/material/Typography';
import { ADMIN_SIDEBAR, EMPLOYEE_SIDEBAR } from '../Utils/Constants';
import EmployeeList from '../Pages/EmployeeList/EmployeeList';
import { useSelector } from 'react-redux';
import AttendanceDashboard from '../Pages/AttendanceManagement/AttendanceDashboard';
import ProjectList from '../Pages/ProjectManagement/ProjectList';
import { useParams } from 'react-router-dom';
import Footer from './Footer/Footer';
import WorkLogHistory from '../Pages/WorkLogs/WorkLogs';

const ContentContainer = () => {
  const params = useParams();
  const currentTab = params?.tab;

  const userRole = useSelector(
    (state) => state.auth.loginResponse?.data?.organizationData?.role
  );

  const isAdmin = userRole === 'admin' || userRole === 'Admin';

  const renderAdminContent = () => {
    switch (currentTab) {
      case ADMIN_SIDEBAR.EMPLOYEE_RECORDS.label:
        return <EmployeeList />;
      case ADMIN_SIDEBAR.ATTENDANCE_MANAGEMENT.label:
        return <AttendanceDashboard />;
      case ADMIN_SIDEBAR.PROJECTS.label:
        return <ProjectList />;
      case ADMIN_SIDEBAR.WORK_LOG_HISTORY.label:
        return <WorkLogHistory />;
      default:
        return (
          <Typography paragraph variant="h4" py={4}>
            {/* Welcome to Daftar App! */}
          </Typography>
        );
    }
  };

  const renderEmployeeContent = () => {
    switch (currentTab) {
      case EMPLOYEE_SIDEBAR.PROJECTS.label:
        return <ProjectList />;
      case EMPLOYEE_SIDEBAR.WORK_LOG_HISTORY.label:
        return <WorkLogHistory />;
      default:
        return <Footer />;
    }
  };

  const renderContent = () => {
    return (
      <>
        {isAdmin && renderAdminContent()}
        {renderEmployeeContent()}
      </>
    );
  };

  return <>{renderContent()}</>;
};

export default ContentContainer;
