import React from 'react';
import './ProjectList.css';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { PROJECT_STATUS_ENUMS } from '../../Utils/Constants';
import { Box, Card, Typography } from '@mui/material';
import { formatDate } from '../../Utils/functions';

function ProjectCard({ project }) {
  const navigate = useNavigate();

  function handleProjectRedirect(id) {
    const projectDetailURL = `/projects/${id}`; // Directly using string template for clarity
    navigate(projectDetailURL);
  }

  return (
    <Card
      onClick={() => handleProjectRedirect(project.id)} // Changed project?._id to project.id
      sx={{
        cursor: 'pointer',
        height: '100%',
        p: '18px',

        '& .card-body, & .card-heading': {
          transition: 'all ease 0.2s',
        },
      }}
    >
      <Box
        className={`project-status ${
          PROJECT_STATUS_ENUMS.includes(project?.status) // status is the correct field based on  API response
            ? project.status.toLowerCase().replace(' ', '')
            : ''
        }`}
        sx={{ position: 'absolute', top: '-10px' }}
      >
        {project.status}
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        textAlign="left"
        height="100%"
        position="relative"
      >
        <Typography
          className="card-heading"
          variant="h4"
          fontWeight={500}
          mt="8px"
        >
          {project.name}
        </Typography>
        <Typography
          className="card-body"
          variant="body1"
          marginTop="18px"
          fontWeight={300}
          sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {project.description}
        </Typography>

        <Typography
          className="card-body"
          variant="body2"
          color="secondary.contrastText"
          mt="auto"
          pt={'42px'}
          marginLeft="auto"
          textAlign="right"
          fontWeight={300}
        >
          Start date:{' '}
          <Typography
            component="span"
            fontSize="inherit"
            color="inherit"
            fontWeight={400}
          >
            {project.start_date ? formatDate(project.start_date) : 'N/A'}
          </Typography>
        </Typography>
      </Box>
    </Card>
  );
}
export default ProjectCard;
