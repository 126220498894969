import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Card,
} from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { ERROR_MESSAGES } from '../../Utils/ValidationErrors';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { addNewProject } from '../../Redux/slices/projectSlice';
import { getAllUsers } from '../../Redux/slices/userSlice';
import { PROJECT_BASE_URL } from '../../Routes/route_constants';
import {
  PROJECT_STATUS_OBJ_ENUMS,
  ENGAGEMENT_TYPE_ENUMS,
  PROJECT_MEMBERS_ROLES,
  PROJECT_BILLING_FREQUENCIES,
  CURRENCY_ENUMS,
  CUSTOM_BILLING_FREQ_ENUM,
  BILLING_MESSAGES,
  PROJECT_MEMBERS_ROLE_ENUMS,
} from '../../Utils/Constants';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import moment from 'moment';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import Colors from '../../Utils/Colors';

function AddProjectModal({ onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [projectManagerSearch, setProjectManagerSearch] = useState('');
  const [memberSearch, setMemberSearch] = useState('');
  const usersResponse = useSelector((state) => state.users.allUsers);
  const [employees, setEmployees] = useState([]);

  const fetchUsersDebounced = useCallback(
    debounce((search) => fetchUsers(search), 500),
    []
  );
  const [dateError, setDateError] = useState(false);
  const [formValues, setFormValues] = useState({
    members: [],
    milestone_billing: [],
  });
  const [errors, setErrors] = useState({});

  // Function to fetch users based on search
  const fetchUsers = (search) => {
    const filters = search ? { search: search } : {};
    dispatch(getAllUsers({ filters: filters, page: 1 }));
  };

  function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
  useEffect(() => {
    fetchUsersDebounced(projectManagerSearch);
  }, [projectManagerSearch, fetchUsersDebounced]);

  useEffect(() => {
    fetchUsersDebounced(memberSearch);
  }, [memberSearch, fetchUsersDebounced]);

  useEffect(() => {
    if (usersResponse) {
      const formattedUsers = usersResponse.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
      }));
      setEmployees(formattedUsers);
    }
  }, [usersResponse]);

  const NUM_STEPS =
    formValues.billing_frequency === PROJECT_BILLING_FREQUENCIES.milestone
      ? 4
      : 3;

  const validateForm = () => {
    let newErrors = {};

    // Validating required fields
    if (step === 0) {
      if (!formValues?.name)
        newErrors.name = ERROR_MESSAGES.PROJECT_NAME_REQUIRED;
    } else if (step == 1) {
      if (!formValues?.billing_currency)
        newErrors.billing_currency = ERROR_MESSAGES.BILLING_CURRENCY_REQUIRED;
    } else if (step === 2) {
      if (!formValues?.members || formValues?.members?.length === 0) {
        newErrors.members = ERROR_MESSAGES.AT_LEAST_ONE_MEMBER_REQUIRED;
      } else {
        formValues?.members?.forEach((member, index) => {
          if (!member.user)
            newErrors[`members.[${index}].user`] =
              ERROR_MESSAGES.MEMBER_USER_REQUIRED;
          if (!member.role)
            newErrors[`members.[${index}].role`] =
              ERROR_MESSAGES.MEMBER_ROLE_REQUIRED;
          if (!member.engagement_type)
            newErrors[`members.[${index}].engagement_type`] =
              ERROR_MESSAGES.MEMBER_ENGAGEMENT_TYPE_REQUIRED;
          if (!member.billing_rate)
            newErrors[`members.[${index}].billing_rate`] =
              ERROR_MESSAGES.MEMBER_BILLING_RATE_REQUIRED;
        });
      }
    } else if (step == NUM_STEPS) {
      if (!formValues?.client_details?.first_name)
        newErrors.client_details = {
          ...newErrors.client_details,
          first_name: ERROR_MESSAGES.FIRST_NAME_REQUIRED,
        };
      if (!formValues?.client_details?.last_name)
        newErrors.client_details = {
          ...newErrors.client_details,
          last_name: ERROR_MESSAGES.LAST_NAME_REQUIRED,
        };
      if (!formValues?.client_details?.email)
        newErrors.client_details = {
          ...newErrors.client_details,
          email: ERROR_MESSAGES.EMAIL_REQUIRED,
        };
      else if (!/\S+@\S+\.\S+/.test(formValues?.client_details?.email))
        newErrors.client_details = {
          ...newErrors.client_details,
          email: ERROR_MESSAGES.EMAIL_INVALID,
        };
      if (!formValues?.client_details?.company_name)
        newErrors.client_details = {
          ...newErrors.client_details,
          company_name: ERROR_MESSAGES.CLIENT_COMPANY_NAME_REQUIRED,
        };
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClientChange = (e) => {
    setFormValues((prev) => ({
      ...prev,
      client_details: {
        ...prev?.client_details,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const handleMemberChange = (index, e) => {
    let newMember = [...formValues?.members];
    let updatedMember = { ...newMember[index] };
    updatedMember[e.target.name] = e.target.value;

    // If "Project Manager" is selected for one member, disable it for all other members
    if (
      e.target.name === 'role' &&
      e.target.value === PROJECT_MEMBERS_ROLE_ENUMS.projectManager
    ) {
      newMember.forEach((member, i) => {
        if (
          i !== index &&
          member.role == PROJECT_MEMBERS_ROLE_ENUMS.projectManager
        ) {
          member.role = '';
        }
      });
    }
    newMember[index] = updatedMember;

    setFormValues((prev) => ({
      ...prev,
      members: newMember,
    }));
  };

  const handleAddMember = () => {
    setFormValues((prev) => ({
      ...prev,
      members: [
        ...prev?.members,
        {
          user: '',
          role: '',
          engagement_type: '',
          billing_rate: null,
          hour_cap: null,
        },
      ],
    }));
  };

  const handleRemoveMember = (index) => {
    const newMember = [...formValues?.members];
    newMember.splice(index, 1);
    setFormValues((prev) => ({
      ...prev,
      members: newMember,
    }));
  };

  const handleAddMilestone = () => {
    setFormValues((prev) => ({
      ...prev,
      milestone_billing: [
        ...prev?.milestone_billing,
        {
          name: '',
          amount: '',
          due_date: null,
        },
      ],
    }));
  };

  const handleRemoveMilestone = (index) => {
    const newMilestone = [...formValues?.milestone_billing];
    newMilestone.splice(index, 1);
    setFormValues((prev) => ({
      ...prev,
      milestone_billing: newMilestone,
    }));
  };

  const handleMilestoneChange = (index, e) => {
    let newMilestone = [...formValues?.milestone_billing];
    let updatedMilestone = { ...newMilestone[index] };
    updatedMilestone[e.target.name] = e.target.value;
    newMilestone[index] = updatedMilestone;

    setFormValues((prev) => ({
      ...prev,
      milestone_billing: newMilestone,
    }));
  };

  const handleNextStep = (e) => {
    e.preventDefault();

    const isFormValid = validateForm();
    if (!isFormValid) return;
    setStep((currentStep) => currentStep + 1);
  };

  const handleChange = (name, value) => {
    if (name == 'start_date' || name == 'end_date') {
      const format = 'YYYY-MM-DD';
      let start, end;

      if (name === 'start_date') {
        start = moment(value, format);
        end = moment(formValues.end_date, format);
      } else if (name === 'end_date') {
        start = moment(formValues.start_date, format);
        end = moment(value, format);
      }

      if (start.isValid() && end.isValid()) {
        setDateError(end.isBefore(start));
      }
    }

    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = validateForm();
    if (!isFormValid) return;

    try {
      await dispatch(addNewProject(formValues)).unwrap();
      toast.success('Project added successfully');
      navigate(PROJECT_BASE_URL);
      onClose();
    } catch (error) {
      toast.error('Failed to add project: ' + error.message);
    }
  };

  return (
    <CustomDialog
      open={true}
      onClose={onClose}
      heading="Add New Project"
      size="md"
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            my: '24px',
            display: 'flex',
            flexDirection: 'column',
            gap: '18px',
          }}
        >
          {step == 0 ? (
            <>
              <Typography fontWeight={500} color="primary">
                What you're working on
              </Typography>

              <TextField
                fullWidth
                label="Project Name"
                variant="outlined"
                id="name"
                error={!!errors?.name}
                helperText={errors.name}
                value={formValues?.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                id="description"
                onChange={(e) => handleChange('description', e.target.value)}
                multiline
                rows={4}
              />
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap="inherit">
                <TextField
                  fullWidth
                  label="Start Date"
                  type="date"
                  variant="outlined"
                  id="start_date"
                  error={!!errors?.start_date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formValues?.start_date}
                  onChange={(e) => handleChange('start_date', e.target.value)}
                />
                <TextField
                  error={dateError}
                  helperText={
                    dateError ? "End date can't be before start date" : ''
                  }
                  fullWidth
                  label="End Date"
                  type="date"
                  variant="outlined"
                  id="end_date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formValues?.end_date}
                  onChange={(e) => handleChange('end_date', e.target.value)}
                />
              </Box>
              <FormControl fullWidth>
                <InputLabel id="status-label">Project Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="status"
                  value={formValues?.status}
                  label="Project Status"
                  onChange={(event) =>
                    handleChange('status', event.target.value)
                  }
                >
                  {PROJECT_STATUS_OBJ_ENUMS.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ) : step == 1 ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    fontWeight={500}
                    marginBottom="5px"
                    color="primary"
                  >
                    Also add how you're working on this project or client
                    engagement
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Billing Currency</InputLabel>
                    <Select
                      name="billing_currency"
                      label="Billing Currency"
                      value={formValues?.billing_currency}
                      style={{ marginBottom: '15px' }}
                      error={
                        errors?.billing_currency &&
                        !formValues?.billing_currency && (
                          <Typography variant="caption" color="error">
                            {errors?.billing_currency}
                          </Typography>
                        )
                      }
                      onChange={(e) =>
                        handleChange('billing_currency', e.target.value)
                      }
                      displayEmpty
                      fullWidth
                    >
                      {CURRENCY_ENUMS?.map((currency, index) => (
                        <MenuItem key={index} value={currency}>
                          {currency}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors?.billing_currency &&
                    !formValues?.billing_currency && (
                      <Typography variant="caption" color="error">
                        {errors?.billing_currency}
                      </Typography>
                    )}
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="billing frequency"
                      name="billing_frequency"
                      value={formValues?.billing_frequency}
                      onChange={(e) =>
                        handleChange('billing_frequency', e.target.value)
                      }
                    >
                      {Object.keys(PROJECT_BILLING_FREQUENCIES).map((key) => (
                        <FormControlLabel
                          key={key}
                          value={PROJECT_BILLING_FREQUENCIES[key]}
                          control={<Radio />}
                          label={
                            BILLING_MESSAGES[key] ||
                            PROJECT_BILLING_FREQUENCIES[key]
                          }
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  {formValues?.billing_frequency ===
                    PROJECT_BILLING_FREQUENCIES.custom && (
                    <>
                      <TextField
                        name="custom_billing_start"
                        label="Custom Billing Start"
                        variant="outlined"
                        value={
                          moment(formValues?.custom_billing_start)
                            .startOf('day')
                            .format('YYYY-MM-DD') || ''
                        }
                        fullWidth
                        style={{ marginBottom: '15px' }}
                        onChange={(e) =>
                          handleChange('custom_billing_start', e.target.value)
                        }
                      ></TextField>

                      <FormControl fullWidth>
                        <InputLabel>Custom Billing Frequency</InputLabel>
                        <Select
                          name="custom_billing_frequency"
                          label="Custom Billing Frequency"
                          value={formValues?.custom_billing_frequency || ''}
                          style={{ marginBottom: '15px' }}
                          onChange={(e) =>
                            handleChange(
                              'custom_billing_frequency',
                              e.target.value
                            )
                          }
                          displayEmpty
                          fullWidth
                        >
                          {CUSTOM_BILLING_FREQ_ENUM?.map((freq, index) => (
                            <MenuItem key={index} value={freq}>
                              {freq}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          ) : step == 2 ? (
            <>
              <Typography fontWeight={500} color="primary">
                Who you're working with
              </Typography>
              <Typography variant="subtitle2">
                Who else is working with you on {formValues?.name}?
              </Typography>
              <Card className="no-hover" variant="outlined" sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  {/* Headers */}
                  <Grid item xs={12} sm={3}>
                    <Typography textAlign="left" variant="subtitle1">
                      Name
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Typography textAlign="left" variant="subtitle1">
                      Role
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Typography textAlign="left" variant="subtitle1">
                      Engagement Type
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Typography textAlign="left" variant="subtitle1">
                      Rate
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Typography textAlign="left" variant="subtitle1">
                      Cap on Hours
                    </Typography>
                  </Grid>
                  {formValues?.members?.map((member, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={12} sm={3}>
                        <FormControl
                          fullWidth
                          error={
                            !!errors?.[`members[${index}].user`] &&
                            member.user === ''
                          }
                        >
                          <InputLabel>Member</InputLabel>
                          <Select
                            name="user"
                            required
                            value={member.user}
                            onChange={(e) => handleMemberChange(index, e)}
                            displayEmpty
                            label="Member"
                          >
                            {employees?.map((employee, i) => (
                              <MenuItem key={i} value={employee.value}>
                                {employee?.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors?.[`members[${index}].user`] &&
                            member.user === '' && (
                              <Typography variant="caption" color="error">
                                {errors?.[`members[${index}].user`]}
                              </Typography>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <FormControl
                          fullWidth
                          error={
                            !!errors?.[`members[${index}].role`] &&
                            member.role === ''
                          }
                        >
                          <InputLabel>Role</InputLabel>
                          <Select
                            name="role"
                            required
                            value={member.role}
                            onChange={(e) => handleMemberChange(index, e)}
                            displayEmpty
                            label="Role"
                          >
                            {PROJECT_MEMBERS_ROLES?.map((role) => (
                              <MenuItem key={role} value={role}>
                                {role}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors?.[`members[${index}].role`] &&
                            member.role === '' && (
                              <Typography variant="caption" color="error">
                                {errors?.[`members[${index}].role`]}
                              </Typography>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <FormControl
                          fullWidth
                          error={
                            !!errors?.[`members[${index}].engagement_type`] &&
                            member.engagement_type === ''
                          }
                        >
                          <InputLabel>Engagement Type</InputLabel>
                          <Select
                            name="engagement_type"
                            required
                            value={member.engagement_type}
                            onChange={(e) => handleMemberChange(index, e)}
                            displayEmpty
                            label="Engagement Type"
                          >
                            {ENGAGEMENT_TYPE_ENUMS?.map((type, index) => (
                              <MenuItem key={index} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors?.[`members[${index}].engagement_type`] &&
                            member.engagement_type === '' && (
                              <Typography variant="caption" color="error">
                                {errors?.[`members[${index}].engagement_type`]}
                              </Typography>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <TextField
                          error={
                            !!errors?.[`members[${index}].billing_rate`] &&
                            member.billing_rate === null
                          }
                          fullWidth
                          type="number"
                          label="Rate"
                          name="billing_rate"
                          value={member.billing_rate}
                          onChange={(e) => handleMemberChange(index, e)}
                          sx={{ mb: 1 }}
                        />
                        {errors?.[`members[${index}].billing_rate`] &&
                          member.billing_rate === null && (
                            <Typography variant="caption" color="error">
                              {errors?.[`members[${index}].billing_rate`]}
                            </Typography>
                          )}
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <TextField
                          fullWidth
                          type="number"
                          label="Hour Cap"
                          name="hour_cap"
                          value={member.hour_cap}
                          onChange={(e) => handleMemberChange(index, e)}
                          sx={{ mb: 1 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={1}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <IconButton
                          onClick={() => handleRemoveMember(index)}
                          sx={{ color: 'error.main' }}
                        >
                          <DeleteOutlined />
                        </IconButton>
                      </Grid>
                    </React.Fragment>
                  ))}
                  <Grid item xs={12}>
                    <Box
                      onClick={handleAddMember}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        color: Colors.link,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <AddBoxIcon />
                      <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        Add teammate
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </>
          ) : step === 3 &&
            formValues.billing_frequency ===
              PROJECT_BILLING_FREQUENCIES.milestone ? (
            <>
              <Typography variant="h5" fontWeight={500}>
                How you're charging
              </Typography>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography fontWeight={500} marginBottom="5px">
                  Do you have any milestones for this project? Adding them will
                  help you track progress towards them and understand projected
                  revenues and potential optimizations.
                </Typography>
              </Grid>

              <Card
                className="no-hover"
                variant="outlined"
                sx={{ p: 2, my: 4 }}
              >
                <Grid container spacing={2}>
                  {/* Headers */}
                  <Grid item xs={12} sm={4}>
                    <Typography textAlign="left" variant="subtitle1">
                      Milestone
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography textAlign="left" variant="subtitle1">
                      Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography textAlign="left" variant="subtitle1">
                      Due Date
                    </Typography>
                  </Grid>

                  {formValues?.milestone_billing?.map((member, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="Milestone"
                          name="name"
                          value={member.name}
                          onChange={(e) => handleMilestoneChange(index, e)}
                          sx={{ mb: 1 }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          type="number"
                          label="Amount"
                          name="amount"
                          value={member.amount}
                          onChange={(e) => handleMilestoneChange(index, e)}
                          sx={{ mb: 1 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          type="date"
                          name="due_date"
                          value={member.due_date}
                          onChange={(e) => handleMilestoneChange(index, e)}
                          sx={{ mb: 1 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={1}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <IconButton
                          onClick={() => handleRemoveMilestone(index)}
                          sx={{ color: 'error.main' }}
                        >
                          <DeleteOutlined />
                        </IconButton>
                      </Grid>
                    </React.Fragment>
                  ))}
                  <Grid item xs={12}>
                    <Box
                      onClick={handleAddMilestone}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        color: Colors.link,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <AddBoxIcon />
                      <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        Add milestone
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </>
          ) : (
            <>
              <Typography fontWeight={500} color="primary">
                Who your client is
              </Typography>
              <Typography variant="subtitle2" marginBottom="5px">
                Add in any details for your client that you'd like to see show
                up on your project reports or invoices
              </Typography>
              <Grid container spacing={2}>
                {/* Client Information */}

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    name="first_name"
                    variant="outlined"
                    value={formValues?.client_details?.first_name}
                    error={
                      !!errors?.client_details?.first_name &&
                      !formValues?.client_details?.first_name
                    }
                    fullWidth
                    style={{ marginBottom: '7px' }}
                    onChange={handleClientChange}
                  />
                  {errors?.client_details?.first_name &&
                    !formValues?.client_details?.first_name && (
                      <Typography variant="caption" color="error">
                        {errors?.client_details?.first_name}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    name="last_name"
                    variant="outlined"
                    error={
                      !!errors?.client_details?.last_name &&
                      !formValues?.client_details?.last_name
                    }
                    value={formValues?.client_details?.last_name}
                    fullWidth
                    style={{ marginBottom: '7px' }}
                    onChange={handleClientChange}
                  />
                  {errors?.client_details?.last_name &&
                    !formValues?.client_details?.last_name && (
                      <Typography variant="caption" color="error">
                        {errors?.client_details?.last_name}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    name="email"
                    variant="outlined"
                    error={
                      (!!errors?.client_details?.email &&
                        !formValues?.client_details?.email) ||
                      (!!formValues?.client_details?.email &&
                        !/\S+@\S+\.\S+/.test(formValues?.client_details?.email))
                    }
                    value={formValues?.client_details?.email || ''}
                    fullWidth
                    onChange={handleClientChange}
                    style={{ marginBottom: '7px' }}
                  />
                  {errors?.client_details?.email &&
                    !formValues?.client_details?.email && (
                      <Typography variant="caption" color="error">
                        {errors?.client_details?.email}
                      </Typography>
                    )}
                  {formValues?.client_details?.email &&
                    !/\S+@\S+\.\S+/.test(formValues?.client_details?.email) && (
                      <Typography variant="caption" color="error">
                        Email address is invalid
                      </Typography>
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Phone Number"
                    name="phone_number"
                    variant="outlined"
                    value={formValues?.client_details?.phone_number || ''}
                    fullWidth
                    onChange={handleClientChange}
                    style={{ marginBottom: '7px' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Company Name"
                    name="company_name"
                    error={
                      !!errors?.client_details?.company_name &&
                      !formValues?.client_details?.company_name
                    }
                    variant="outlined"
                    value={formValues?.client_details?.company_name || ''}
                    onChange={handleClientChange}
                    style={{ marginBottom: '7px' }}
                    fullWidth
                  />
                  {errors?.client_details?.company_name &&
                    !formValues?.client_details?.company_name && (
                      <Typography variant="caption" color="error">
                        {errors?.client_details?.company_name}
                      </Typography>
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Country"
                    name="country"
                    variant="outlined"
                    style={{ marginBottom: '7px' }}
                    value={formValues?.client_details?.country || ''}
                    onChange={handleClientChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Address"
                    name="address"
                    variant="outlined"
                    style={{ marginBottom: '7px' }}
                    onChange={handleClientChange}
                    value={formValues?.client_details?.address || ''}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Zip Code"
                    variant="outlined"
                    name="zip_code"
                    value={formValues?.client_details?.zip_code || ''}
                    style={{ marginBottom: '7px' }}
                    onChange={handleClientChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="8px"
        >
          {step > 0 && (
            <Button
              variant="text"
              onClick={() => setStep((s) => s - 1)}
              sx={{ mr: 'auto' }}
            >
              Back
            </Button>
          )}
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          {step === NUM_STEPS ? (
            <Button variant="contained" type="submit">
              Add Project
            </Button>
          ) : (
            <Button variant="contained" onClick={handleNextStep}>
              Next
            </Button>
          )}
        </Box>
      </form>
    </CustomDialog>
  );
}

export default AddProjectModal;
