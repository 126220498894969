const Colors = {
  primary: '#7F3FFD',
  primaryBg: '#7F3FFD35',
  border: '#e6e6e6',
  text: '#1C1D34',
  textMid: "#475467",
  tableHeader: '#F5F5F5',
  lightGray: '#F6F5F8',
  lightGray2: '#F2F1F4',
  gray: '#7A7A7A',
  link: '#3581DB',
  orange: '#ED6B42',
  success: '#377E36',
  error: '#B12F30',
  successLight: '#19B616',
  errorLight: '#EC3F36',
  successBg: '#E0F0E4',
  errorBg: '#FEECEB',
  darkBg: '#2A223C',
};

export default Colors;
