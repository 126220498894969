import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logger } from '../../Services/loggerService';

const initialState = {
  allCompanyResources: [],
  unassignedResources: [],
  loading: false,
  done: false,
  error: false,
};

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getAllCompanyResources = createAsyncThunk(
  'company/getAllCompanyResources',
  async (thunkAPI) => {
    try {
      const response = await axios.get('/company/get-resources', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      logger.error('Get all company resources', message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getUnassignedCompanyResources = createAsyncThunk(
  'company/getUnassignedCompanyResources',
  async (thunkAPI) => {
    try {
      const response = await axios.get('/company/get-unassinged-resources', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      logger.error('Get unassigned company resources', message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addNewCompanyResource = createAsyncThunk(
  'company/addNewCompanyResource',

  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/company/add-resource', data, {
        headers: {
          'Content-type': 'application/json',
        },
      });

      return response.data?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      logger.error('Add new company resource', message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteCompanyResource = createAsyncThunk(
  'company/deleteCompanyResource',

  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(
        `/company/delete-resource?_id=${id}`,
        {
          headers: {
            'Content-type': 'application/json',
          },
        }
      );
      return response?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      logger.error('Delete company resource', message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const companyResourcesSlice = createSlice({
  name: 'companyResources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCompanyResources.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCompanyResources.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.allCompanyResources = action.payload;
      })
      .addCase(getAllCompanyResources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allCompanyResources = [];
      })
      .addCase(getUnassignedCompanyResources.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUnassignedCompanyResources.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.unassignedResources = action.payload;
      })
      .addCase(getUnassignedCompanyResources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.unassignedResources = [];
      })

      .addCase(addNewCompanyResource.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewCompanyResource.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.allCompanyResources = [
          ...state.allCompanyResources,
          action.payload,
        ];
      })
      .addCase(addNewCompanyResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(deleteCompanyResource.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCompanyResource.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.allCompanyResources.data = state.allCompanyResources.filter(
          (item) => {
            return item._id !== action.payload;
          }
        );
      })

      .addCase(deleteCompanyResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default companyResourcesSlice.reducer;
