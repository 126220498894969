import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logger } from '../../Services/loggerService';

const initialState = {
  employeeHandbookFile: null,
  loading: false,
  done: false,
  error: false,
};

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const publishEmployeeHandbook = createAsyncThunk(
  'files/addhandbook',
  async (data, thunkAPI) => {
    try {
      const data2 = Object.fromEntries(data.entries());

      const response = await axios.post('/files/publish-handbook', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      logger.error('Add Empolyee handbook', message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getEmployeeHandbook = createAsyncThunk(
  'files/gethandbook',
  async (thunkAPI) => {
    try {
      const response = await axios.get('/files/get-handbook', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      logger.error('Get Empolyee handbook', message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fileSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(publishEmployeeHandbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(publishEmployeeHandbook.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.employeeHandbookFile = action.payload;
      })
      .addCase(publishEmployeeHandbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.employeeHandbookFile = [];
      })
      .addCase(getEmployeeHandbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployeeHandbook.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.employeeHandbookFile = action.payload;
      })
      .addCase(getEmployeeHandbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.employeeHandbookFile = [];
      });
  },
});

export default fileSlice.reducer;
