import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomButton from '../MiscComponents/CustomButton';
import {
  getAttendanceStatus,
  updateAttendanceStatus,
} from '../../Redux/slices/attendanceSlice';
import { toast } from 'react-toastify';
import AppRocketWorkingLog from '../WorkLogComponent/WorkLog';
import { setCheckInTime } from '../../Redux/slices/userSlice';
import { useSelector } from 'react-redux';
import { Box, Card, Typography, Button, CircularProgress } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPersonCircleXmark,
  faPersonCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import Colors from '../../Utils/Colors';
import CustomDialog from '../MiscComponents/CustomDialog';
import { logger } from '../../Services/loggerService';

const Footer = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.currentUser?.data);
  const checkInTime = useSelector((state) => state.users.checkInTime);

  const [openDialog, setOpenDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState('');
  const [workLogModalOpen, setWorkLogModalOpen] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [buttonState, setButtonState] = useState({
    text: 'Check In',
    disabled: false,
    loading: false,
  });
  const [quote, setQuote] = useState('');

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = await axios.get('/v1/quote');

        if (response.data && response.data.data) {
          const quotes = response.data.data;

          const now = new Date();
          const dayOfYear = Math.floor(
            (now - new Date(now.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24)
          );

          const quoteIndex = dayOfYear % quotes.length;
          setQuote(quotes[quoteIndex].quote);
        }
      } catch (error) {
        logger.error('Failed to fetch quotes data:', error);
      }
    };

    fetchQuotes();
    const intervalId = setInterval(fetchQuotes, 1000 * 60 * 60 * 24);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    dispatch(getAttendanceStatus())
      .unwrap()
      .then((res) => {
        const { status, checkInTime } = res.data;
        setButtonState({
          text: status === 'Checked-In' ? 'Check Out' : 'Check In',
          disabled: false,
          loading: false,
        });
        if (status === 'Checked-In') {
          // Convert checkInTime from ISO string to timestamp
          const checkInTimestamp = new Date(checkInTime).getTime();
          dispatch(setCheckInTime(checkInTimestamp));
          setIsCheckedIn(true);
        }
      })
      .catch((error) => {
        logger.error('Failed to fetch attendance status:', error);
      });
  }, [dispatch]);

  useEffect(() => {
    let interval = null;
    if (checkInTime && isCheckedIn) {
      interval = setInterval(() => {
        setElapsedTime(Date.now() - checkInTime);
      }, 1000); // Update every second
    }
    return () => clearInterval(interval);
  }, [checkInTime, isCheckedIn]);

  const formatElapsedTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    return (
      // <>
      //   {!!hours && (
      //     <>
      //       {hours}
      //       <Typography component="span">h</Typography>{' '}
      //     </>
      //    )}
      //   {minutes}
      //   <Typography component="span">m</Typography> {totalSeconds % 60}
      //   <Typography component="span">s</Typography>
      // </>
      <>
        {hours.toString().padStart(2, '0')}:
        {minutes.toString().padStart(2, '0')}:
        {(totalSeconds % 60).toString().padStart(2, '0')}
      </>
    );
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setConfirmAction(buttonState.text);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenWorkLogModal = () => {
    setWorkLogModalOpen(true);
  };

  const handleCloseWorkLogModal = () => {
    setWorkLogModalOpen(false);
  };

  const handleAttendanceAction = () => {
    setOpenDialog(false);
    setButtonState((prevState) => ({ ...prevState, loading: true }));
    const now = Date.now(); // Capture the current timestamp
    const statusToSet =
      buttonState.text === 'Check In' ? 'Checked-In' : 'Checked-Out';

    dispatch(
      updateAttendanceStatus({
        status: statusToSet,
        timestamp: now, // Include the timestamp in the payload
      })
    )
      .unwrap()
      .then(() => {
        setButtonState({
          text: statusToSet === 'Checked-Out' ? 'Check In' : 'Check Out',
          disabled: false, // Allow re-check in/out
          loading: false,
        });
        toast.success(
          `Successfully ${
            statusToSet === 'Checked-Out' ? 'checked out' : 'checked in'
          }!`
        );

        if (statusToSet === 'Checked-In') {
          dispatch(setCheckInTime(now));
          setIsCheckedIn(true);
        } else if (statusToSet === 'Checked-Out') {
          dispatch(setCheckInTime(null)); // I added here after testing, it was missing

          setIsCheckedIn(false);
          handleOpenWorkLogModal();
        }
      })
      .catch((error) => {
        logger.error('Failed to update attendance status:', error);
        setButtonState((prevState) => ({ ...prevState, loading: false }));
        toast.error('Failed to update attendance status.');
      });
  };

  const buttonIcon =
    buttonState.text === 'Check Out'
      ? faPersonCircleXmark
      : faPersonCircleCheck;
  // ? faPersonCircleCheck
  // : faPersonCircleXmark;

  return (
    <Box overflow="hidden">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        textAlign="left"
      >
        <Typography
          variant="h3"
          fontWeight={500}
          sx={{ fontSize: { xs: '1.8rem', sm: '3rem' } }}
        >
          Welcome back, {user?.first_name}! 👋
        </Typography>
        <Typography
          variant="body1"
          color="secondary.contrastText"
          mt="4px"
          mb="42px"
        >
          {quote}
        </Typography>
      </Box>

      <Card
        className="no-hover slide-up"
        sx={{
          animationDelay: '0.15s !important',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' },
          p: isCheckedIn ? { xs: '30px', md: '42px' } : 0,
          gap: { xs: '32px', md: '24px' },
          transition: 'all ease 0.4s',
          backgroundColor: isCheckedIn ? Colors.primary : 'undefined',
          color: isCheckedIn ? 'white' : Colors.text,
          borderColor: isCheckedIn ? Colors.border : 'transparent',
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={handleOpenDialog}
          disabled={buttonState.disabled || buttonState.loading}
          sx={{
            mt: isCheckedIn ? 0 : '55px',
            mx: isCheckedIn
              ? 0
              : {
                  xs: `calc(50% - ${(200 + 2 * 2) / 2}px)`,
                  sm: `calc(50% - ${(340 + 2 * 2) / 2}px)`,
                },

            minHeight: isCheckedIn ? '200px' : { xs: '200px', sm: '340px' },
            minWidth: isCheckedIn ? '200px' : { xs: '200px', sm: '340px' },
            height: isCheckedIn ? '200px' : { xs: '200px', sm: '340px' },
            width: isCheckedIn ? '200px' : { xs: '200px', sm: '340px' },

            borderRadius: '100%',
            display: 'flex',
            flexDirection: 'column',

            border: `1px solid ${Colors.primary}`,
            borderWidth: `2px !important`,
            transition: 'all ease 0.4s',

            gap: isCheckedIn ? '16px' : { xs: '16px', sm: '30px' },
            fontSize: isCheckedIn ? '18px' : { xs: '18px', sm: '30px' },
            '& svg': {
              fontSize: isCheckedIn ? '48px' : { xs: '48px', sm: '90px' },
            },

            ...(isCheckedIn
              ? {
                  backgroundColor: 'white !important',
                  color: `${Colors.primary} !important`,

                  '&:hover': {
                    backgroundColor: `${Colors.primary} !important`,
                    color: 'white !important',
                    borderColor: 'white !important',
                  },
                }
              : {
                  '&:hover': {
                    backgroundColor: 'white !important',
                    color: `${Colors.primary} !important`,
                  },
                }),
          }}
        >
          {buttonState.loading ? (
            <CircularProgress color="inherit" />
          ) : (
            <FontAwesomeIcon icon={buttonIcon} />
          )}

          {!buttonState.loading && buttonState.text}
        </Button>

        {checkInTime && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems={{ xs: 'center', md: 'flex-start' }}
          >
            <Typography
              variant="body1"
              mb="6px"
              className="slide-up-bounce"
              sx={{ animationDelay: '0.3s !important' }}
            >
              Checked in for
            </Typography>

            <Typography
              variant="h1"
              className="slide-up-bounce secondary-font"
              sx={{
                fontSize: { xs: '53px', md: '110px' },
                lineHeight: { xs: '53px', md: '110px' },
                animationDelay: '0.2s !important',
                width: { xs: '200px', md: '500px' },
                textAlign: 'start',

                '& span': {
                  fontSize: { xs: '30px', md: '36px' },
                  color: 'inherit',
                },
              }}
            >
              {formatElapsedTime(elapsedTime)}
            </Typography>
          </Box>
        )}
      </Card>

      <CustomDialog
        open={openDialog}
        onClose={handleCloseDialog}
        size="sm"
        heading="Confirm Action"
        subHeading={`Are you sure you want to ${confirmAction.toLowerCase()}?`}
      >
        <Box display="flex" justifyContent="flex-end" mt={'42px'} gap="8px">
          <CustomButton variant="outlined" onClick={handleCloseDialog}>
            Cancel
          </CustomButton>
          <CustomButton onClick={handleAttendanceAction} autoFocus>
            Confirm
          </CustomButton>
        </Box>
      </CustomDialog>

      <CustomDialog
        open={workLogModalOpen}
        onClose={handleCloseWorkLogModal}
        size="lg"
        heading={`Hey ${user.first_name}, log your work effort here.`}
      >
        <AppRocketWorkingLog onClose={handleCloseWorkLogModal} />
      </CustomDialog>
    </Box>
  );
};

export default Footer;
