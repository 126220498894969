import { Grid, TextField, Typography, Box } from '@mui/material';
import React from 'react';

function CompanyDetail({ signUpData, handleChange, validationErrors }) {
  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <Typography variant="h5" fontWeight={500} mb={2}>
          About Your Company
        </Typography>
        <Grid item xs={12}>
          <Typography fontWeight={500} mb={6}>
            Tell us a bit about your company
          </Typography>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Company Name"
            variant="outlined"
            id="company_name"
            value={signUpData?.company_name}
            onChange={(e) => handleChange('company_name', e.target.value)}
            error={!!validationErrors.company_name}
            helperText={validationErrors.company_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="URL"
            variant="outlined"
            id="last_name"
            value={signUpData?.company_url}
            onChange={(e) => handleChange('company_url', e.target.value)}
            error={!!validationErrors.company_url}
            helperText={validationErrors.company_url}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            id="company_description"
            value={signUpData?.company_description}
            onChange={(e) =>
              handleChange('company_description', e.target.value)
            }
            error={!!validationErrors.company_description}
            helperText={validationErrors.company_description}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Logo"
            variant="outlined"
            id="company_logo"
            value={signUpData?.company_logo}
            onChange={(e) => handleChange('company_logo', e.target.value)}
            error={!!validationErrors.company_logo}
            helperText={validationErrors.company_logo}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default CompanyDetail;
