import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Divider, Card } from '@mui/material';
import { ENGAGEMENT_TYPE } from '../../Utils/Constants';
import moment from 'moment';
import CustomButton from '../../Components/MiscComponents/CustomButton';
import html2pdf from 'html2pdf.js';
import { createInvoice } from '../../Redux/slices/invoiceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const PreviewInvoice = ({ data, onBack, setInvoiceData, setActiveStep }) => {
  const dispatch = useDispatch();
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const [clientDetails, setClientDetails] = useState();

  useEffect(() => {
    setClientDetails(selectedProject?.client_details);
    calculateBillingTotal();
  }, []);

  const calculateBillingTotal = () => {
    const updatedItems = data?.invoice_items.map((item) => ({
      ...item,
      billing_total: item.quantity * item.rate,
    }));
    setInvoiceData((prevData) => ({
      ...prevData,
      invoice_items: updatedItems,
    }));
  };

  const getTotalAmount = () => {
    return data?.invoice_items.reduce(
      (total, item) => total + item.billing_total,
      0
    );
  };

  const handleGenerateInvoice = async () => {
    await dispatch(createInvoice(data))
      .unwrap()
      .then((invoice) => {
        const element = document.getElementById('pdf-content');
        html2pdf(element);
        toast.success(invoice?.message);
      });
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBackClick = () => {
    onBack();
  };

  return (
    <Box sx={{ maxWidth: '724px', mx: 'auto', mt: 5 }}>
      <Card
        id="pdf-content"
        className="no-hover"
        variant="outlined"
        sx={{ p: 4 }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h5" fontWeight={500} mb="8px" textAlign="left">
              Invoice
            </Typography>
            {data?.due_date && (
              <Typography
                textAlign="left"
                variant="body2"
                fontWeight="bold"
                mb={3}
              >
                Date due: {moment(data?.due_date).format('Do MMMM, YYYY')}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <img
              src="https://via.placeholder.com/75"
              alt="Logo 1"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <img
              src="https://via.placeholder.com/150"
              alt="Logo"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Grid>
          {/* Client Information */}
          <Grid item xs={10}>
            <Typography textAlign="left" variant="h6">
              Client Information
            </Typography>
            <Typography textAlign="left" variant="body2">
              {clientDetails?.first_name} {clientDetails?.last_name}
            </Typography>
            <Typography textAlign="left" variant="body2">
              {clientDetails?.email}
            </Typography>
            <Typography textAlign="left" variant="body2">
              {clientDetails?.company_name}
            </Typography>
          </Grid>
        </Grid>
        <Typography textAlign="left" variant="h6" mt="25px">
          Items
        </Typography>
        <Typography textAlign="left" variant="body2">
          Confirm your items to send an invoice
        </Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Grid container display="flex" justifyContent="space-between">
              <Typography
                variant="subtitle1"
                textAlign="left"
                fontWeight="bold"
                sx={{ width: '53%' }}
              >
                Description
              </Typography>
              <Typography
                variant="subtitle1"
                textAlign="left"
                fontWeight="bold"
                sx={{ width: '12%' }}
              >
                Qty
              </Typography>
              <Typography
                variant="subtitle1"
                textAlign="left"
                fontWeight="bold"
                sx={{ width: '12%' }}
              >
                Rate
              </Typography>
              <Typography
                variant="subtitle1"
                textAlign="left"
                fontWeight="bold"
                sx={{ width: '12%' }}
              >
                Amount
              </Typography>
            </Grid>
          </Grid>
          {data?.invoice_items?.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12}>
                <Grid container display="flex" justifyContent="space-between">
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      textAlign="left"
                      // fontWeight="bold"
                    >
                      {item?.invoice_item} - ${item.rate}
                      {item?.engagement_type === ENGAGEMENT_TYPE.hourly
                        ? '/hr'
                        : '/item'}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">{item?.quantity}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">{item?.rate}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">
                      ${item?.billing_total}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {index !== data?.invoice_items?.length - 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))}
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <Typography variant="h6" fontWeight="bold" mt={2}>
              Total Amount: ${getTotalAmount()}
            </Typography>
          </Grid>
        </Grid>
      </Card>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <CustomButton
          type="submit"
          sx={{ mt: '32px' }}
          onClick={handleBackClick}
        >
          Back
        </CustomButton>
        <CustomButton
          type="submit"
          sx={{ mt: '32px' }}
          onClick={handleGenerateInvoice}
        >
          Generate Invoice
        </CustomButton>
      </Box>
    </Box>
  );
};

export default PreviewInvoice;
