import { useNavigate } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {
  EMPLOYEE_BASE_URL,
  EMPLOYEE_PERFORMANCE_NEW_APPRAISAL,
  EMPLOYEE_OFFBOARDING_DETAILS,
} from '../../Routes/route_constants';

const EmployeeTable = ({ employee, handleClick, targetComponent }) => {
  const navigate = useNavigate();

  const handleRowClick = () => {
    if (handleClick) {
      handleClick(employee);
    } else if (targetComponent === 'employeeDetails') {
      navigate(`${EMPLOYEE_BASE_URL}/${employee?._id}`);
    } else if (targetComponent === 'appraisalDetails') {
      navigate(`${EMPLOYEE_PERFORMANCE_NEW_APPRAISAL}/${employee?._id}`);
    } else if (targetComponent === 'offBoarding') {
      navigate(`${EMPLOYEE_OFFBOARDING_DETAILS}/${employee?._id}`);
    }
  };

  const renderTableCells = () => {
    switch (targetComponent) {
      case 'employeeDetails':
        return (
          <>
            <TableCell component="th" scope="row">
              {employee?.first_name} {employee?.last_name}
            </TableCell>
            <TableCell align="left">{employee?.email}</TableCell>
            <TableCell align="left">{employee?.employee_type}</TableCell>
            <TableCell align="left">{employee?.job_title}</TableCell>
            <TableCell align="left">{employee?.bank_account_number}</TableCell>
            <TableCell align="left">{employee?.salary}</TableCell>
          </>
        );
      case 'appraisalDetails':
      case 'reimbursementDetails': // Since both have the same structure
        return (
          <>
            <TableCell component="th" scope="row">
              {employee?.first_name} {employee?.last_name}
            </TableCell>
            <TableCell align="left">{employee?.email}</TableCell>
            <TableCell align="left">{employee?.employee_type}</TableCell>
          </>
        );
      default:
        return (
          <TableCell component="th" scope="row" align="left">
            {employee?.first_name} {employee?.last_name}
          </TableCell>
        );
    }
  };

  return (
    <TableRow
      sx={{
        cursor: 'pointer',
        '&:last-child td, &:last-child th': { border: 0 },
      }}
      onClick={handleRowClick}
    >
      {renderTableCells()}
    </TableRow>
  );
};

export default EmployeeTable;
