import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLeaves, updateLeave } from '../../Redux/slices/leaveSlice';
import moment from 'moment';
import Box from '@mui/material/Box';
// import DynamicBackButton from '../../Components/BackButton/DynamicBackButton';
import { Typography } from '@mui/material';
import CustomTable from '../../Components/MiscComponents/CustomTable';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import CustomButton from '../../Components/MiscComponents/CustomButton';
import ContentWrapper from '../../Components/MiscComponents/ContentWrapper';
import Colors from '../../Utils/Colors';
import { Close, Done } from '@mui/icons-material';
import { formatDate } from '../../Utils/functions';
import { logger } from '../../Services/loggerService';

const headers = [
  { label: 'Employee Name', keys: ['user.first_name', 'user.last_name'] },
  { label: 'Type', key: 'type' },
  {
    label: 'Start Date',
    key: 'start_date',
    format: formatDate,
  },
  {
    label: 'End Date',
    key: 'end_date',
    format: formatDate,
  },
  { label: 'Duration', key: 'duration' },
];

const LeaveRequests = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.leaves.loading);
  const leavesResponse = useSelector((state) => state.leaves.allLeaves);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const leaveUpdateStatus = useSelector((state) => state.leaves.updateStatus);

  const [selectedLeave, setSelectedLeave] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');

  useEffect(() => {
    dispatch(
      getAllLeaves({
        filters: { status: 'Pending' },
        page: currentPage,
        limit: perPage,
      })
    );
  }, [dispatch, currentPage, perPage, leaveUpdateStatus]);

  const handleRowClick = (leave) => {
    setSelectedLeave(leave);
    setDialogTitle('Leave Details');
    setDialogContent(
      <Box
        display="flex"
        flexDirection="column"
        gap="8px"
        sx={{
          '& span': { mr: '4px', fontWeight: 400, color: Colors.textMid },
        }}
      >
        <Typography fontWeight={500} variant="body1">
          <span>Employee Name:</span>{' '}
          {leave.user.first_name && leave.user.last_name
            ? `${leave.user.first_name} ${leave.user.last_name}`
            : '_'}
        </Typography>
        <Typography fontWeight={500} variant="body1">
          <span>Type:</span> {leave.type}
        </Typography>
        <Typography fontWeight={500} variant="body1">
          <span>Start Date:</span> {formatDate(leave.start_date)}
        </Typography>
        <Typography fontWeight={500} variant="body1">
          <span>End Date:</span>{' '}
          {leave.end_date ? formatDate(leave.end_date) : 'N/A'}
        </Typography>
        <Typography fontWeight={500} variant="body1">
          <span>Duration:</span> {leave.duration}
        </Typography>
        <Typography fontWeight={500} variant="body1">
          <span>Status:</span> {leave.status}
        </Typography>
        <Typography fontWeight={500} variant="body1">
          <span>Reason:</span> {leave.reason || 'N/A'}
        </Typography>
      </Box>
    );
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleApproveLeave = () => {
    updateLeaveStatus('Approved');
  };

  const handleRejectLeave = () => {
    updateLeaveStatus('Rejected');
  };

  const updateLeaveStatus = (status) => {
    if (selectedLeave) {
      dispatch(updateLeave({ id: selectedLeave.id, status }))
        .then(() => {
          dispatch(
            getAllLeaves({
              filters: { status: 'Pending' },
              page: currentPage,
              limit: perPage,
            })
          );
          setIsDialogOpen(false);
        })
        .catch((error) => {
          logger.error('Failed to update leave status:', error);
        });
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <ContentWrapper heading={'Pending Leave Requests'} isLoading={loading}>
        <CustomTable
          headers={headers}
          data={leavesResponse?.data}
          handleRowClick={handleRowClick}
          totalPages={leavesResponse?.metadata?.totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          emptyStateMessage="No pending leaves currently"
        />

        <CustomDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          heading={dialogTitle}
        >
          <Box mt="24px">{dialogContent}</Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap="8px"
            mt="32px"
          >
            <CustomButton
              onClick={handleRejectLeave}
              variant="outlined"
              endIcon={<Close />}
            >
              Reject
            </CustomButton>{' '}
            <CustomButton
              onClick={handleApproveLeave}
              variant="contained"
              endIcon={<Done />}
            >
              Approve
            </CustomButton>
          </Box>
        </CustomDialog>
      </ContentWrapper>
    </>
  );
};

export default LeaveRequests;
