import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import CustomButton from '../../Components/MiscComponents/CustomButton';
import {
  getAllProjects,
  updateSelectedProject,
} from '../../Redux/slices/projectSlice';
import { PROJECT_BILLING_FREQUENCIES } from '../../Utils/Constants';

const NewInvoice = ({ data, onChange, onNext, loading }) => {
  const dispatch = useDispatch();

  const projectsResponse = useSelector((state) => state.projects.allProjects);
  const [selectedProject, setSelectedProject] = useState();
  const [projectBillingFrequency, setProjectBillingFrequency] = useState();
  const [projectMilestones, setProjectMilestones] = useState(null);

  useEffect(() => {
    if (!projectsResponse || !projectsResponse?.length) {
      dispatch(getAllProjects());
    }
  }, []);

  useEffect(() => {
    if (selectedProject) {
      dispatch(updateSelectedProject(selectedProject));
      setProjectBillingFrequency(selectedProject?.billing_frequency);
      setProjectMilestones(selectedProject?.milestone_billing);
    }
  }, [selectedProject]);

  const handleNextClick = () => {
    onNext();
  };

  const isButtonDisabled =
    !data?.projectId ||
    !data.start_date ||
    data.end_date < data.start_date ||
    (projectBillingFrequency === PROJECT_BILLING_FREQUENCIES.milestone &&
      !data.milestoneId);

  const buttonOpacity = isButtonDisabled ? 0.5 : 1;

  return (
    <>
      {/* <DynamicBackButton />  */}
      <Box sx={{ maxWidth: '724px', mx: 'auto', mt: 5 }}>
        <Typography variant="h5" fontWeight={500} mb="8px" textAlign="left">
          Create Invoice
        </Typography>
        <Typography textAlign="left" variant="body2" mb="25px">
          Please select a project to create an invoice
        </Typography>
        <FormControl fullWidth sx={{ mb: 1 }}>
          <InputLabel>Project</InputLabel>
          <Select
            name="project"
            value={
              data?.projectId
                ? projectsResponse.find(
                    (project) => project?.id === data?.projectId
                  )
                : ''
            }
            label="Project"
            onChange={(e) => {
              setSelectedProject(e.target.value);
              onChange((prev) => ({
                ...prev,
                projectId: e.target.value?.id,
              }));
            }}
          >
            {projectsResponse?.map((project) => (
              <MenuItem key={project?.id} value={project}>
                {project?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Grid container spacing="18px">
          <Grid item xs={12} mt="8px">
            {projectBillingFrequency ===
              PROJECT_BILLING_FREQUENCIES.milestone && (
              <FormControl fullWidth variant="outlined">
                <InputLabel>{'Select Milestone'}</InputLabel>
                <Select
                  value={data?.milestoneId}
                  name="milestoneId"
                  onChange={(e) => {
                    onChange((prev) => ({
                      ...prev,
                      milestoneId: e.target.value,
                    }));
                  }}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Status Filter' }}
                  size="small"
                  sx={{ width: '100%' }}
                  label={'Select Milestone'}
                >
                  {projectMilestones?.map((opt, idx2) => (
                    <MenuItem key={idx2} value={opt._id}>
                      {opt.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>

          <Grid item xs={12} mt="8px">
            <DatePicker
              label="Start Date"
              value={data?.start_date}
              name="start_date"
              onChange={(date) => {
                onChange((prev) => ({
                  ...prev,
                  start_date: date,
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth required />
              )}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} mt="8px">
            <DatePicker
              label="End Date"
              value={data?.end_date}
              name="end_date"
              onChange={(date) => {
                onChange((prev) => ({
                  ...prev,
                  end_date: date,
                }));
              }}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} fullWidth required />
              )}
            />
          </Grid>
        </Grid>

        <CustomButton
          type="submit"
          fullWidth
          disabled={isButtonDisabled}
          isSaving={loading}
          sx={{ mt: '32px', opacity: buttonOpacity }}
          onClick={handleNextClick}
        >
          Next
        </CustomButton>
      </Box>
    </>
  );
};

export default NewInvoice;
