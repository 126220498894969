import React from 'react';
import { Box } from '@mui/material';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import CustomButton from '../../Components/MiscComponents/CustomButton';

const DeleteProjectDialog = ({ open, onClose, onDelete }) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      heading="Confirm Deletion"
      subHeading="Are you sure you want to delete this project?"
    >
      <Box display="flex" justifyContent="flex-end" mt={'42px'} gap="8px">
        <CustomButton variant="outlined" onClick={onClose}>
          Cancel
        </CustomButton>
        <CustomButton onClick={onDelete} autoFocus>
          Delete
        </CustomButton>
      </Box>
    </CustomDialog>
  );
};

export default DeleteProjectDialog;
