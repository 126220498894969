import React from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function OnboardingCompleted({ handleDashboardNavigation }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minWidth="100%"
    >
      <Paper square elevation={0} sx={{ p: 3, textAlign: 'center' }}>
        <CheckCircleOutlineIcon color="primary" sx={{ fontSize: 50, mb: 2 }} />
        <Typography variant="h5" gutterBottom>
          You're all set
        </Typography>
        <Button onClick={handleDashboardNavigation} sx={{ mt: 1, mr: 1 }}>
          Go to Dashboard
        </Button>
      </Paper>
    </Box>
  );
}

export default OnboardingCompleted;
