import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLeaves } from '../../Redux/slices/leaveSlice';
import { useNavigate } from 'react-router-dom';
import { EMPLOYEE_SUBMIT_LEAVE } from '../../Routes/route_constants';
import CustomTable from '../../Components/MiscComponents/CustomTable';
import ContentWrapper from '../../Components/MiscComponents/ContentWrapper';
import { formatDate } from '../../Utils/functions';
import Colors from '../../Utils/Colors';
import { Box, Typography } from '@mui/material';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import moment from 'moment';


const headers = [
  { label: 'Employee Name', keys: ['user.first_name', 'user.last_name'] },
  { label: 'Type', key: 'type' },
  {
    label: 'Start Date',
    key: 'start_date',
    format: formatDate,
  },
  {
    label: 'End Date',
    key: 'end_date',
    format: formatDate,
  },
  { label: 'Duration', key: 'duration' },
  { label: 'Status', key: 'status' },
];

const LeaveRecords = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.leaves.loading);
  const leavesResponse = useSelector((state) => state.leaves.allLeaves);
  const [currentPage, setCurrentPage] = useState(1);
  const metadata = useSelector((state) => state.leaves.metadata);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [dialogContent, setDialogContent] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    dispatch(
      getAllLeaves({ page: currentPage, limit: 10, status: selectedStatus })
    );
  }, [dispatch, currentPage, selectedStatus]);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setCurrentPage(1);
  };

  const handleRowClick = (leave) => {
    setIsDialogOpen(true)
    setDialogContent(
      <Box
        display="flex"
        flexDirection="column"
        gap="8px"
        sx={{
          '& span': { mr: '4px', fontWeight: 400, color: Colors.textMid },
        }}
      >
        <Typography fontWeight={500} variant="body1">
          <span>Employee Name:</span>{' '}
          {leave.user.first_name && leave.user.last_name
            ? `${leave.user.first_name} ${leave.user.last_name}`
            : '_'}
        </Typography>
        <Typography fontWeight={500} variant="body1">
          <span>Type:</span> {leave.type}
        </Typography>
        <Typography fontWeight={500} variant="body1">
          <span>Start Date:</span>{' '}
          {moment(leave.start_date).format('YYYY-MM-DD')}
        </Typography>
        <Typography fontWeight={500} variant="body1">
          <span>End Date:</span>{' '}
          {leave.end_date ? moment(leave.end_date).format('YYYY-MM-DD') : 'N/A'}
        </Typography>
        <Typography fontWeight={500} variant="body1">
          <span>Duration:</span> {leave.duration}
        </Typography>
        <Typography fontWeight={500} variant="body1">
          <span>Status:</span> {leave.status}
        </Typography>
        <Typography fontWeight={500} variant="body1">
          <span>Reason:</span> {leave.reason || 'N/A'}
        </Typography>
      </Box>
    );
  };

  const handleModalClose = () => {
    setIsDialogOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleClearFilters = () => {
    setSelectedStatus('');
  };

  
  return (
    <ContentWrapper
      heading={'Leave Records'}
      isLoading={loading}
      addButtonText="Request Leave"
      addButtonOnClick={() => navigate(EMPLOYEE_SUBMIT_LEAVE)}
      hideAddIcon
      onClearFilters={handleClearFilters}
      filters={[
        {
          type: 'dropdown',
          value: selectedStatus,
          onChange: handleStatusChange,
          options: [
            { value: '', text: 'All Statuses' },
            { value: 'Pending', text: 'Pending' },
            { value: 'Approved', text: 'Approved' },
            { value: 'Rejected', text: 'Rejected' },
          ],
        },
      ]}
    >
      <CustomTable
        headers={headers}
        data={leavesResponse?.data}
        handleRowClick={handleRowClick}
        totalPages={metadata?.totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        emptyStateMessage="No leave records found"
      />
      <CustomDialog
        open={isDialogOpen}
        onClose={handleModalClose}
        heading={"Leave Details"}
      >
        <Box mt="24px">{dialogContent}</Box>
      </CustomDialog>
    </ContentWrapper>
  );
};

export default LeaveRecords;
