import React from 'react';
import InvoiceItems from '../../Pages/Invoices/InvoiceItems';
import NewInvoice from '../../Pages/Invoices/NewInvoice';
import PreviewInvoice from '../../Pages/Invoices/PreviewInvoice';
import ReturnToInvoices from '../../Pages/Invoices/ReturnToInvoices';

const InvoiceSteps = ({
  step,
  data,
  loading,
  error,
  onChange,
  onNext,
  setInvoiceData,
  setActiveStep,
  onBack,
}) => {
  switch (step) {
    case 0:
      return (
        <NewInvoice
          data={data}
          loading={loading}
          error={error}
          onChange={onChange}
          onNext={onNext}
        />
      );
    case 1:
      return (
        <InvoiceItems
          data={data}
          onChange={onChange}
          setActiveStep={setActiveStep}
          onBack={onBack}
        />
      );
    case 2:
      return (
        <PreviewInvoice
          data={data}
          onBack={onBack}
          setInvoiceData={setInvoiceData}
          setActiveStep={setActiveStep}
        />
      );
    case 3:
      return (
        <ReturnToInvoices />
      )
    default:
      return null;
  }
};

export default InvoiceSteps;
