import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Box,
  Typography,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { createNewUser } from '../../Redux/slices/userSlice';
import { getCurrentUser } from '../../Redux/slices/authSlice';
import { fetchDepartments } from '../../Redux/slices/departmentSlice';
import { toast } from 'react-toastify';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import { logger } from '../../Services/loggerService';

import {
  USER_ORG_ROLE_ENUMS,
  CURRENCY_ENUMS,
  CURRENT_EMPLOYEMENT_STATUS,
} from '../../Utils/Constants';

const UserRegistrationForm = ({ open, onClose, onUserCreated }) => {
  const dispatch = useDispatch();
  const user_organization = useSelector(
    (state) => state.auth.currentUser.data.active_organization
  );
  const departments = useSelector(
    (state) => state.departments?.departments?.data?.departments
  );

  useEffect(() => {
    dispatch(fetchDepartments(user_organization));
  }, []);

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    contact_number: '',
    secondary_contact_number: '',
    current_address: '',
    permanent_address: '',
    emergency_contact_name: '',
    emergency_contact_number: '',
    date_of_birth: '',
    national_id_number: '',
    organizationData: {
      role: '',
      employee_code: '',
      departments: [],
      job_start_date: '',
      job_title: '',
      job_end_date: '',
      salary_amount: '',
      salary_currency: '',
      bank_name: '',
      bank_account_title: '',
      bank_account_number: '',
      employment_status: '',
    },
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('organizationData.')) {
      const fieldName = name.split('.')[1];
      setFormData((prevFormData) => ({
        ...prevFormData,
        organizationData: {
          ...prevFormData.organizationData,
          [fieldName]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleDepartmentChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData((prevFormData) => ({
      ...prevFormData,
      organizationData: {
        ...prevFormData.organizationData,
        departments: typeof value === 'string' ? value.split(',') : value,
      },
    }));
  };

  const validateForm = () => {
    let newErrors = {};

    // Validating required fields
    if (!formData.first_name) newErrors.first_name = 'First name is required';
    if (!formData.last_name) newErrors.last_name = 'Last name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = 'Email address is invalid';
    if (!formData.organizationData.employee_code)
      newErrors.organizationData_employee_code = 'Employee code is required';
    if (!formData.organizationData.role)
      newErrors.organizationData_role = 'Role is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    if (step === 0) {
      const isFormValid = validateForm();
      if (!isFormValid) return;
      setStep((currentStep) => currentStep + 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    const isFormValid = validateForm();
    if (!isFormValid) return; // Stop submission if validation fails
    // Prepare formData for submission
    const submissionData = { ...formData };
    if (submissionData.organizationData.salary_currency === '') {
      delete submissionData.organizationData.salary_currency; // Or remove the key
    }
    if (submissionData.organizationData.employment_status === '') {
      submissionData.organizationData.employment_status = null; // Or remove the key
    }

    try {
      const response = await dispatch(createNewUser(formData)).unwrap();
      toast.success('User created successfully!');
      onUserCreated();

      // Reset the form and errors after successful submission
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        contact_number: '',
        secondary_contact_number: '',
        current_address: '',
        permanent_address: '',
        emergency_contact_name: '',
        emergency_contact_number: '',
        date_of_birth: '',
        national_id_number: '',
        organizationData: {
          role: '',
          employee_code: '',
          departments: [],
          job_start_date: '',
          job_title: '',
          job_end_date: '',
          salary_amount: '',
          salary_currency: '',
          bank_name: '',
          bank_account_title: '',
          bank_account_number: '',
          employment_status: '',
        },
      });
      setErrors({});
      onClose(); // Close the dialog
      setStep(0); // Reset to the initial step
    } catch (error) {
      toast.error(
        `Error creating new user: ${
          error.message || 'An unknown error occurred'
        }`
      );
      logger.error('Error creating new user:', error);
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      size="lg"
      heading="Add New Employee"
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} mt={'12px'} mb={'24px'}>
          {step === 0 ? (
            <>
              {/* First Step Fields */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  name="first_name"
                  required
                  value={formData.first_name}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.first_name}
                  helperText={errors.first_name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  name="last_name"
                  required
                  value={formData.last_name}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.last_name}
                  helperText={errors.last_name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email Address"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth error={!!errors.organizationData_role}>
                  <InputLabel>Role</InputLabel>
                  <Select
                    name="organizationData.role"
                    required
                    value={formData.organizationData.role}
                    onChange={handleChange}
                    displayEmpty
                    label="Role"
                  >
                    <MenuItem value=""></MenuItem>
                    {USER_ORG_ROLE_ENUMS?.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.organizationData_role && (
                    <Typography
                      color="error"
                      mt="3px"
                      ml="14px"
                      fontSize="0.75rem"
                    >
                      {errors.organizationData_role}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              {departments && departments.length > 0 && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Departments</InputLabel>
                    <Select
                      multiple
                      name="organizationData.departments"
                      value={formData.organizationData.departments}
                      onChange={handleDepartmentChange}
                      renderValue={(selected) => selected.join(', ')}
                      label="Departments"
                    >
                      {departments.map((department) => (
                        <MenuItem key={department.id} value={department.name}>
                          <Checkbox
                            checked={
                              formData.organizationData.departments.indexOf(
                                department.name
                              ) > -1
                            }
                          />
                          <ListItemText primary={department.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  label="Employee Code"
                  required
                  name="organizationData.employee_code"
                  value={formData.organizationData.employee_code}
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.organizationData_employee_code}
                  helperText={errors.organizationData_employee_code || ''} // Show error message
                />
              </Grid>
            </>
          ) : (
            <>
              {/* Second Step Fields */}
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight={500}>
                  Contact Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Contact Number"
                  name="contact_number"
                  value={formData.contact_number}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Secondary Contact Number"
                  name="secondary_contact_number"
                  value={formData.secondary_contact_number}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Current Address"
                  name="current_address"
                  value={formData.current_address}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Permanent Address"
                  name="permanent_address"
                  value={formData.permanent_address}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight={500}>
                  Personal Details
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Date of Birth"
                  name="date_of_birth"
                  type="date"
                  value={formData.date_of_birth}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="National ID Number"
                  name="national_id_number"
                  value={formData.national_id_number}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight={500}>
                  Emergency Contact
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Emergency Contact Name"
                  name="emergency_contact_name"
                  value={formData.emergency_contact_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Emergency Contact Number"
                  name="emergency_contact_number"
                  value={formData.emergency_contact_number}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              {/* Organization-specific Data Fields */}
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight={500}>
                  Role Details
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Job Title"
                  name="organizationData.job_title"
                  value={formData.organizationData.job_title}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Employment Status</InputLabel>
                  <Select
                    name="organizationData.employment_status"
                    value={formData.organizationData.employment_status}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value=""></MenuItem>
                    {CURRENT_EMPLOYEMENT_STATUS?.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Job Start Date"
                  name="organizationData.job_start_date"
                  type="date"
                  value={formData.organizationData.job_start_date}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Job End Date"
                  name="organizationData.job_end_date"
                  type="date"
                  value={formData.organizationData.job_end_date}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Salary Amount"
                  name="organizationData.salary_amount"
                  type="number"
                  value={formData.organizationData.salary_amount}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Salary Currency</InputLabel>
                  <Select
                    name="organizationData.salary_currency"
                    value={formData.organizationData.salary_currency}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value=""></MenuItem>
                    {CURRENCY_ENUMS?.map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight={500}>
                  Financial Account Details
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Bank Name"
                  name="organizationData.bank_name"
                  value={formData.organizationData.bank_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Bank Account Title"
                  name="organizationData.bank_account_title"
                  value={formData.organizationData.bank_account_title}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Bank Account Number"
                  name="organizationData.bank_account_number"
                  value={formData.organizationData.bank_account_number}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="8px"
        >
          {step > 0 && (
            <Button
              variant="text"
              onClick={() => setStep((s) => s - 1)}
              sx={{ mr: 'auto' }}
            >
              Back
            </Button>
          )}
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          {step === 0 ? (
            <Button variant="contained" onClick={handleNextStep}>
              Next
            </Button>
          ) : (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
        </Box>
      </form>
    </CustomDialog>
  );
};

export default UserRegistrationForm;
