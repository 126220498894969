import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { useState } from 'react';
import LayoutHeader from '../../Components/PageLayout/LayoutHeader';
import daftarLogo from '../../Assets/images/daftar-logo.png';
import { toast } from 'react-toastify';

import {
  DRAWER_WIDTH,
  ONBOARDING_STEPS,
  PROJECT_BILLING_FREQUENCIES,
  PROJECT_MEMBERS_ROLE_ENUMS,
  DRAWER_WIDTH as drawerWidth,
} from '../../Utils/Constants';
import LayoutSpacing from '../../Utils/LayoutSpacing';
import StepOne from './StepOne';
import {
  Button,
  Drawer,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import Colors from '../../Utils/Colors';
import { ERROR_MESSAGES } from '../../Utils/ValidationErrors';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  onboardingNewOrganization,
  updateOnboarding,
} from '../../Redux/slices/onboardingSlice';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import OnboardingCompleted from './OnboardingCompleted';
import Loader from '../../Components/MiscComponents/Loader';

const OnboardingMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [currentSubStep, setCurrentSubStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [validationErrors, setValidationErrors] = useState({});
  const [onboardingData, setOnboardingData] = useState({
    members: [],
  });

  const loading = useSelector((state) => state.onboarding.loading);

  const handleDrawerToggle = (state) =>
    setMobileOpen(typeof state !== 'undefined' ? state : !mobileOpen);

  const [showSubStep, setShowSubStep] = useState(false);

  const options = ['Logout'];

  const handleChange = (name, value) => {
    setOnboardingData((prev) => {
      let updatedOnboardingData = { ...prev };

      if (name === 'billing_frequency') {
        setShowSubStep(value === PROJECT_BILLING_FREQUENCIES.milestone);
        updatedOnboardingData.milestone_billing = [];
      }

      updatedOnboardingData[name] = value;

      return updatedOnboardingData;
    });
  };

  const handleSubmitOnboarding = () => {
    setIsLoading(true);
    if (validateStep(activeStep)) {
      dispatch(onboardingNewOrganization(onboardingData))
        .unwrap()
        .then(() => {
          setIsLoading(false);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        });
    }
  };

  const validateStep = (step) => {
    let errors = {};
    switch (step) {
      case 0:
        if (!onboardingData.project_name) {
          errors.project_name = ERROR_MESSAGES.PROJECT_NAME_REQUIRED;
        }
        if (
          !onboardingData.billing_currency &&
          onboardingData.billing_frequency !== PROJECT_BILLING_FREQUENCIES.none
        ) {
          errors.billing_currency = ERROR_MESSAGES.BILLING_CURRENCY_REQUIRED;
        }
        if (!onboardingData.billing_frequency) {
          errors.billing_frequency = ERROR_MESSAGES.BILLING_FREQUENCY_REQUIRED;
        }
        break;
      case 1:
        if (!onboardingData?.members || onboardingData?.members?.length === 0) {
          errors.members = ERROR_MESSAGES.AT_LEAST_ONE_MEMBER_REQUIRED;
        } else {
          onboardingData.members.forEach((member, index) => {
            if (!member.first_name)
              errors[`members.[${index}].first_name`] =
                ERROR_MESSAGES.FIRST_NAME_REQUIRED;

            if (!member.last_name) {
              errors[`members.[${index}].last_name`] =
                ERROR_MESSAGES.LAST_NAME_REQUIRED;
            }
            if (!member.email) {
              errors[`members.[${index}].email`] =
                ERROR_MESSAGES.EMAIL_REQUIRED;
            } else if (!/\S+@\S+\.\S+/.test(member?.email)) {
              errors[`members.[${index}].email`] = ERROR_MESSAGES.EMAIL_INVALID;
            }
            if (!member.project_role) {
              errors[`members.[${index}].project_role`] =
                ERROR_MESSAGES.MEMBER_ROLE_REQUIRED;
            }
          });
        }
        break;
      case 2:
        onboardingData.members.forEach((member, index) => {
          if (!member.engagement_type)
            errors[`members.[${index}].engagement_type`] =
              ERROR_MESSAGES.MEMBER_ENGAGEMENT_TYPE_REQUIRED;
          if (!member.billing_rate)
            errors[`members.[${index}].billing_rate`] =
              ERROR_MESSAGES.MEMBER_BILLING_RATE_REQUIRED;
        });
        break;
      case ONBOARDING_STEPS.length - 1:
        if (!onboardingData?.client_details?.first_name)
          errors.client_details = {
            ...errors.client_details,
            first_name: ERROR_MESSAGES.FIRST_NAME_REQUIRED,
          };
        if (!onboardingData?.client_details?.last_name)
          errors.client_details = {
            ...errors.client_details,
            last_name: ERROR_MESSAGES.LAST_NAME_REQUIRED,
          };
        if (!onboardingData?.client_details?.email)
          errors.client_details = {
            ...errors.client_details,
            email: ERROR_MESSAGES.EMAIL_REQUIRED,
          };
        else if (!/\S+@\S+\.\S+/.test(onboardingData?.client_details?.email))
          errors.client_details = {
            ...errors.client_details,
            email: ERROR_MESSAGES.EMAIL_INVALID,
          };
        if (!onboardingData?.client_details?.company_name)
          errors.client_details = {
            ...errors.client_details,
            company_name: ERROR_MESSAGES.CLIENT_COMPANY_NAME_REQUIRED,
          };
        break;
      default:
        break;
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(activeStep)) {
      if (activeStep === 2 && currentSubStep === 0 && showSubStep) {
        setCurrentSubStep((prevCurrentSubstep) => prevCurrentSubstep + 1);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep == 2 && currentSubStep == 1 && showSubStep) {
      setCurrentSubStep((prevCurrentSubstep) => prevCurrentSubstep - 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleDashboardNavigation = () => {
    // Update the onboarding status

    dispatch(updateOnboarding())
      .unwrap()
      .then(() => {
        navigate('/dashboard');
      })
      .catch((error) => {
        // setLoading(false);
        toast.error(`Error: ${error.message}`);
      });
  };

  const handleMemberChange = (index, e) => {
    let newMember = [...onboardingData?.members];
    let updatedMember = { ...newMember[index] };
    updatedMember[e.target.name] = e.target.value;

    // If "Project Manager" is selected for one member, disable it for all other members
    if (
      e.target.name === 'project_role' &&
      e.target.value === PROJECT_MEMBERS_ROLE_ENUMS.projectManager
    ) {
      newMember.forEach((member, i) => {
        if (
          i !== index &&
          member.project_role == PROJECT_MEMBERS_ROLE_ENUMS.projectManager
        ) {
          member.project_role = '';
        }
      });
    }
    newMember[index] = updatedMember;

    setOnboardingData((prev) => ({
      ...prev,
      members: newMember,
    }));
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StepOne
            handleChange={handleChange}
            onboardingData={onboardingData}
            validationErrors={validationErrors}
          />
        );
      case 1:
        return (
          <StepTwo
            handleMemberChange={handleMemberChange}
            onboardingData={onboardingData}
            setOnboardingData={setOnboardingData}
            validationErrors={validationErrors}
          />
        );
      case 2:
        return (
          <StepThree
            currentSubStep={currentSubStep}
            showSubStep={showSubStep}
            handleMemberChange={handleMemberChange}
            onboardingData={onboardingData}
            setOnboardingData={setOnboardingData}
            validationErrors={validationErrors}
          />
        );

      case 3:
        return (
          <StepFour
            onboardingData={onboardingData}
            setOnboardingData={setOnboardingData}
            validationErrors={validationErrors}
          />
        );
      case 4:
        return (
          <OnboardingCompleted
            handleDashboardNavigation={handleDashboardNavigation}
          />
        );
      default:
        return;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {activeStep !== 4 && (
        <>
          <LayoutHeader
            handleSidedrawer={handleDrawerToggle}
            options={options}
            disableHamBurger={true}
          />

          <Box
            component="nav"
            sx={{
              width: { sm: DRAWER_WIDTH },
              flexShrink: { sm: 0 },
            }}
            aria-label="mailbox folders"
          >
            <Drawer
              open
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: DRAWER_WIDTH,
                },
              }}
            >
              <Box
                sx={{
                  borderRight: `1px solid ${Colors.border}`,
                  borderRadius: LayoutSpacing.headerBorderRadius,
                  minHeight: '100%',
                  height: 'max-content',
                  overflowY: 'auto',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                }}
              >
                <Toolbar sx={{ justifyContent: 'center', pt: '12px' }}>
                  <Box
                    component="img"
                    alt="Logo"
                    src={daftarLogo}
                    sx={{ height: '35px', cursor: 'pointer' }}
                  />
                </Toolbar>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 10,
                  }}
                >
                  <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    sx={{
                      '& .MuiStepIcon-text': {
                        fill: '#FFF',
                      },
                    }}
                  >
                    {ONBOARDING_STEPS?.map((step, index) => (
                      <Step key={step}>
                        <StepLabel>{step}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Box>
            </Drawer>
          </Box>
        </>
      )}

      <Box
        component="main"
        sx={(theme) => ({
          p: LayoutSpacing.pagePadding,

          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          [theme.breakpoints.down('sm')]: {
            p: LayoutSpacing.pagePaddingSm,
            pt: '32px',
          },
        })}
      >
        {isLoading ? (
          <Loader height="60vh" />
        ) : (
          <>
            <Toolbar />
            {getStepContent(activeStep)}
            {activeStep !== 4 && (
              <Box
                sx={(theme) => ({
                  my: 3,
                  px: 4,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    px: 2,
                  },
                })}
              >
                <Typography
                  variant="body2"
                  color="primary"
                  sx={(theme) => ({
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    [theme.breakpoints.down('sm')]: {
                      mb: 2,
                    },
                  })}
                  onClick={handleDashboardNavigation}
                >
                  no thanks, I can find my own way
                </Typography>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'row',
                    [theme.breakpoints.down('sm')]: {
                      width: '100%',
                      justifyContent: 'space-between',
                    },
                  })}
                >
                  {activeStep === ONBOARDING_STEPS.length - 1 ? (
                    <Button
                      variant="contained"
                      onClick={handleSubmitOnboarding}
                      sx={(theme) => ({
                        mt: 1,
                        mr: 1,
                        [theme.breakpoints.down('sm')]: {
                          size: 'small',
                          fontSize: '0.875rem',
                        },
                      })}
                    >
                      Finish
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={(theme) => ({
                        mt: 1,
                        mr: 1,
                        [theme.breakpoints.down('sm')]: {
                          size: 'small',
                          fontSize: '0.875rem',
                        },
                      })}
                    >
                      Continue
                    </Button>
                  )}
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={(theme) => ({
                      mt: 1,
                      mr: 1,
                      [theme.breakpoints.down('sm')]: {
                        size: 'small',
                        fontSize: '0.875rem',
                      },
                    })}
                  >
                    Back
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default OnboardingMain;
