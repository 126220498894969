import React from 'react';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { borderRadius } from '../../Utils/LayoutSpacing';

/**
 * @param {{
 * size: "sm" | "md" | "lg" ;
 * }} size of dialog
 */

const sizes = {
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
};

const sizeWidths = {
  sm: 560,
  md: 724,
  lg: 900,
};

const CustomDialog = ({
  className,
  open,
  onClose,
  size = sizes.SMALL,
  width,
  minHeight = 'auto',
  children,
  contentStyle,
  hideCloseButton,
  heading = '',
  subHeading = '',
  additionalHeadingElements, // to display anything after heading
  ...rest
}) => {
  width = width ?? sizeWidths[size];

  return (
    <Dialog
      PaperProps={{
        style: {
          maxWidth: width,
          width: '100%',
          borderRadius: `${borderRadius.xl}px`,
        },
      }}
      maxWidth="lg"
      className={className}
      open={open}
      onClose={onClose}
      {...rest}
    >
      <DialogContent
        style={{
          width: '100%',
          minHeight,
          position: 'relative',
          ...contentStyle,
        }}
      >
        {!hideCloseButton && (
          <IconButton
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              zIndex: 5,
            }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        )}
        {heading && (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              width: 'calc(100% - 35px)',
              gap: 20,
              marginBottom: '12px',
            }}
          >
            <Typography variant="h5" fontWeight={500}>
              {heading}
            </Typography>
          </span>
        )}
        {subHeading && <Typography variant="body1">{subHeading}</Typography>}
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
