import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { ENGAGEMENT_TYPE_ENUMS } from '../../Utils/Constants';
import ChargeMilestones from './ChargeMilestones';

function StepThree({
  onboardingData,
  setOnboardingData,
  currentSubStep,
  handleMemberChange,
  showSubStep,
  validationErrors,
}) {
  return (
    <div>
      {showSubStep && currentSubStep === 1 ? (
        <ChargeMilestones
          onboardingData={onboardingData}
          setOnboardingData={setOnboardingData}
        />
      ) : (
        <>
          <Typography variant="h5" fontWeight={500}>
            How you're charging
          </Typography>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography fontWeight={500} marginBottom="5px">
              Are any of the folks in your project working on an hourly rate or
              monthly retainer of a specific number of hours? By adding this
              information you will be able to understand whether your project is
              on track and how much to charge your client. Note that you will
              always be able to add one-off items to your invoices before
              sending them off.
            </Typography>
          </Grid>

          <Card className="no-hover" variant="outlined" sx={{ p: 2, my: 4 }}>
            <Grid container spacing={2}>
              {/* Headers */}
              <Grid item xs={6} sm={2}>
                <Typography textAlign="left" variant="subtitle1">
                  Name
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography textAlign="left" variant="subtitle1">
                  Role
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography textAlign="left" variant="subtitle1">
                  Engagement Type
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography textAlign="left" variant="subtitle1">
                  Rate
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography textAlign="left" variant="subtitle1">
                  Cap on Hours
                </Typography>
              </Grid>
              {onboardingData?.members?.map((member, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={6} sm={2}>
                    <Typography variant="body1" marginTop={1}>
                      {member?.first_name} {member?.last_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Typography variant="body1" marginTop={1}>
                      {member?.project_role}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl
                      fullWidth
                      error={
                        !!validationErrors[`members.[${index}].engagement_type`]
                      }
                    >
                      <InputLabel>Engagement Type</InputLabel>
                      <Select
                        name="engagement_type"
                        required
                        value={member.engagement_type}
                        onChange={(e) => handleMemberChange(index, e)}
                        displayEmpty
                        label="Engagement Type"
                      >
                        {ENGAGEMENT_TYPE_ENUMS?.map((type, index) => (
                          <MenuItem key={index} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                      {validationErrors[
                        `members.[${index}].engagement_type`
                      ] && (
                        <Typography variant="caption" color="error">
                          {
                            validationErrors[
                              `members.[${index}].engagement_type`
                            ]
                          }
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Rate"
                      name="billing_rate"
                      value={member.billing_rate}
                      error={
                        !!validationErrors[`members.[${index}].billing_rate`]
                      }
                      helperText={
                        validationErrors[`members.[${index}].billing_rate`]
                      }
                      onChange={(e) => handleMemberChange(index, e)}
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Hour Cap"
                      name="hour_cap"
                      value={member.hour_cap}
                      onChange={(e) => handleMemberChange(index, e)}
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Card>
        </>
      )}
    </div>
  );
}

export default StepThree;
