import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// New async thunk to fetch all subscription plans from your API
export const getAllSubscriptionPlans = createAsyncThunk(
  'subscriptions/getAllPlans',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/v1/subscriptionPlan/plans');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// New async thunk to create a subscription plan
export const createSubscriptionPlan = createAsyncThunk(
  'subscriptions/createPlan',
  async (planData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/v1/subscriptionPlans', planData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// New async thunk to update a subscription plan
export const updateSubscriptionPlan = createAsyncThunk(
  'subscriptions/updatePlan',
  async ({ id, planData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/v1/subscriptionPlans/${id}`, planData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// New async thunk to delete a subscription plan
export const deleteSubscriptionPlan = createAsyncThunk(
  'subscriptions/deletePlan',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/v1/subscriptionPlans/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// New async thunk to get a subscription plan by ID
export const getSubscriptionPlanById = createAsyncThunk(
  'subscriptions/getPlanById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/v1/subscriptionPlan/${id}`);

      console.log('🚀 ~ response:', response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const subscriptionPlansSlice = createSlice({
  name: 'subscriptionPlans',
  initialState: {
    apiPlans: [], // State for plans fetched from your API
    selectedPlan: null, // State to store the plan fetched by ID
    isLoading: false,
    error: null,
  },
  reducers: {
    // Optionally, you can add other reducers to manipulate state locally
  },
  extraReducers: (builder) => {
    builder
      // New getAllSubscriptionPlans handlers
      .addCase(getAllSubscriptionPlans.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllSubscriptionPlans.fulfilled, (state, action) => {
        state.isLoading = false;
        state.apiPlans = action.payload.data;
      })
      .addCase(getAllSubscriptionPlans.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // New createSubscriptionPlan handlers
      .addCase(createSubscriptionPlan.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createSubscriptionPlan.fulfilled, (state, action) => {
        state.apiPlans.push(action.payload);
        state.isLoading = false;
      })
      .addCase(createSubscriptionPlan.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // New updateSubscriptionPlan handlers
      .addCase(updateSubscriptionPlan.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateSubscriptionPlan.fulfilled, (state, action) => {
        const index = state.apiPlans.findIndex(
          (plan) => plan._id === action.payload._id
        );
        if (index !== -1) {
          state.apiPlans[index] = action.payload;
        }
        state.isLoading = false;
      })
      .addCase(updateSubscriptionPlan.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // New deleteSubscriptionPlan handlers
      .addCase(deleteSubscriptionPlan.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteSubscriptionPlan.fulfilled, (state, action) => {
        state.apiPlans = state.apiPlans.filter(
          (plan) => plan._id !== action.payload._id
        );
        state.isLoading = false;
      })
      .addCase(deleteSubscriptionPlan.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // New getSubscriptionPlanById handlers
      .addCase(getSubscriptionPlanById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSubscriptionPlanById.fulfilled, (state, action) => {
        state.selectedPlan = action.payload;
        state.isLoading = false;
      })
      .addCase(getSubscriptionPlanById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default subscriptionPlansSlice.reducer;
