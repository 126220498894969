import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLeaves } from '../../Redux/slices/leaveSlice';
import moment from 'moment';
import HashLoader from 'react-spinners/HashLoader';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DynamicBackButton from '../../Components/BackButton/DynamicBackButton';
import Typography from '@mui/material/Typography';
import { formatDate } from '../../Utils/functions';

const LeaveRecords = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.leaves.loading);
  const leavesResponse = useSelector((state) => state.leaves.allLeaves);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const metadata = useSelector((state) => state.leaves.metadata);

  useEffect(() => {
    dispatch(getAllLeaves({ page: currentPage, limit: perPage }));
  }, [dispatch, currentPage, perPage]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ textAlign: 'left', mb: 7, mt: 2 }}>
        <Typography variant="h4" fontWeight={500}>
          Employee Leave Records
        </Typography>
      </Box>
      <DynamicBackButton />
      {loading ? (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <HashLoader color={'#F04E30'} loading={true} size={60} />
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Employee Name</TableCell>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="left">Start Date</TableCell>
                  <TableCell align="left">End Date</TableCell>
                  <TableCell align="left">Duration</TableCell>
                  <TableCell align="left">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leavesResponse &&
                leavesResponse.data &&
                leavesResponse.data.length > 0 ? (
                  leavesResponse.data.map((leave) => (
                    <TableRow key={leave.id} className="leave-row">
                      <TableCell>
                        {`${leave.user.first_name} ${leave.user.last_name}`}
                      </TableCell>
                      <TableCell align="left">{leave.type}</TableCell>
                      <TableCell align="left">
                        {formatDate(leave.start_date)}
                      </TableCell>
                      <TableCell align="left">
                        {formatDate(leave.end_date)}
                      </TableCell>
                      <TableCell align="left">{leave.duration}</TableCell>
                      <TableCell align="left">{leave.status}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="subtitle1" color="textSecondary">
                        No Items Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {leavesResponse &&
            leavesResponse.metadata &&
            leavesResponse.metadata.totalPages > 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <Pagination
                  count={metadata?.totalPages || 1}
                  page={currentPage}
                  onChange={handlePageChange}
                  sx={{ mt: 2 }}
                />
              </Box>
            )}
        </Box>
      )}
    </Box>
  );
};

export default LeaveRecords;
